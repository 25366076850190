import { useEffect, useRef } from 'react';
const useUpdateEffect = (effectCallback, deps) => {
  const isFirstMount = useRef(false);
  useEffect(() => {
    return () => {
      isFirstMount.current = false;
    };
  }, []);
  useEffect(() => {
    if (!isFirstMount.current) {
      isFirstMount.current = true;
    } else {
      return effectCallback();
    }
  }, deps);
};
export default useUpdateEffect;