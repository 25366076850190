import {Box, IconButton, Stack} from "@mui/material";
import BackButton from "../../../icons/BackIcon";
import ShareIcon from "../../../icons/ShareIcon";
import InfoIcon from "../../../icons/InfoIcon";
import {useNavigate, useParams} from "react-router-dom";
import {useModalsContext} from "../../../modals/ModalsContext";
import {MODALS} from "../../../modals/modals";
import {toast} from "react-toastify";
import BookMark from "../../../icons/BookMark";
import {useState, useEffect} from "react"
import { DislikeEventById, IsEventLikedByUser, LikeEventById } from "../../../firebase/functions/event/event-likes";
import BookMarkAct from "../../../icons/BookMarkAct";
import { useContext } from "react";
import { Context } from "../../../context/StateContext";

const iconStyle = {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    aspectRatio: "1/1",
    width: {xs: "30px", sm: "40px"},
    height: {xs: "30px", sm: "40px"},
    opacity: 0.8,
    padding: "7px",
};


const DetailsNavigation = ({eventFlagged, event}) => {


  const {eventData, setEventData} = useContext(Context)
  
    const navigate = useNavigate();
    const {setOpenModal} = useModalsContext();

  //   const {
  //   id,
  //   photos,
  //   title,
  //   location,
  //   participant,
  //   date,
  //   startTime,
  //   eventJoined,
  //   description,
  // } = event;

  
  const { id } = useParams();

  
  const [like, setLike] = useState({ id: id, isLike: event.likes });

  

useEffect(() => {
    try {
        IsEventLikedByUser(id).then((res) => {
            setLike({ id: id, isLike: res });
        });
    } catch (error) {
        console.log("error", error);
    }
}, [id]);


const handleLike = (id, e) => {
    if (like.isLike) {
        DislikeEventById(id).then(() => {
            setLike({ id: id, isLike: false });
        });
    } else {
        LikeEventById(id).then(() => {
            setLike({ id: id, isLike: true });
        });
    }
};


    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <IconButton onClick={() => navigate(-1)} size="small" sx={iconStyle}>
                <BackButton/>
            </IconButton>
            <Box display="flex" alignItems="center" gap={{xs: 2, sm: 4 }}>
         
          <Box
            onClick={() => handleLike(id)}
            
            sx={{
              fontSize: 16,
              bgcolor: "info.main",
              color:
                like.isLike && like.id === id ? "error.main" : "text.secondary",
              width: 32,
              height: 32,
              lineHeight: "33px",
              textAlign: "center",
              borderRadius: "50%",
              cursor: "pointer",
        
            }}
          >
            {like.isLike && like.id === id ? (
              <BookMark height={15} width={16} />
              ) : (
              <BookMarkAct height={15} width={16} />
            )}
          </Box>
           

                {!eventFlagged && (
                    <IconButton
                        onClick={() => setOpenModal(MODALS.EVENT_FLAG)}
                        size="small"
                        sx={iconStyle}
                    >
                        <InfoIcon/>
                    </IconButton>
                )}
            </Box>
        </Box>
    );
};

export default DetailsNavigation;
