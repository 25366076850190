import HomeHeader from "../components/Home/HomeHeaderNew";
import Popular from "../components/Home/Popular/Popular";
import Upcoming from "../components/Home/Upcoming/Upcoming";
import TagsModal from "../modals/TagsModal";
import { useModalsContext } from "../modals/ModalsContext";
import { MODALS } from "../modals/modals";
import { useEffect, useState } from "react";
import { setUserToken } from "../firebase/functions/messaging";
import { updateUserLocation } from "../firebase/functions/user";
import { useSelector, useDispatch } from "react-redux";
import { clearInfoUser, setUserInfo } from "../store/UserSlice";
import SortAndFilterModal from "../modals/SortAndFilterModal";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ReactComponent as ProfileSvg } from "../images/profile-menu.svg";
import { ReactComponent as HelpSvg } from "../images/Help-menu.svg";
import { ReactComponent as EventSvg } from "../images/event-menu.svg";
import { ReactComponent as ContactSvg } from "../images/contact-menu.svg";
import { ReactComponent as BookmarkSvg } from "../images/bookmark-menu.svg";
import { ReactComponent as SignOutSvg } from "../images/signout-arrow.svg";
import { Stack } from "@mui/material";
import { setOpenMenu } from "../store/OpenMenuSlice";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../routes";
import FirebaseAuth from "../firebase/auth";
import { setLoaded } from "../store/LocationSlice";

const drawerWidth = "60%";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isOpen = useSelector((state) => state.OpenMenuReducer.isOpen);

  const theme = useTheme();

  const handleDrawerOpen = (e) => {
    e.stopPropagation();
    dispatch(setOpenMenu(!isOpen));
  };

  const { openModal, setOpenModal } = useModalsContext();
  const userInfo = useSelector((state) => state.UserReducer.userInfo);
  const location = useSelector((state) => state.LocationReducer.location);
  const positionPoints = useSelector(
    (state) => state.LocationReducer.positionPoints
  );
  const filterObj = useSelector((state) => state.ModelReducer.filterObj);

  const [currentCity, setCurrentCity] = useState(
    location.structured_formatting?.main_text
  );
  const [appliedFilters, setAppliedFilters] = useState(filterObj);
  const FiltersChanged = (filters) => {
    setAppliedFilters(filters);
  };

  console.log({'dasdsada':filterObj,appliedFilters,currentCity});

  const [initLoad, setInitLoad] = useState(0);
  const [updateKeywords, setUpdateKeywords] = useState(0);
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.scrollTo(0, 0);

  const HandleUpdate = (city) => {
    if (initLoad !== 0) setCurrentCity(city);
    else setInitLoad(initLoad + 1);
  };
  const UpdateKeywords = () => {
    setUpdateKeywords(updateKeywords + 1);
  };

  useEffect(() => {
    setAppliedFilters(filterObj);
  }, [filterObj]);

  useEffect(() => {
    setUserToken();
  }, []);

  useEffect(() => {
    updateUserLocation(positionPoints?.lat, positionPoints?.lng);
    dispatch(
      setUserInfo({
        ...userInfo,
        location: {
          city: location?.structured_formatting?.main_text,
          lat: positionPoints.lat,
          lng: positionPoints.lng,
        },
      })
    );
  }, [positionPoints]);

  const mainStyles = {
    flexGrow: 1,
    transition: isOpen
      ? theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        })
      : "none",
    marginLeft: isOpen ? "70%" : 0,
    width: "100%",
    height: isOpen ? "88vh" : "unset",
    marginTop: isOpen ? "5%" : 0,
    paddingBottom: "5rem",
    overflow: isOpen ? "hidden" : "auto",
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: 20,
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }));

  const menuItems = [
    {
      name: "My Profile",
      logo: <ProfileSvg />,
      path: "/profile",
    },
    {
      name: "My Events",
      logo: <EventSvg />,
      path: "/events",
    },
    {
      name: "Saved",
      logo: <BookmarkSvg />,
      path: "/saved",
    },
    {
      name: "Help & FAQ",
      logo: <HelpSvg />,
      path: "/terms-of-services",
    },
    {
      name: "Contact Us",
      logo: <ContactSvg />,
      path: "/contact-us",
    },
  ];

  const handleNavClick = (path) => {
    navigate(path);
    dispatch(setOpenMenu(false));
  };

  const handleClickedLogOut = () => {
    dispatch(setLoaded(false));
    FirebaseAuth.logOut();
    dispatch(clearInfoUser());
    navigate(ROUTES.LOGIN);
  };

  const userToken = localStorage.getItem("UID");

  return (
    <div
      onClick={() => {
        dispatch(setOpenMenu(false));
      }}
    >
      <Drawer
        className="gradient"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            background: "#FDFDFE",
            border: "none",
          },
        }}
        variant="persistent"
        anchor="left"
        open={isOpen}
      >
        <DrawerHeader>
          <Stack sx={{ marginLeft: "9px" }}>
            <Box
              maxWidth="65px"
              sx={{ aspectRatio: "1/1", border: "3px solid #F56C6C" }}
              borderRadius="50%"
              width={"100%"}
              overflow="hidden"
              backgroundColor="#FFFF"
              display="flex"
              alignItems="center"
              justifyContent="center"
              position="relative"
            >
              <img
                style={{
                  objectFit: "cover",
                  width: "100%",
                  aspectRatio: "1/1",
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
                src={
                  userInfo && userInfo.photoURL && userInfo?.photoURL
                    ? userInfo.photoURL
                    : "https://cdn1.iconfinder.com/data/icons/messenger-and-society/16/user_person_avatar_unisex-512.png"
                }
                alt="user"
              />
            </Box>
            <p
              style={{
                fontSize: "22px",
                fontWeight: "500",
                color: "#000000",
                marginTop: "10px",
              }}
            >
              {userInfo?.displayName}
            </p>
          </Stack>
        </DrawerHeader>
        <List>
          {menuItems.map((item, index) => (
            <ListItem
              key={index}
              sx={{ minWidth: "45px" }}
              onClick={() => {
                handleNavClick(item.path);
              }}
            >
              <ListItemButton sx={{ paddingLeft: "15px" }}>
                <ListItemIcon>{item.logo}</ListItemIcon>
                <ListItemText>
                  <p
                    style={{ fontSize: 16, fontWeight: 500, color: "#000000" }}
                  >
                    {item.name}
                  </p>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            border: "1px solid #F56C6C",
            position: "absolute",
            bottom: "3%",
            width: "150px",
            height: "46px",
            marginLeft: "25px",
            borderRadius: "8px",
          }}
          onClick={handleClickedLogOut}
        >
          <SignOutSvg />
          <p style={{ fontSize: "18px", fontWeight: "500", color: "#F56C6C" }}>
            {userInfo?.displayName === "Anonymous" ? "Sign In " : " Sign Out"}
          </p>
        </Box>
      </Drawer>

      <div style={mainStyles} open={isOpen}>
        <div className={isOpen && "dreawer-shadow"}>
          <HomeHeader handleDrawerOpen={handleDrawerOpen} open={isOpen} />
          <Upcoming
            appliedFilters={appliedFilters}
            currentCity={currentCity}
            heading={"Popular Near You See All"}
          />
          <Popular currentCity={currentCity} heading={"Adventure & Outdoors"} />
        </div>

        <div
          className="z1"
          style={{ display: isOpen ? "block" : "none" }}
        ></div>
        <div
          className="z2"
          style={{ display: isOpen ? "block" : "none" }}
        ></div>
      </div>
      <TagsModal
        onData={UpdateKeywords}
        onClose={() => setOpenModal(null)}
        open={openModal === MODALS.TAGS}
      />
      <SortAndFilterModal
        onApply={FiltersChanged}
        open={openModal === MODALS.SORT_FILTER}
        onClose={() => setOpenModal(null)}
      />
    </div>
  );
};

export default Home;
