const BackButton = () => (
    <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.59986 7.71C2.50613 7.61704 2.43174 7.50644 2.38097 7.38458C2.3302 7.26272 2.30406 7.13201 2.30406 7C2.30406 6.86799 2.3302 6.73728 2.38097 6.61542C2.43174 6.49356 2.50613 6.38296 2.59986 6.29L7.18986 1.71C7.28359 1.61704 7.35798 1.50644 7.40875 1.38458C7.45952 1.26272 7.48566 1.13201 7.48566 0.999999C7.48566 0.867987 7.45952 0.737282 7.40875 0.615422C7.35798 0.493563 7.28359 0.382962 7.18986 0.289999C7.0025 0.103748 6.74905 -0.000793457 6.48486 -0.000793457C6.22067 -0.000793457 5.96722 0.103748 5.77986 0.289999L1.18986 4.88C0.628058 5.4425 0.3125 6.205 0.3125 7C0.3125 7.795 0.628058 8.5575 1.18986 9.12L5.77986 13.71C5.96612 13.8947 6.21752 13.9989 6.47986 14C6.61147 14.0008 6.74193 13.9755 6.86377 13.9258C6.9856 13.876 7.09642 13.8027 7.18986 13.71C7.28359 13.617 7.35798 13.5064 7.40875 13.3846C7.45952 13.2627 7.48566 13.132 7.48566 13C7.48566 12.868 7.45952 12.7373 7.40875 12.6154C7.35798 12.4936 7.28359 12.383 7.18986 12.29L2.59986 7.71Z"
            fill="#111315"
        />
    </svg>
);
export default BackButton;
