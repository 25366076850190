import React, { useState, useEffect } from "react";
import Map, { Marker, Popup } from "react-map-gl";
import { ReactComponent as Location } from "../../../images/location.svg";
import { useNavigate } from "react-router-dom";
import { CustomMarker } from "./customMarker";
import { ROUTES } from "../../../routes";
import "mapbox-gl/dist/mapbox-gl.css";
import { useSelector } from "react-redux";
import ExploreHeader from "../ExploreHeader/ExploreHeader";
import { ReactComponent as SportSvg } from "../../../images/sport.svg";
import { ReactComponent as MusicSvg } from "../../../images/music.svg";
import { ReactComponent as FoodSvg } from "../../../images/food.svg";
import { ReactComponent as ArtSvg } from "../../../images/artSvg.svg";
import { Box, Button } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import EventCard from "../../EventCard/EventCard";
import TagsSwiper from "./TagsSwiper";
import Tags from "./Tags";
import { MapIcon } from "./mapIcon";
import { GetLatitudeAndLongitudeOfDistance } from "../../../firebase/functions/event";
import { async } from "@firebase/util";

export const tagsMap = [
  {
    name: "All Events",
    value: "others",
  },
  {
    name: "Sports",
    value: "Sports & Games",
    logo: <SportSvg />,
  },
  {
    name: "Music",
    value: "Art & Music",
    logo: <MusicSvg />,
  },
  {
    name: "Food",
    value: "Food",
    logo: <FoodSvg />,
  },
  {
    name: "Art",
    value: "Art & Music",
    logo: <ArtSvg />,
  },
];

const MapBox = ({
  events,
  eventDetail,
  setEventCategory,
  eventCategory,
  filters,
}) => {
  console.log({events});
  const navigate = useNavigate();
  const positionPoints = useSelector(
    (state) => state.LocationReducer.positionPoints
  );
  const [currentEvent, setCurrentEvent] = useState();

  const [currentLocationEvent, setCurrentLocationEvent] = useState();
  const [clickCount, setClickCount] = useState(0);
  const [locationParam, setLocationParam] = useState(false);
  const [locationPoint, setLocationPoint] = useState({
    latitude: positionPoints.lat,
    longitude: positionPoints.lng,
    zoom: 12,
  });

  // const slideTo = (index) => swiper.slideTo(index);
  const width = window.innerWidth;
  let boxSHadow = true;
  const [selected, setSelected] = useState(null);
  const [userPosition, setUserPosition] = useState(positionPoints);
  let isEventsArray = false;
  if (events && !Array.isArray(events)) {
  } else {
    isEventsArray = true;
  }

  /* convert events to array of an object */
  /* check if events is an array */
  useEffect(() => {
    const fetchData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const lat = urlParams.get("lat");
      const lng = urlParams.get("lng");
      if (lat !=='undefined' && lng!=='undefined' && lat !==null && lng!==null) {
        const area = await GetLatitudeAndLongitudeOfDistance(lat, lng, 1);
        console.log({ area });
        console.log(lat, lng);

        const filteredEvents = events.filter((event) => {
          return (
            area.lowerLat <= event.latitude &&
            area.greaterLat >= event.latitude &&
            area.lowerLon <= event.longitude &&
            area.greaterLon >= event.longitude
          );
        });
        setCurrentLocationEvent(filteredEvents);
        setLocationParam(true);
        console.log(filteredEvents);
        setLocationPoint((pre) => ({
          ...pre,
          latitude: lat,
          longitude: lng,
        }));
        setUserPosition({
          lat: lat,
          lng: lng,
        });
      } else if (events && !Array.isArray(events)) {
        setLocationPoint((pre) => ({
          ...pre,
          latitude: events.latitude,
          longitude: events.longitude,
        }));
        setCurrentEvent(events);
      } else if (events?.length) {
        isEventsArray = true;
        setLocationPoint((pre) => ({
          ...pre,
          latitude: events[0].latitude,
          longitude: events[0].longitude,
        }));
        setCurrentEvent(events[0]);
      }
    };
    fetchData();
  }, [events]);

  const handleClickedMap = (e) => {
    setSelected(null);
    if (isEventsArray) {
      setUserPosition({
        lat: e.lngLat.lat,
        lng: e.lngLat.lng,
      });
    }
  };

  const handleTouchStart = (event) => {
    if (clickCount === 1) {
      navigate(ROUTES.EVENT_DETAILS.replace(":id", event.id));
      setClickCount(0);
    } else {
      setTimeout(() => {
        setClickCount(0);
      }, 200);
    }

    setClickCount((prevCount) => prevCount + 1);
  };

  const handleSlideChange = (swiper) => {
    const currentSlideIndex = swiper.activeIndex;
    if (
      currentLocationEvent &&
      currentLocationEvent.length > currentSlideIndex
    ) {
      setCurrentEvent(currentLocationEvent[currentSlideIndex]);
    } else if (events && events.length > currentSlideIndex) {
      setCurrentEvent(events[currentSlideIndex]);
    }

    const activeEvent = currentLocationEvent
      ? currentLocationEvent[swiper.activeIndex]
      : events[swiper.activeIndex];

    setLocationPoint((prev) => ({
      ...prev,
      latitude: activeEvent.latitude,
      longitude: activeEvent.longitude,
    }));
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        position: "relative",
        top: "1px",
      }}
    >
      <Map
        initialViewState={{ ...locationPoint }}
        mapboxAccessToken={
          "pk.eyJ1IjoiYXItcmVobWFuMTM1IiwiYSI6ImNsZTJmYjl5djAwdnAzd28yczFnemNtZGEifQ.riw8y9JzuIU59NDfoe0NcA"
        }
        viewState={{ ...locationPoint }}
        style={{ width: "100%", height: "100%" }}
        mapStyle="mapbox://styles/ar-rehman135/clh095mus00j301pgd330g67n"
        onDblClick={handleClickedMap}
        onZoom={(e) => setLocationPoint(e.viewState)}
        onMoveStart={(e) => setLocationPoint(e.viewState)}
      >
        {eventDetail === true ? (
          ""
        ) : (
          <div
            style={{
              position: "absolute",
              top: "4%",
              zIndex: 99,
              width: "100%",
            }}
          >
            <ExploreHeader filters={filters} locationParam={locationParam} />
            {width <= 500 ? (
              <TagsSwiper
                tagsMap={tagsMap}
                setEventCategory={setEventCategory}
                eventCategory={eventCategory}
              />
            ) : (
              <Tags
                tagsMap={tagsMap}
                setEventCategory={setEventCategory}
                eventCategory={eventCategory}
              />
            )}
          </div>
        )}

        <Box
          sx={{
            width: "100%",
            height: 100,
            zIndex: 999,
            position: "absolute",
            bottom: "13%",
          }}
        >
          <Box
            sx={{
              boxShadow: "0px 6px 20px 0px #2E2E4F1F",
            }}
          >
            <Swiper
              slidesPerView={1.1}
              centeredSlides={true}
              allowSlidePrev={true}
              spaceBetween={5}
              onSlideChange={handleSlideChange}
              grabCursor={true}
              className="mySwiper"
            >
              <div className="slider up" style={{ display: "flex" }}>
                {(currentLocationEvent && currentLocationEvent.length > 0
                  ? currentLocationEvent
                  : events
                )?.map((event, index) => {
                  return (
                    <SwiperSlide
                      onTouchStart={() => handleTouchStart(event)}
                      onDoubleClick={(e) => {
                        navigate(ROUTES.EVENT_DETAILS.replace(":id", event.id));
                      }}
                      style={{ width: "400px", marginRight: "6px" }}
                      key={index}
                    >
                      <EventCard event={event} shadow={boxSHadow} />
                    </SwiperSlide>
                  );
                })}
              </div>
            </Swiper>
          </Box>
        </Box>
        {/* // Show markers for each location in the markers array */}
        {/* this is show events in map location */}

        {(currentLocationEvent && currentLocationEvent.length > 0
          ? currentLocationEvent
          : events
        )?.map((event) => (
          <Marker
            key={event.id}
            latitude={event.latitude}
            longitude={event.longitude}
            anchor="top"
          >
            <CustomMarker
              photo={event.photos[0]}
              event={event}
              setSelected={setSelected}
            />
          </Marker>
        ))}
        {currentLocationEvent && currentLocationEvent.length > 0
          ? currentLocationEvent.map((event, index) => (
              <Marker
                key={event.id}
                latitude={event.latitude}
                longitude={event.longitude}
                anchor="top"
              >
                <CustomMarker
                  photo={event.photos[0]}
                  event={event}
                  setSelected={setSelected}
                />
              </Marker>
            ))
          : events &&
            !isEventsArray && (
              <Marker
                key={events.id}
                latitude={events.latitude}
                longitude={events.longitude}
                anchor="top"
              >
                <CustomMarker
                  photo={events.photos[0]}
                  event={events}
                  setSelected={setSelected}
                />
              </Marker>
            )}

        {/* Show a marker for the user's current position */}
        {!locationParam && userPosition && (
          <Marker
            latitude={userPosition.lat}
            longitude={userPosition.lng}
            anchor="top"
          >
            <Location />
          </Marker>
        )}

        {selected ? (
          <Popup
            longitude={selected?.longitude}
            latitude={selected?.latitude}
            closeButton={true}
            closeOnClick={false}
            anchor="top"
            onClose={() => {
              setSelected(null);
            }}
          >
            <div>
              <h2>{selected.title}</h2>
              <p
                style={{
                  marginTop: "10px",
                }}
              >
                Custom information about this marker
              </p>
              <Button
                onClick={function (e) {
                  e.preventDefault();
                  navigate(ROUTES.EVENT_DETAILS.replace(":id", selected.id));
                }}
                sx={{
                  px: 1,
                  py: 0.5,
                  borderRadius: 50,
                  fontSize: { xs: 14, md: 16 },
                  fontWeight: 700,
                  textTransform: "capitalize",
                  color: "text.primary",
                  boxShadow: 0,
                  "&:hover": {
                    backgroundColor: "primary.main",
                    color: "info.main",
                    boxShadow: 0,
                  },
                }}
                style={{
                  backgroundColor: "info.main",
                  color: "text.primary",
                  marginBlock: "auto",
                  marginTop: "10px",
                }}
                variant="contained"
                color="info"
              >
                Join
              </Button>
            </div>
          </Popup>
        ) : null}
      </Map>
    </div>
  );
};

export default MapBox;
