import { Button, Container, Badge } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import React, { useEffect, useState } from "react";
import { HiOutlineUser, HiUser } from "react-icons/hi";
import { RiHome6Fill, RiHome6Line, RiSearch2Line } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAllNotifications,
  returnJoinedNotifications,
  getAllNotificationDelete,
} from "../../firebase/functions/messaging/notifications";
import HomeHeader from "../../components/Home/HomeHeaderNew";
import HomeHeaderDesktop from "../../components/Home/HomeHeaderDesktop";
import {
  IoNotificationsOutline,
  IoNotificationsSharp,
  IoCalendarOutline,
  IoCalendarSharp,
} from "react-icons/io5";
import { TiLocation } from "react-icons/ti";

const paths = [
  // {
  //   path: "/home",
  //   icon: <RiHome6Line />,
  //   activeIcon: <RiHome6Fill />,
  //   label: "Home",
  // },
  {
    path: "/home",
    icon: <RiHome6Line />,
    activeIcon: <RiHome6Fill />,
    label: "Explore",
  },
  {
    path: "/explore",
    icon: <TiLocation />,
    activeIcon: <TiLocation />,
    label: "Map",
  },
  // {
  //   path: "/events",
  //   icon: <BiCalendar />,
  //   activeIcon: <BiCalendar />,
  //   label: "Event",
  // },
  {
    path: "/events",
    icon: <IoCalendarOutline />,
    activeIcon: <IoCalendarSharp />,
    label: "Events",
  },
  {
    path: "/notifications",
    icon: <IoNotificationsOutline />,
    activeIcon: <IoNotificationsSharp />,
    label: "Notifications",
  },
  {
    path: "/profile",
    icon: <HiOutlineUser />,
    activeIcon: <HiUser />,
    label: "Profile",
  },
  // {
  //   path: "/profile",
  //   icon: <HiOutlineUser />,
  //   activeIcon: <HiUser />,
  //   label: "Profile",
  // },
  // {
  //   path: "/events",
  //   icon: <IoNotificationsOutline />,
  //   activeIcon: <IoNotificationsSharp />,
  //   label: "Events",
  // },
];

// navbar
const Navbar = () => {
  
  const [active, setActive] = React.useState(false);
  const [notifications, setNotifications] = useState([]);
  const [countNotifications, setCountNotifications] = useState(0);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    getAllNotifications().then(async () => {
      const notifyData = await returnJoinedNotifications();
      setCountNotifications(notifyData.length);
      setNotifications(notifyData);
    });
  }, [active]);

  const handleNavClick = (path) => {
    navigate(path);
    setActive(!active);
  };


  return (
    <>
      {/* <HomeHeaderDesktop /> */}
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="static"
          sx={{
            bgcolor: "transparent",
            boxShadow: 0,
            display: { xs: "none", sm: "block" },
          }}
        >
          <Toolbar>
            <Container>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "center",
                  alignItems: "center",
                  bgcolor: "info.main",
                  py: 2,
                  px: 4,
                  mt: 1,
                  borderRadius: "25px",
                  // boxShadow: 1,
                }}
              >
                <Box sx={{ display: { xs: "none", sm: "block" } }}>
                  <nav
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 8,
                    }}
                  >
                    {paths.map((item) => (
                      <Button
                        onClick={() => handleNavClick(item.path)}
                        startIcon={
                          pathname === item.path ? item.activeIcon : item.icon
                        }
                        sx={{
                          textTransform: "capitalize",
                          color: pathname === item.path ? "#20ACAC" : "#afb1ba",
                          fontWeight: pathname === item.path ? 700 : 500,
                        }}
                        variant="text"
                        key={item.label}
                      >
                        {item.label}
                      </Button>
                    ))}
                    {/* <Button
                    onClick={() => handleNavClick("/notifications")}
                    startIcon={
                      pathname === "/notifications" ? (
                        <Badge badgeContent={countNotifications} color="error">
                          <IoNotificationsSharp />
                        </Badge>
                      ) : (
                        <Badge badgeContent={countNotifications} color="error">
                          <IoNotificationsOutline />
                        </Badge>
                      )
                    }
                    sx={{
                      textTransform: "capitalize",
                      color:
                        pathname === "/notifications" ? "primary" : "#afb1ba",
                      fontWeight: pathname === "/notifications" ? 700 : 500,
                    }}
                    variant="text"
                    key="Notifications"
                  >
                    Notifications
                  </Button> */}
                  </nav>
                </Box>
              </Box>
            </Container>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default Navbar;
