import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";

function StripePayment() {
  const { id, uId } = useParams();
  console.log(id, "======");
  console.log(uId, "======");
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_STRIP_PAYMENT_API}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: 1000,
        currency: "USD",
        eventId: id,
        userId: uId,
      }),
    })
      .then(async (result) => {
        if (!result.ok) {
          throw new Error(`Fetch failed with status ${result.status}`);
        }
        const responseData = await result.json();

        setStripePromise(
          loadStripe(`${process.env.REACT_APP_STRIP_PAYMENT_PUBLIC_KEY}`)
        );
        setClientSecret(responseData.client_secret);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  }, []);

  return (
    <>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm eventId={id} />
        </Elements>
      )}
    </>
  );
}

export default StripePayment;
