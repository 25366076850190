import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import loading from "../../images/loader.gif";

const OneTimeGif = ({loader}) => {
  return (
    <>
      {!loader ? (
        <Player
          className="gif"
          autoplay
          loop={0}
          keepLastFrame
          src="https://lottie.host/6f3ba850-94a6-49a2-9755-6efb1c775545/cJt0Bxkede.json"
          style={{ height: "300px", width: "300px" }}
        ></Player>
      ) : (
        <img src={loading} style={{ height: "300px", width: "300px" }} />
      )}
    </>
  );
};

export default OneTimeGif;
