const TransactionsIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66669 14.0003C4.66669 12.7117 5.71136 11.667 7.00002 11.667H17.5C18.7887 11.667 19.8334 12.7117 19.8334 14.0003V14.0003C19.8334 15.289 18.7887 16.3337 17.5 16.3337H7.00002C5.71136 16.3337 4.66669 15.289 4.66669 14.0003V14.0003Z"
      stroke="#111315"
      strokeWidth="2"
    />
    <path
      d="M16.3333 6.31691L14.8249 7.82528C14.3693 8.2809 13.6306 8.28089 13.175 7.82528L11.6666 6.31691M14 2.33366L14 7.48357"
      stroke="#111315"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M14 20.9997C14 23.577 16.0894 25.6663 18.6667 25.6663C21.244 25.6663 23.3334 23.577 23.3334 20.9997C23.3334 18.4223 21.244 16.333 18.6667 16.333C18.4341 16.333 18.2055 16.35 17.9821 16.3829M14 20.9997C14 18.6549 15.7293 16.714 17.9821 16.3829M14 20.9997H7.00002C5.71136 20.9997 4.66669 19.955 4.66669 18.6663C4.66669 17.3777 5.71136 16.333 7.00002 16.333H17.5C17.6653 16.333 17.8265 16.3502 17.9821 16.3829"
      stroke="#111315"
      strokeWidth="2"
    />
  </svg>
);

export default TransactionsIcon;
