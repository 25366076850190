import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import ContinueWithSocialMedia from "../components/ContinueWithSocialMedia";
import FormContainer from "../components/FormContainer";
import StyledTextInput from "../components/StyledTextInput";
import useSignUp from "../hooks/useSignUp";
import BackButton from "../icons/BackIcon";
import { ReactComponent as Logo } from "../icons/Favicon-forms.svg";
import { ROUTES } from "../routes";

const Header = () => {
  const navigate = useNavigate();
  return (
    <Stack
      position="relative"
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        left={0}
        position="absolute"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        {/* <BackButton /> */}
      </Box>
      <Logo
        style={{
          boxShadow:
            "0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.10)",
            borderRadius:"50%"
        }}
      />
      {/* <Typography fontWeight="bold" fontSize="20px" textAlign="center">
                Create an account
            </Typography> */}
    </Stack>
  );
};

const SignUpForm = ({ inputs, formData, handleOnChange }) => {
  const navigate = useNavigate();
  const handleNavigate = (to) => {
    if (to === "tos") {
      navigate("/terms-of-services");
    } else {
      navigate("/privacy-policy");
    }
  };
  return (
    <Stack spacing={2}>
      {inputs.map((inputProps, index) => (
        <div
          style={{
            position: "relative",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            {...inputProps}
            key={index}
            placeholder={inputProps.label}
            value={formData[inputProps.name]}
            onChange={handleOnChange}
            style={{
              padding: "9px 5px",
              border: "1px solid #D9D9D9",
              borderRadius: "8px",
              width: "100%",
              paddingLeft: "18%",
              fontSize: "14px",
              fontWeight: 400,
              color: "#666666",
            }}
          />
          <img
            style={{ position: "absolute", left: "5%" }}
            src={inputProps.image}
          />
        </div>
      ))}
    </Stack>
  );
};

const SignUp = () => {
  const { inputs, formData, handleOnChange, handleSubmit } = useSignUp();
  return (
    <FormContainer>
      <Header />
      <Stack maxWidth={"400px"} width={"90%"} sx={{ marginTop: 5 }} spacing={5}>
        <Typography
          align="center"
          sx={{
            fontSize: "24px",
            lineHeight: "36px",
            fontWeight: "500",
            fontStyle: "normal",
            color: "#303030",
            fontFamily: "PoppinsRegular",
          }}
        >
          Create an account
        </Typography>
        <p
          align="center"
          style={{
            fontSize: "14px",
            lineHeight: "19px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "#303030",
            marginTop: 0,
          }}
        >
          Sign up below to get started
        </p>
        <SignUpForm {...{ inputs, formData, handleOnChange }} />
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            backgroundColor: "#20ACAC",
            fontSize: "15px",
            fontWeight: 700,
            lineHeight: "19px",
            color: "info.main",
            borderRadius: "8px",
            padding: "17px",
            boxshadow:
              "0px 9.057971000671387px 31.702898025512695px 0px #6F7EC926",
            "&:hover": {
              backgroundColor: "#20ACAC",
            },
          }}
        >
          SIGN IN
        </Button>
      </Stack>
      <ContinueWithSocialMedia sx={{ marginTop: 5, textAlign: "center" }} />
      <Box
        sx={{
          pt: 2,
          pb: 6,
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
          fontWeight: 400,
        }}
        flexDirection={"column"}
      >
        <Typography textAlign="center">Already have an account?</Typography>
        <Link
          component={RouterLink}
          to={ROUTES.LOGIN}
          underline="always"
          sx={{ color: "#20ACAC", fontSize: "14px", fontWeight: 400 }}
        >
          Sign in for free.
        </Link>
      </Box>
    </FormContainer>
  );
};

export default SignUp;
