import { Stack, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useCreateEventContext } from "../../pages/CreateEvent";
import { ReactComponent as UploadSvg } from "../../images/upload.svg";
import { ReactComponent as DeleteSvg } from "../../images/DeleteOutlined.svg";
import NextButton from "./NextButton";
const DetailCard = ({ children }) => {
  return (
    <Stack
      width="100%"
      borderRadius="8px"
      bgcolor="white"
      sx={{
        border: "1px solid #D9D9D9",
        padding: "10px",
      }}
    >
      {children}
    </Stack>
  );
};

const Text = ({ title }) => {
  return (
    <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>{title}</Typography>
  );
};

const ImagePage = ({ State, description, setPages, pages }) => {
  const fileInputRef = useRef(null);
  const { photos, setState } = useCreateEventContext();

  const handleDefaultImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    setState((prev) => ({
      ...prev,
      photos: [...e.target.files, ...prev.photos].slice(0, 5),
    }));
  };

  const handleDeleteImage = (index) => {
    const updatedPhotos = photos.filter((_photo, i) => i !== index);
    setState((prev) => ({
      ...prev,
      photos: updatedPhotos,
    }));
  };

  const nextPage = () => {
    setPages(pages + 1);
  };

  return (
    <Stack gap={2} width="100%">
      <Stack gap={2}>
        <Text title="Tell us more about it! " />
        <DetailCard title="Description">
          <textarea
            className="description"
            value={description}
            placeholder="Event Description"
            onChange={(e) =>
              State((prev) => ({ ...prev, description: e.target.value }))
            }
            rows={6}
          />
        </DetailCard>
        <Text title="Wanna add photos?" />
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #D9D9D9",
            padding: "10px",
            width: "100%",
            borderRadius: "2px",
          }}
          onClick={handleDefaultImageClick}
        >
          <input
            ref={fileInputRef}
            multiple
            accept="image/*"
            type="file"
            onChange={handleFileInputChange}
            hidden
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              opacity: 0,
            }}
          />
          <UploadSvg />
          <Typography sx={{ marginLeft: "5px" }}>Click to Upload</Typography>
        </Stack>
        {photos &&
          photos.map((photo, index) => (
            <Stack
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                height: "50px",
                border: "1px solid #D9D9D9",
                width: "100%",
                borderRadius: "2px",
                paddingRight: "8px",
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: "8px",
                }}
              >
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    aspectRatio: "1/1",
                  }}
                  src={
                    typeof photo === "string"
                      ? photo
                      : URL.createObjectURL(photo)
                  }
                  alt={`list-${index}`}
                />
                <Typography sx={{ marginLeft: "5px" }}>{photo.name}</Typography>
              </Stack>
              <DeleteSvg onClick={() => handleDeleteImage(index)} />
            </Stack>
          ))}
        <div onClick={nextPage} style={{ width: "100%" }}>
          <NextButton />
        </div>
      </Stack>
    </Stack>
  );
};

export default ImagePage;
