import {Box, Button, Modal, Typography} from "@mui/material";
import { Stack } from "@mui/system";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LeftEventById } from "../firebase/functions/event/event-left";
import { sendEventLeftNotification } from "../firebase/functions/messaging";
import { ROUTES } from "../routes";
import DefaultModal from "./DefaultModal";

const LeftModal = ({id, open, onClose}) => {
    const navigate = useNavigate();
    const event = useSelector(state=>state.EventDataReducer.updateEventData)
    const handleLeftEvent = () => {
        LeftEventById(id)
        sendEventLeftNotification(id, event)
        navigate(ROUTES.HOME)
        onClose()
    }
    return (
        <DefaultModal open={open} onClose={onClose}>
        <Stack textAlign="center" gap={3}>
            <Typography variant="h4">Leave Activity</Typography>
            <Stack
                flexWrap="wrap"
                maxHeight={{xs: "50vh", sm: "500px"}}
                overflow="auto"
                flexDirection="row"
                margin={"1rem 0rem"}
            >
                <Button
                onClick={handleLeftEvent}
                 variant="contained"
                 fullWidth
                 sx={{
                     backgroundColor: "red",
                     color: "info.main",
                     borderRadius: 8,
                     padding: 1,
                     "&:hover": {
                         backgroundColor: "red",
                     },
                 }}>Are you Sure?</Button>
            </Stack>
        </Stack>
    </DefaultModal>
    );
};

export default LeftModal;
