import { createSlice } from "@reduxjs/toolkit";

export const UserProfileReducer = createSlice({
  name: "profile",
  initialState: {
    profileInfo: {},
  },
  reducers: {
    setProfileInfo: (state, action) => {
      state.profileInfo = action.payload;
    },
    clearProfileInfo: (state) => {
      state.profileInfo = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProfileInfo, clearProfileInfo } = UserProfileReducer.actions;

export default UserProfileReducer.reducer;
