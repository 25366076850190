import React from "react";
import { Stack, Typography } from "@mui/material";
import MapBox from "./Map";

const Text = ({ title }) => {
  return (
    <Typography
      sx={{
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "PoppinsRegular",
        color: "#303030",
      }}
    >
      {title}
    </Typography>
  );
};

const Description = ({ title }) => {
  return (
    <Typography
      sx={{
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        fontFamily: "PoppinsRegular",
        color: "#000000",
        marginY: "10px",
      }}
    >
      {title}
    </Typography>
  );
};

const MapPage = ({ pages, setPages }) => {
  return (
    <Stack width={"100%"} sx={{ alignSelf: "stretch" }} height={"100%"}>
      <Text title="Select A Custom Location 📍" />
      <Description title="Use two fingers to zoom in and out of the map. Tap the ‘+’ button to pin your location.  " />
      <Stack height={"100%"} marginTop={"20px"}>
        <MapBox pages={pages} setPages={setPages} />
      </Stack>
    </Stack>
  );
};

export default MapPage;
