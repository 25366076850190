import { Box, Button, Container, Stack, Typography } from "@mui/material";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import UpcomingCard from "./UpcomingCard";
import { useEffect } from "react";
import {
  GetExploreEventsFromUserLocation,
  SortEventWithCityByUpcoming,
  SortEventWithLocationByUpcoming,
} from "../../../firebase/functions/event/sort-event";
import { ReactComponent as SeeAllIcon } from "../../../images/see-all.svg";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";
import { useSelector } from "react-redux";
import { useContext } from "react";
import { Context } from "../../../context/StateContext";

const Upcoming = ({ appliedFilters, currentCity, heading }) => {
  const { eventData, setEventData } = useContext(Context);

  const navigate = useNavigate();
  const location = useSelector((state) => state?.LocationReducer?.location);
  const positionPoints = useSelector(
    (state) => state.LocationReducer.positionPoints
    
  );
  const tags = useSelector((state) => state.ModelReducer.tags);
  const filterObj = useSelector((state) => state.ModelReducer.filterObj);


console.log({location});
  useEffect(() => {
    let isMounted = true;
    if (location.structured_formatting?.main_text !== "") {
      GetExploreEventsFromUserLocation(
        20,
        filterObj,
        positionPoints.lat,
        positionPoints.lng,
        tags
      ).then((events) => {
        if (isMounted && appliedFilters) {
          const CostFilteredEvents = events.filter((event) => {
            return (
              event.cost >= appliedFilters.minPrice &&
              event.cost <= appliedFilters.maxPrice
            );
          });
          const DateFilteredEvents = CostFilteredEvents.filter((event) => {
            const eventDate = new Date(
              event.date.seconds * 1000 + event.date.nanoseconds / 1000000
            );
            const NewEventDate = eventDate.toISOString().split("T")[0];
            return NewEventDate <= appliedFilters.date;
          });
          setEventData(DateFilteredEvents);
        } else if (isMounted && appliedFilters === null) {
          setEventData(events);
        }
      });
    } else {
      SortEventWithLocationByUpcoming(4).then((events) => {
        if (isMounted && appliedFilters) {
          const CostFilteredEvents = events.filter((event) => {
            return (
              event.cost >= appliedFilters.minPrice &&
              event.cost <= appliedFilters.maxPrice
            );
          });
          const DateFilteredEvents = CostFilteredEvents.filter((event) => {
            const eventDate = new Date(
              event.date.seconds * 1000 + event.date.nanoseconds / 1000000
            );
            const NewEventDate = eventDate.toISOString().split("T")[0];
            return NewEventDate <= appliedFilters.date;
          });
          setEventData(DateFilteredEvents);
        } else if (isMounted && appliedFilters === null) {
          setEventData(events);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [location.structured_formatting?.main_text, appliedFilters , filterObj]);

  const { id } = eventData;

  return (
    <div>
      <Container
        sx={{
          py: { xs: 2, md: 5 },
          px: { xs: 2.5, md: 2 },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{
              fontSize: { xs: 18, md: 44 },
              fontWeight: 500,
              color: "#120D26",
            }}
            variant="h3"
          >
            {heading}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={function (e) {
                e.preventDefault();
                navigate(ROUTES.EVENTS_PAGE + "?type=upcoming-event");
              }}
              sx={{ color: "#747688", fontSize: "14px", fontWeight: "400" }}
              variant="text"
            >
              See All
            </Button>
            <SeeAllIcon />
          </Box>
        </Stack>
      </Container>
      <Container
        sx={{
          // pd: {xs: 2, md: 4},
          pr: { xs: 0, md: "inherit" },
          pl: { xs: 2, md: "inherit" },
        }}
      >
        <Box>
          <Swiper
            slidesPerView={1.3}
            spaceBetween={20}
            breakpoints={{
              600: {
                slidesPerView: 2.1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2.7,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3.2,
                spaceBetween: 20,
              },
            }}
            grabCursor={true}
            className="mySwiper"
          >
            {eventData &&
              eventData.length !== 0 &&
              eventData.map((event) => (
                <SwiperSlide key={event.id}>
                  <UpcomingCard event={event} />
                </SwiperSlide>
              ))}
          </Swiper>
        </Box>
      </Container>
    </div>
  );
};

export default Upcoming;
