import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { SortEventWithUserId } from "../../firebase/functions/event/sort-event";
import UpcomingPastEventCard from "../UpcomingPastEventCard/UpcomingPastEventCard";
import AboutUs from "./About";
import Tags from "./Tags";
import styled from "@emotion/styled";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const CustomTabs = ({ setPastEvent, selectedOption , userInfo}) => {
  const [value, setValue] = React.useState(0);
  const [events, setEvents] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    SortEventWithUserId(userInfo.uid).then((data) => {
      setEvents(data);
      setPastEvent(data.length);
    });
  }, []);

  function formatDate(timestamp) {
    const date = timestamp?.toDate();
    const dated = new Date(date);

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = months[dated.getMonth()];

    const year = dated.getFullYear();

    return `${monthName} ${year}`;
  }

  const groupedEvents = events.reduce((groups, event) => {
    const formattedDate = formatDate(event.date);
    if (!groups[formattedDate]) {
      groups[formattedDate] = [];
    }
    groups[formattedDate].push(event);
    return groups;
  }, {});

  const groupedEventsArray = Object.entries(groupedEvents).map(
    ([date, events]) => ({
      date,
      events,
    })
  );

  return (
    <Stack>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Tabs
            className="tabs-div"
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{ sx: { backgroundColor: "#EB5757" } }}
            aria-label="basic tabs example"
          >
            <Tab className="tabs-button" label="About" />
            <Tab className="tabs-button" label="Events" />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <AboutUs />
          <Stack>
            <AboutTypoGraphy>Interests</AboutTypoGraphy>
            <Tags selectedOption={selectedOption} />
          </Stack>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Stack width={"100%"}>
            {groupedEventsArray.map((item,i) => (
              <UpcomingPastEventCard key={i} event={item} />
            ))}
          </Stack>
        </CustomTabPanel>
      </Box>
    </Stack>
  );
};

export default CustomTabs;

const AboutTypoGraphy = styled.p`
  color: var(--color-typography-title, #120d26);
  text-rendering: optimizeLegibility;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  margin: 0;
`;
