export const timeTo12HrFormat = (time) => {
  const timeArr = time.split(":");
  let hours = parseInt(timeArr[0]);
  let minutes = parseInt(timeArr[1]);
  let designation = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const timeStr = hours + ":" + minutes + " " + designation;
  return timeStr;
};

export const timeTo24HrFormat = (time) => {
  const timeArr = time.split(" ");
  const timeStr = timeArr[0];
  const designation = timeArr[1];

  let [hours, minutes] = timeStr.split(":");
  hours = parseInt(hours);
  minutes = parseInt(minutes);

  if (designation === "PM" && hours !== 12) {
    hours += 12;
  } else if (designation === "AM" && hours === 12) {
    hours = 0;
  }

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  const time24HrStr = hours + ":" + minutes;
  return time24HrStr;
};

export const addMinutes = (time, minutesToAdd) => {
  const timeArr = time.split(":");
  let [hours, minutes] = timeArr.map((t) => parseInt(t, 10)); // Fix parseInt with radix

  const totalMinutes = hours * 60 + minutes + minutesToAdd;
  hours = Math.floor(totalMinutes / 60) % 24;
  minutes = totalMinutes % 60;

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return `${hours}:${minutes}`;
};
