import Container from "@mui/system/Container";

const FormContainer = ({ children }) => {
  return (
    <div style={{ minHeight: "100vh" }}>
      <Container
        sx={{
          width: "100%",
          backgroundColor: "#F2F2F2",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {children}
      </Container>
    </div>
  );
};

export default FormContainer;
