import { Stack } from "@mui/material";
import React from "react";
import chroma from "chroma-js";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

const Interest = ({ options, selectedOption, setSelectedOption }) => {
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles) => {
      return {
        ...styles,
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.9).css(),
        padding: "7px 10px",
        borderRadius: "16px",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "white",
      fontSize: "13px",
      fontWeight: 700,
      padding: 0,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "white",
      padding: "0px 0px 0px 6px",
      ":hover": {
        backgroundColor: data.color,
        color: "white",
      },
    }),
  };

  return (
    <Stack>
      <Select
        className="select new"
        closeMenuOnSelect={false}
        onChange={(e) => {
          setSelectedOption(e);
        }}
        value={selectedOption}
        isMulti
        options={options}
        styles={colourStyles}
        isClearable={false}
      />
    </Stack>
  );
};

export default Interest;
