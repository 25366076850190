import { useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { ReactComponent as BackIcon } from "../images/Back.svg";
import { ROUTES } from "../routes";
import { clearProfileInfo } from "../store/ProfileSlice";
import { useDispatch } from "react-redux";

const PageHeader = ({ title, pages, ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Stack
      {...props}
      position="relative"
      flexDirection="row"
      alignItems={"center"}
    >
      {title === "My Profile"  && (
        <BackIcon
          onClick={() => {
            navigate(-1);
            dispatch(clearProfileInfo())
          }}
        />
      )}
      <Typography
        width="100%"
        ml={{ xs: title === "My Profile" ? 1 : 0 }}
        variant="h3"
        sx={{ fontSize: "24px", fontWeight: 400, color: "#120D26" }}
      >
        {title}
      </Typography>
    </Stack>
  );
};

export default PageHeader;
