import { getMessaging, getToken } from "firebase/messaging";
import app from "../../config";
import { getCurrentUser } from "../user";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import axios from "axios";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

const storage = getStorage();
export const messaging = getMessaging(app);
let currentUser = null;
const db = getFirestore(app);

const url =
  "https://us-central1-recessmobile-d2ab0.cloudfunctions.net/send_notification";

export const setUserToken = async () => {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      getToken(messaging, {
        vapidKey:
          "BCUDnw1dZZ75la9Hll0KqIErbvCKMwSmQFcWgotMe-YzpemW-ZUIzPunDbXJKv0NnqBc4SxpGHUxZ-7Ip6lMRMk",
      })
        .then((currentToken) => {
          updateUserToken(currentToken);
        })
        .catch((err) => {
          console.log("error", err);
        });
    } else {
      console.log("No permission to send notifications");
    }
  });
};

export const updateUserToken = async (token) => {
  currentUser = await getCurrentUser();
  if (currentUser) {
    const userRef = doc(db, "users", currentUser);
    const userDoc = await getDoc(userRef);
    const user = userDoc.data();
    if (!user?.controls) {
      try {
        const res = await updateDoc(userRef, {
          notificationControls: {
            new: true,
            join: true,
            reminder: true,
            update: true,
            left: true,
            message: true,
          },
        });
      } catch (err) {
        console.log("error", err);
      }
    }
    const res = await updateDoc(userRef, {
      token: token,
    });
    if (res) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const sendNewEventNotification = async (event) => {
  const eventCity = event?.address?.city;
  const users = [];
  const usersRef = collection(db, "users");
  const usersSnapshot = await getDocs(usersRef);

  usersSnapshot.forEach((doc) => {
    const user = doc.data();
    try {
      const userCity = user.location?.city;
      if (userCity === eventCity) {
        users.push(user);
      }
    } catch (err) {
      console.log("error", err);
    }
  });

  for (const user of users) {
    if (user.notificationControls?.new === true) {
      const file = event.photos[0];
      const storageRef = ref(storage, `${file.name}`); // Upload the image file to Firebase Storage
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef); // Get the download URL of the uploaded image
      const userToken = user.token;
      const data = {
        to: userToken,
        notification: {
          title: `New Event - ${event.title}`,
          body: "There is a new event near you",
          priority: "high",
          link: `${window.location.origin}/event/${event.id}`,
          sound: "default",
        },
        data: {
          id: user.uid,
          link: `${window.location.origin}/event/${event.id}`,
          type: "new",
          photo: downloadURL,
        },
      };
      const headers = {
        "Content-Type": "text/plain",
      };
      const res = await axios.post(url, JSON.stringify(data), {
        headers: headers,
      });
    }
  }
};

export const sendEventJoinNotification = async (
  title,
  eventId,
  creatorId,
  photo,
  event
) => {
  const user = await getCurrentUser();
  const receiverRef = await getDoc(doc(db, "users", user));
  const receiverUser = receiverRef.data();
  const eventCreatedBy = creatorId;
  const userRef = doc(db, "users", eventCreatedBy);
  const userDoc = await getDoc(userRef);
  const userToken = userDoc.data().token;
  if (userDoc.data().notificationControls.join === true) {
    sendEventScheduleNotification(event, receiverUser)
      .then((res) => {})
      .catch((err) => {
        console.log("error", err);
      });
    const data = {
      to: userToken,
      notification: {
        title: `${receiverUser.displayName} joined ${title}`,
        body: `joined your event`,
        priority: "high",
        link: `${window.location.origin}/event/${eventId}`,
        sound: "default",
      },
      data: {
        id: userDoc.data().uid,
        link: `${window.location.origin}/event/${eventId}`,
        type: "join",
        photo: photo,
      },
    };
    /* axios use headers content-type=text/plain */
    const headers = {
      "Content-Type": "text/plain",
    };
    const res = await axios.post(url, JSON.stringify(data), {
      headers: headers,
    });
  }
};

export const delLeftUserScheduleNotification = async (eventId, user) => {
  const schNotficationCollection = collection(db, "scheduleNotifications");
  let delID = "";
  const schNotficationSnapshot = await getDocs(schNotficationCollection);
  schNotficationSnapshot.forEach(function (doc) {
    let data = doc.data();
    const eventID = data.data.link.split("/event/")[1];
    const userID = data.data.id;
    if (eventID === eventId && user.uid === userID) {
      delID = doc.id;
    }
  });

  deleteDoc(doc(schNotficationCollection, delID))
    .then(() => {
      console.log("Document deleted successfully.");
    })
    .catch((error) => {
      console.error("Error deleting document:", error);
    });
};

export const delScheduleNotificationByEventID = async (eventId) => {
  const schNotficationCollection = collection(db, "scheduleNotifications");
  let delID = [];
  const schNotficationSnapshot = await getDocs(schNotficationCollection);
  schNotficationSnapshot.forEach(function (doc) {
    let data = doc.data();
    const eventID = data.data.link.split("/event/")[1];
    if (eventID === eventId) {
      delID.push(doc.id);
    }
  });

  delID.forEach((id) => {
    const documentRef = doc(schNotficationCollection, id);
    deleteDoc(documentRef)
      .then(() => {
        console.log("Document deleted successfully.");
      })
      .catch((error) => {
        console.error("Error deleting document:", error);
      });
  });
};

export const sendEventLeftNotification = async (eventId, event) => {
  try {
    const user = await getCurrentUser();
    const receiverRef = await getDoc(doc(db, "users", user));
    const receiverUser = receiverRef.data();
    const eventCreatedBy = event.CreatedBy;
    const userRef = doc(db, "users", eventCreatedBy);
    const userDoc = await getDoc(userRef);
    const userToken = userDoc.data().token;
    await delLeftUserScheduleNotification(eventId, receiverUser)
      .then((res) => {})
      .catch((err) => {
        console.log("error", err);
      });
    if (receiverUser.notificationControls.left === true) {
      const data = {
        to: userToken,
        notification: {
          title: `${receiverUser.displayName} has left ${event.title}`,
          body: `Left your event`,
          priority: "high",
          link: `${window.location.origin}/event/${eventId}`,
          sound: "default",
        },
        data: {
          id: userDoc.data().uid,
          link: `${window.location.origin}/event/${eventId}`,
          type: "left",
          photo: receiverUser.photoURL,
        },
      };
      /* axios use headers content-type=text/plain */
      const headers = {
        "Content-Type": "text/plain",
      };
      const res = await axios.post(url, JSON.stringify(data), {
        headers: headers,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const sendEventScheduleNotification = async (event, user) => {
  if (user.notificationControls.reminder === true) {
    const userToken = user.token;
    const data = {
      to: userToken,
      notification: {
        title: `${event.title} - Starting In 1 Hour`,
        body: "Event Starting In 1 Hour",
        priority: "high",
        link: `${window.location.origin}/event/${event.id}`,
        sound: "default",
      },
      data: {
        id: user.uid,
        link: `${window.location.origin}/event/${event.id}`,
        type: "reminder",
        isScheduled: true,
        scheduledTime: event.date.getTime(),
        photo: event.photos[0],
      },
    };
    const notificationId = Math.random().toString(36).substring(2);
    setDoc(doc(db, "scheduleNotifications", notificationId), data)
      .then(() => {
        console.log("add schedule events notifications");
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export const updateEventScheduleNotification = async (event, user) => {
  const [year, mon, day] = event.date.split("-");
  const [hour, min] = event.startTime.split(":");
  const userToken = user.token;
  const data = {
    to: userToken,
    notification: {
      title: `${event.title} - Starting In 1 Hour`,
      body: "Event Starting In 1 Hour",
      priority: "high",
      link: `${window.location.origin}/event/${event.id}`,
      sound: "default",
    },
    data: {
      id: user.uid,
      link: `${window.location.origin}/event/${event.id}`,
      type: "reminder",
      isScheduled: true,
      scheduledTime: new Date(year, mon - 1, day, hour, min).getTime(),
      photo: event.photos[0],
    },
  };
  const docRef = collection(db, "scheduleNotifications");
  const schNotficationSnapshot = await getDocs(docRef);

  let updateDocs = [];
  schNotficationSnapshot.forEach((doc) => {
    const docData = doc.data();
    const eventID = docData.data.link.split("/event/")[1];
    if (eventID === event.id) {
      updateDocs.push(doc);
    }
  });
  for (let i = 0; i < updateDocs.length; i++) {
    const updatedDoc = updateDocs[i];
    updateDoc(doc(db, "scheduleNotifications", updatedDoc.id), data)
      .then(() => {
        console.log("update schedule events notifications");
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export const sendEventUpdateNotification = async (event, type, reason) => {
  const users = [];
  for (let i = 0; i < event.joined.length; i++) {
    const userID = event.joined[i];
    const userData = await (await getDoc(doc(db, "users", userID))).data();
    users.push(userData);
  }

  for (const user of users) {
    if (user.notificationControls.update === true) {
      let file = event.photos[0];
      if (typeof file !== "string") {
        const storageRef = ref(storage, `${file.name}`); // Upload the image file to Firebase Storage
        await uploadBytes(storageRef, file);
        file = await getDownloadURL(storageRef); // Get the download URL of the uploaded image
      }

      const userToken = user.token;
      let data = {};
      if (type === "cancel") {
        data = {
          to: userToken,
          notification: {
            title: `${event.title} event cancelled due to ${
              reason === "Other" ? "Some Reason" : reason
            } `,
            body: `${event.title} event cancelled due to ${
              reason === "Other" ? "Some Reason" : reason
            }  `,
            priority: "high",
            link: `${window.location.origin}/event/${event.id}`,
            sound: "default",
          },
          data: {
            id: user.uid,
            link: `${window.location.origin}/event/${event.id}`,
            type: "update",
            photo: file,
          },
        };
      } else {
        data = {
          to: userToken,
          notification: {
            title: `There are some updates in ${event.title} event`,
            body: `There are some updates in ${event.title} event`,
            priority: "high",
            link: `${window.location.origin}/event/${event.id}`,
            sound: "default",
          },
          data: {
            id: user.uid,
            link: `${window.location.origin}/event/${event.id}`,
            type: "update",
            photo: file,
          },
        };
        updateEventScheduleNotification(event, user);
      }

      const headers = {
        "Content-Type": "text/plain",
      };
      const res = await axios.post(url, JSON.stringify(data), {
        headers: headers,
      });
    }
  }
  if (type === "cancel") {
    delScheduleNotificationByEventID(event.id);
  }
};

export const getNotificationControls = async () => {
  currentUser = await getCurrentUser();
  if (currentUser) {
    const userRef = doc(db, "users", currentUser);
    const userDoc = await getDoc(userRef);
    const user = userDoc.data();
    return user.notificationControls;
  } else {
    return false;
  }
};

export const updateNotificationControls = async (controls) => {
  currentUser = await getCurrentUser();
  if (currentUser) {
    const userRef = doc(db, "users", currentUser);
    const userDoc = await getDoc(userRef);
    const res = await updateDoc(userRef, {
      notificationControls: controls,
    });
    if (res) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const CommentNotify = async (id) => {
  try {
    const currentUser = getCurrentUser();
    const event = await (await getDoc(doc(db, "events", id))).data();
    const users = [];
    for (let i = 0; i < event.joined.length; i++) {
      const userID = event.joined[i];
      if (currentUser !== userID) {
        const userData = await (await getDoc(doc(db, "users", userID))).data();
        users.push(userData);
      }
    }
    const currrentUserData = await (
      await getDoc(doc(db, "users", currentUser))
    ).data();
    console.log(users);
    for (const user of users) {
      if (user.notificationControls.message === true) {
        let file = event.photos[0];

        const userToken = user.token;
        let data = {};
        data = {
          to: userToken,
          notification: {
            title: `${currrentUserData.displayName} comment on ${event.title} event`,
            body: `${currrentUserData.displayName} comment on ${event.title} event`,
            priority: "high",
            link: `${window.location.origin}/event/${id}`,
            sound: "default",
          },
          data: {
            id: user.uid,
            link: `${window.location.origin}/event/${id}`,
            type: "message",
            photo: file,
          },
        };

        const headers = {
          "Content-Type": "text/plain",
        };
        const res = await axios.post(url, JSON.stringify(data), {
          headers: headers,
        });
      }
    }
  } catch (error) {
    console.log(error);
  }
};
