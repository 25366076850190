const MasterCard = () => (
    <svg
        width="40"
        height="25"
        viewBox="0 0 40 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 22.0139C17.8816 23.8759 15.1338 25 12.1311 25C5.4313 25 0 19.4036 0 12.5C0 5.59644 5.4313 0 12.1311 0C15.1338 0 17.8816 1.12406 20 2.98608C22.1183 1.12406 24.8662 0 27.8688 0C34.5687 0 40 5.59644 40 12.5C40 19.4036 34.5687 25 27.8688 25C24.8662 25 22.1183 23.8759 20 22.0139Z"
            fill="#ED0006"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 22.0139C22.6084 19.7212 24.2623 16.3096 24.2623 12.5C24.2623 8.69038 22.6084 5.27881 20 2.98608C22.1184 1.12406 24.8662 0 27.8688 0C34.5687 0 40 5.59644 40 12.5C40 19.4036 34.5687 25 27.8688 25C24.8662 25 22.1184 23.8759 20 22.0139Z"
            fill="#F9A000"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 22.0141C22.6084 19.7213 24.2623 16.3098 24.2623 12.5002C24.2623 8.69061 22.6084 5.27906 20 2.98633C17.3917 5.27906 15.7378 8.69061 15.7378 12.5002C15.7378 16.3098 17.3917 19.7213 20 22.0141Z"
            fill="#FF5E00"
        />
    </svg>
);

export default MasterCard;
