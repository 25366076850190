import DefaultModal from "./DefaultModal";
import {Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextareaAutosize, Typography} from "@mui/material";
import {useState} from "react";
import {CancelEvent, FlagEvent} from "../firebase/functions/event";
import {toast} from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../routes";
import { useSelector } from "react-redux";
import { sendEventUpdateNotification } from "../firebase/functions/messaging";

const CancelEventModal = ({id, open, onClose}) => {
    const updateEventData = useSelector(state => state.EventDataReducer.updateEventData)
    const [reason, setReason] = useState("Weather");
    const navigate = useNavigate()
    const onModalClose = () => {
        setReason("Weather");
        onClose();
    };

    const cancelEventFunction = async () => {
        CancelEvent(id, reason).then(()=>{
            toast.success("Event Cancelled successfully");
            sendEventUpdateNotification({...updateEventData,id:id},"cancel", reason)
            onModalClose();
            navigate(ROUTES.HOME)
        })
    }
           

    return (
        <DefaultModal open={open} onClose={onModalClose}>
            <Stack textAlign="center" gap={3}>
                <Typography variant="h4">Cancel Event</Typography>
                <FormControl>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Weather"
                    name="radio-buttons-group"
                    onChange={(e)=>setReason(e.target.value)}
                >
                    <FormControlLabel value="Weather" control={<Radio />} label="Weather" />
                    <FormControlLabel value="Short Attandence" control={<Radio />} label="Short Attandence" />
                    <FormControlLabel value="Permit or Licensing Problems" control={<Radio />} label="Permit or Licensing Problems" />
                    <FormControlLabel value="Health and Public Safety Concerns" control={<Radio />} label="Health and Public Safety Concerns" />
                    <FormControlLabel value="Safety Concerns" control={<Radio />} label="Safety Concerns" />
                    <FormControlLabel value="Other" control={<Radio />} label="Other" />
                </RadioGroup>
                </FormControl>
                <Button
                    onClick={cancelEventFunction}
                    variant="contained"
                    fullWidth
                    sx={{
                        backgroundColor: "#F56C6C",
                        color: "info.main",
                        borderRadius: 8,
                        padding: 1,
                        "&:hover": {
                            backgroundColor: "#F25050",
                        },
                    }}
                >
                    Cancel Event
                </Button>
            </Stack>
        </DefaultModal>
    );
};

export default CancelEventModal;
