import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import MapView from "../MapView/MapView";
import { MODALS } from "../../../modals/modals";
import evenUser1 from "../../../images/even-user-1.png";
import evenUser2 from "../../../images/even-user-2.png";
import evenUser3 from "../../../images/even-user-3.png";
import frisbee4 from "../../../images/firsbee-4.png";
import frisbee2 from "../../../images/frisbee-2.png";
import frisbee3 from "../../../images/frisbee-3.png";
import glof from "../../../images/glof.png";
import {
  GetExploreEvents,
  GetExploreEventsFromUserLocation,
} from "../../../firebase/functions/event/sort-event";
import { useModalsContext } from "../../../modals/ModalsContext";
import { useSelector } from "react-redux";
import { setOpenTagModel } from "../../../store/ModelSlice";
import LocationHook from "../../../hooks/useLocationHook";
import { tagsMap } from "../MapView/Map";
import { useContext } from "react";
import { Context } from "../../../context/StateContext";

// Define your events array
const events = [
  {
    id: 1,
    title: "Ultimate Golf",
    location: "Central City Park",
    date: "25 Jun, 2022",
    time: "10:00 am",
    image: glof,
    eventJoined: [
      { id: 1, image: evenUser1 },
      { id: 2, image: evenUser2 },
      { id: 3, image: evenUser3 },
    ],
  },
  {
    id: 2,
    title: "Ultimate Frisbee",
    location: "Central City Park",
    date: "25 Jun, 2022",
    time: "10:00 am",
    image: frisbee2,
    eventJoined: [
      { id: 1, image: evenUser1 },
      { id: 2, image: evenUser2 },
      { id: 3, image: evenUser3 },
    ],
  },
  {
    id: 3,
    title: "Ultimate Frisbee",
    location: "Central City Park",
    date: "25 Jun, 2022",
    time: "10:00 am",
    image: frisbee3,
    eventJoined: [
      { id: 1, image: evenUser1 },
      { id: 2, image: evenUser2 },
      { id: 3, image: evenUser3 },
    ],
  },
  {
    id: 4,
    title: "Ultimate Frisbee",
    location: "Central City Park",
    date: "25 Jun, 2022",
    time: "10:00 am",
    image: frisbee4,
    eventJoined: [
      { id: 1, image: evenUser1 },
      { id: 2, image: evenUser2 },
      { id: 3, image: evenUser3 },
    ],
  },
];

const ExploreView = ({ filters }) => {
  const location = useSelector((state) => state.LocationReducer.location);
  const userInfo = useSelector((state) => state.UserReducer.userInfo);

  const positionPoints = useSelector(
    (state) => state.LocationReducer.positionPoints
  );
  console.log({filters});

  const tags = useSelector((state) => state.ModelReducer.tags);
  const filterObj = useSelector((state) => state.ModelReducer.filterObj);

  const { setCurrentLocationPoints } = LocationHook();

  const [view, setView] = useState("map");
  const [events, setEvents] = useState([]);

  // const [filteredEvents, setFilteredEvents] = useState(null);
  const { exploreEvents, setExploreEvents } = useContext(Context);

  const [city, setCity] = useState(null);
  const [eventCategory, setEventCategory] = useState(tagsMap[0]);
  const [userLocation, setUserLocation] = useState(null);
  const [shouldReset, setShouldReset] = useState(false);

  const handleView = (view) => {
    setView(view);
  };

  useEffect(() => {
    
    if (filterObj && filterObj !== null) {
      GetExploreEventsFromUserLocation(
        20,
        filterObj,
        positionPoints.lat,
        positionPoints.lng,
        tags
      ).then((data) => {
        console.log("get events data", data);

        setEvents(data);
        setExploreEvents(data);
      }).catch((err) =>{
        console.log("get events error", err);
      });
    }
  }, []);

  useEffect(() => {
    if (filterObj && filterObj !== null) return;
    GetExploreEventsFromUserLocation(
      20,
      filterObj,
      positionPoints.lat,
      positionPoints.lng,
      tags
    ).then((data) => {

      setEvents(data);
      setExploreEvents(data);
    });
  }, [view, location, tags, filterObj]);

  useEffect(() => {
    if (eventCategory.value !== "others") {
      const filterEvents = events?.filter((e) =>
        e?.category?.includes(eventCategory.value)
      );
      setExploreEvents(filterEvents);
    } else {
      setExploreEvents(events);
    }
  }, [eventCategory]);

  return (
    <Box
      className="borders"
      sx={{
        width: "100%",
        zIndex: 12,
        position: "absolute",
      }}
    >
      <Box sx={{ pb: 0 }}>
        <MapView
          handleView={handleView}
          view={view}
          setView={setView}
          tags={tags}
          filters={filters}
          events={exploreEvents}
          setEventCategory={setEventCategory}
          eventCategory={eventCategory}
        />
      </Box>
    </Box>
  );
};

export default ExploreView;
