import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
const SwiperNotification = ({ children,deleteId, onDelete }) => {

    const [startX, setStartX] = useState(null);
    const [isSwiped, setIsSwiped] = useState(false);
    const handleStart = (clientX) => {
        setStartX(clientX);
        // setIsSwiped(false);
    };
    const handleMove = (clientX) => {
        if (startX !== null) {
            const deltaX = clientX - startX;
            setIsSwiped(deltaX < -50);
        }
    };
    const handleEnd = () => {
        // if (isSwiped) {
        //     onDelete();
        // }
        setStartX(null);
        // setIsSwiped(false);
    };
    const handleTouchStart = (event) => {
        handleStart(event.touches[0].clientX);
    };
    const handleTouchMove = (event) => {
        handleMove(event.touches[0].clientX);
    };
    const handleTouchEnd = () => {
        handleEnd();
    };
    const handleMouseDown = (event) => {
        handleStart(event.clientX);
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };
    const handleMouseMove = (event) => {
        handleMove(event.clientX);
    };
    const handleMouseUp = () => {
        handleEnd();
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
    };
    return (
        <Box
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove} // Add this line for mouse events
            sx={{
                position: "relative",
                overflow: "hidden",
                cursor: "pointer", // Added cursor style for better UX
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    right: isSwiped ? "0" : "-100px",
                    bottom: 0,
                    width: "100px",
                    backgroundColor: "#FF0000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "right 0.3s",
                    borderRadius: "13px",
                    zIndex: 99,
                }}
                onClick={()=>onDelete(deleteId)}
            >
                <Typography variant="body1" sx={{ color: "#FFFFFF" }}>
                    Delete
                </Typography>
            </Box>
            {children}
        </Box>
    );
};
export default SwiperNotification;
