import { Box } from "@mui/material";

const Tags = ({ tagsMap, setEventCategory, eventCategory }) => {
  const SetCategory = (e) => {
    setEventCategory(e);
  };

  return (
    <div className="tags-swiper-md">
      {tagsMap.map((tag, index) => (
        <Box
          onClick={() => {
            SetCategory(tag);
          }}
          key={index}
          sx={{
            height: "40px",
            width: "107px",
            background:
              eventCategory.name === tag.name
                ? `linear-gradient(70.5deg, rgba(39, 201, 201, 0.7) 0%, rgba(255, 223, 111, 0.7) 100.49%), linear-gradient(0deg, #FFFFFF, #FFFFFF)`
                : "#FFFFFF",
            borderRadius: "21px",
            boxShadow: "0px 6px 20px 0px #2E2E4F1F",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "10px",
          }}
        >
          {tag.logo}
          <p
            style={{
              fontSize: "15px",
              fontWeight: "400",
              marginLeft: "8px",
            }}
          >
            {tag.name}
          </p>
        </Box>
      ))}
    </div>
  );
};

export default Tags;
