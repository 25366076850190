import { Box } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";

const TagsSwiper = ({ tagsMap, setEventCategory, eventCategory }) => {
  const SetCategory = (e) => {
    setEventCategory(e);
  };

  return (
    <Box
      sx={{
        width: "100%",
        paddingTop: "10px",
      }}
    >
      <Box
        sx={{
          marginLeft: { xs: "1.7rem" },
          boxShadow: "0px 6px 20px 0px #2E2E4F1F",
        }}
      >
        <Swiper
          slidesPerView={3.2}
          spaceBetween={2}
          grabCursor={true}
          breakpoints={{
            600: {
              slidesPerView: 4,
              spaceBetween: 4,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 4,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 4,
            },
          }}
          className="mySwiper"
        >
          <div className="slider up" style={{ display: "flex" }}>
            {tagsMap.map((tag, index) => {
              return (
                <SwiperSlide
                  key={index}
                  style={{ width: "130px", marginRight: "6px" }}
                >
                  <Box
                    onClick={() => {
                      SetCategory(tag);
                    }}
                    sx={{
                      height: "40px",
                      width: "107px",
                      background:
                        eventCategory.name === tag.name
                          ? `linear-gradient(70.5deg, rgba(39, 201, 201, 0.7) 0%, rgba(255, 223, 111, 0.7) 100.49%), linear-gradient(0deg, #FFFFFF, #FFFFFF)`
                          : "#FFFFFF",
                      borderRadius: "21px",
                      boxShadow: "0px 6px 20px 0px #2E2E4F1F",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {tag?.logo}
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        marginLeft: "8px",
                      }}
                    >
                      {tag?.name}
                    </p>
                  </Box>
                </SwiperSlide>
              );
            })}
          </div>
        </Swiper>
      </Box>
    </Box>
  );
};

export default TagsSwiper;
