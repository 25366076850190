import { Container, Stack, Typography } from "@mui/material";
import PageHeader from "../components/PageHeader";
const PrivacyPolicy = () => {
    return (
        <Container sx={{ my: 4 }}>
            <Stack gap={4}>
                <PageHeader title="Privacy Policy" />
                <Typography variant="h3" fontWeight="bold">
                    Recess Privacy Introduction
                </Typography>
                {/* Effective Date: 6.18.2023 */}
                <Typography color="#8E949B" variant="body1">
                    Effective: June 19, 2023
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                    At Recess Games, LLC, we value your privacy and are committed to
                    protecting your personal information. This Privacy Policy explains how
                    we collect, use, disclose, and safeguard your data when you use our
                    web application. By using our services, you consent to the practices
                    described in this policy. Please read this Privacy Policy carefully.
                </Typography>
                <Typography variant="body1">
                    <h1 style={{ fontSize: "1.5rem" }}>1. Information We Collect:</h1>
                    {/* <br /> */}
                    <br />   a. Personal Information: We may collect personal information
                    such as your name, email address, phone number, and payment details
                    when you register an account or make transactions on our platform.
                    <br />
                    <br />   b. Event Information: We collect information about the events
                    you create, manage, or attend, including event details, attendee
                    lists, and related communication.
                    <br />
                    <br />
                    c. Usage Data: We collect non-personal information about your
                    interactions with our platform, such as your IP address, browser type,
                    device information, and access times.
                    <br />
                    <br />
                    <h1 style={{ fontSize: "1.5rem" }}>2. Use of Information:</h1>
                    <br />    a. We use your personal information to provide and improve
                    our services, authenticate users, process transactions, and
                    communicate with you regarding your account and events.
                    <br />
                    <br />    b. We may use your email address to send you promotional or
                    informational messages related to our services. You can opt out of
                    receiving such communications at any time.
                    <br />
                    <br />
                    c. We may analyze usage data to enhance our platform, identify
                    trends, and personalize your experience.
                    <br />
                    <br />
                    <h1 style={{ fontSize: "1.5rem" }}>3. Information Sharing:</h1>
                    <br />    a. We may share your personal information with trusted third
                    parties, such as payment processors, event organizers, or service
                    providers, only to the extent necessary to fulfill our contractual
                    obligations and provide the requested services.
                    <br />
                    <br />    b. We may disclose your information if required by law or to
                    protect our rights, property, or safety, as well as the rights,
                    property, and safety of others.
                    <br />
                    <br />    c. In the event of a merger, acquisition, or sale of assets,
                    your personal information may be transferred to the acquiring entity,
                    subject to their agreement to abide by this Privacy Policy.
                    <br />
                    <br />
                    <h1 style={{ fontSize: "1.5rem" }}>4. Data Security:</h1>
                    <br />    a. We implement reasonable security measures to protect your
                    personal information from unauthorized access, disclosure, alteration,
                    or destruction.
                    <br />
                    <br />    b. However, please note that no method of transmission over
                    the internet or electronic storage is completely secure. We cannot
                    guarantee absolute security of your data.
                    <br />
                    <br />
                    <h1 style={{ fontSize: "1.5rem" }}>5. Data Retention:</h1>
                    <br />   a. We retain your personal information for as long as
                    necessary to fulfill the purposes outlined in this Privacy Policy,
                    unless a longer retention period is required or permitted by law.
                    <br />
                    <br />    b. You may request the deletion of your account and personal
                    information. However, please note that certain information may need to
                    be retained for legal or legitimate business purposes.
                    <br />
                    <br />
                    <h1 style={{ fontSize: "1.5rem" }}>
                        6. Third-Party Links and Services:
                    </h1>
                    <br />   a. Our platform may contain links to third-party websites or
                    services. We are not responsible for the privacy practices or content
                    of these third parties. We encourage you to review their privacy
                    policies before providing any personal information.
                    <br />
                    <br />
                    <h1 style={{ fontSize: "1.5rem" }}>7. Children's Privacy:</h1>
                    <br />    a. Our services are not intended for individuals under the
                    age of 18. We do not knowingly collect personal information from
                    children. If we become aware that we have inadvertently collected
                    personal data from a child, we will take steps to delete such
                    information.
                    <br />
                    <br />
                    <h1 style={{ fontSize: "1.5rem" }}>
                        8. Updates to the Privacy Policy:
                    </h1>
                    <br />    a. We may update this Privacy Policy from time to time. The
                    updated policy will be posted on our platform, and the effective date
                    will be indicated. We encourage you to review the Privacy Policy
                    periodically.
                    <br />
                    <br />
                    <h1 style={{ fontSize: "1.5rem" }}>9. Contact Us:</h1>
                    <br />
                    If you have any questions, concerns, or requests regarding
                    this Privacy Policy or our data practices, please contact us at <a href="mailto:admin@playrecess.app">admin@playrecess.app</a>
                    .
                    <br />
                    <br />
                    By using our web application, you acknowledge that you have read,
                    understood, and agreed to the practices described in this Privacy
                    Policy. If you do not agree with this policy, please refrain from
                    using our services.
                </Typography>
            </Stack>
        </Container>
    );
};
export default PrivacyPolicy;
