import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

// import * as React from "react";
// import { useState, useEffect } from "react";
import { TiLocation } from "react-icons/ti";
import {
  IoNotificationsOutline,
  IoNotificationsSharp,
  IoCalendarOutline,
  IoCalendarSharp,
} from "react-icons/io5";
import { BiCalendar } from "react-icons/bi";
import { MdExplore } from "react-icons/md";
import { RiHome6Fill, RiHome6Line, RiSearch2Line } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import LocationImage from "../../icons/LocationImage";
import LocationIconActive from "../../icons/LocationIconActive";
import { useSelector } from "react-redux";

// import {
//   getAllNotifications,
//   returnJoinedNotifications,
//   getAllNotificationDelete,
// } from "../../firebase/functions/messaging/notifications";

const paths = [
  // {
  //   path: "/home",
  //   icon: <RiHome6Line />,
  //   activeIcon: <RiHome6Fill />,
  //   label: "Home",
  // },
  {
    path: "/home",
    icon: <MdExplore />,
    activeIcon: <MdExplore />,
    label: "Explore",
  },
  {
    path: "/explore",
    icon: <TiLocation />,
    activeIcon: <TiLocation />,
    label: "Map",
  },
  // {
  //   path: "/events",
  //   icon: <BiCalendar />,
  //   activeIcon: <BiCalendar />,
  //   label: "Event",
  // },
  {
    path: "/events",
    icon: <IoCalendarOutline />,
    activeIcon: <IoCalendarSharp />,
    label: "Events",
  },
  // {
  //   path: "/notifications",
  //   icon: <IoNotificationsOutline />,
  //   activeIcon: <IoNotificationsSharp />,
  //   label: "Notifications",
  // },
  // {
  //   path: "/profile",
  //   icon: <HiOutlineUser />,
  //   activeIcon: <HiUser />,
  //   label: "Profile",
  // },
];

// navbar
const MobileNavbar = () => {
  const [active, setActive] = useState(false);
  const isOpen = useSelector((state) => state.OpenMenuReducer.isOpen);
  //   const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  //   useEffect(() => {
  //     getAllNotifications().then(() => {
  //       const notifyData = returnJoinedNotifications();
  //       setNotifications(notifyData);
  //     });
  //   }, []);

  const handleNavClick = (path) => {
    navigate(path);
    setActive(!active);
  };
  console.log("navbar");
  const mobileMenu = (
    <Box
      sx={{
        px: 2,
        // py: 1,
        bgcolor: "info.main",
        // borderRadius: "25px 25px 0px 0px",
        width: "100%",
        borderRadius: isOpen ? "0px 0px 0px 33px" : 0,
      }}
    >
      <nav
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        {paths.map((item) => (
          <Button
            onClick={() => handleNavClick(item.path)}
            className="pathIcon"
            sx={{
              textTransform: "capitalize",
              color: pathname === item.path ? "#20ACAC" : "#afb1ba",
              fontWeight: pathname === item.path ? 700 : 500,
              flexDirection: "column",
              fontSize: "14px",
            }}
            variant="text"
            key={item.label}
          >
            {pathname === item.path ? item.activeIcon : item.icon}
            {item.label}
          </Button>
        ))}
      </nav>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Box
        sx={{
          position: "fixed",
          bottom: isOpen ? "9%" : 0,
          width: "100%",
          zIndex: 999,
          marginLeft: isOpen ? "70%" : 0,
          transition: "margin 225ms ease-out",
        }}
      >
        {mobileMenu}
      </Box>
    </Box>
  );
};

export default MobileNavbar;
