import { createSlice } from '@reduxjs/toolkit'


export const OpenMenuReducer = createSlice({
    name: 'menu',
    initialState: {
     isOpen : false
    },
    reducers: {
          setOpenMenu: (state,action) => {
              state.isOpen = action.payload;
          },
      }   
  })
  
  // Action creators are generated for each case reducer function
  export const { setOpenMenu, } = OpenMenuReducer.actions
  
  export default OpenMenuReducer.reducer