import DefaultModal from "./DefaultModal";
import { Button, Slider, SliderThumb, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import { ReactComponent as CalenderIcon } from "../images/Calendar.svg";
import { ReactComponent as ArrowRight } from "../images/ArrowRight.svg";
import { ReactComponent as ArrowRight1 } from "../images/ArrowRight1.svg";
import { ReactComponent as RangeImage } from "../images/range-image.svg";
import { ReactComponent as LocationFilter } from "../images/location-filter.svg";
import { setAppliedFilter, setAppliedFilterObj } from "../store/ModelSlice";
import { useDispatch, useSelector } from "react-redux";

import { useContext } from "react";
import { Context } from "../context/StateContext";
import { capitalizeFirstLetter, Cities } from "../utils/allCities";
import { GetExploreEventsFromUserLocation } from "../firebase/functions/event/sort-event";

const MyButton = styled(Button)(({ theme }) => ({
  display: "inline-block",
  borderRadius: "10px",
  border: "1px solid #E6E6E6",
  color: "#807A7A",
  padding: "7px 20px",
  fontSize: "16px",
  fontWeight: 400,
  "&.MuiButton-contained": {
    backgroundColor: "#F56C6C",
    color: " #FFFFFF",
  },
  "&:hover": {
    borderColor: "#E5E5E5",
    boxShadow: "none",
  },
}));

const CustomDateInput = styled(TextField)(({ theme, customDay }) => ({
  '& input[type="date"]': {
    padding: "22px 70px",
    border: "1px solid #E6E6E6",
    borderRadius: "10px",
    fontSize: "15px",
    fontWeight: 400,
    color: customDay === "custom" ? "white" : "#807A7A",
    backgroundColor: customDay === "custom" ? "#F56C6C" : "transparent",
  },
}));

const CustomStack = styled(Stack)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  &.outlined {
    border: 1px solid #e5e5e5;
    color: #303030;
    border-radius: 14px;
    padding: 0.9rem 2.9rem;
    &:hover {
      border-color: #e5e5e5;
      box-shadow: none;
    }
  }

  &.contained {
    border: 1px solid #20acac;
    color: #ffffff;
    border-radius: 14px;
    padding: 0.9rem 4.3rem;
    background-color: #20acac;
    &:hover {
      background-color: #20acac;
      box-shadow: none;
    }
  }
`;

const StyledTypography = styled(Typography)`
  text-align: start;
  font-weight: 400;
  color: #303030;
`;

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: "#F56C6C",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 35,
    width: 35,
    backgroundColor: "#fff",
    border: "1px solid #F24040",
    borderRadius: "9px",
    "&:hover": {
      boxShadow: "none",
    },
  },
  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#F56C6C" : "#FDE7E7",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
}));

function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span>
        <RangeImage />
      </span>
    </SliderThumb>
  );
}
AirbnbThumbComponent.propTypes = {
  children: PropTypes.node,
};

const SortAndFilterModal = ({ open, onClose, onApply }) => {
  // eventsData has our array to which we have to applying sorting & filter
  const positionPoints = useSelector(
    (state) => state.LocationReducer.positionPoints
  );
  const tags = useSelector((state) => state.ModelReducer.tags);
  const filterObj = useSelector((state) => state.ModelReducer.filterObj);
  const location = useSelector((state) => state.LocationReducer.location);
  const { exploreEvents, setExploreEvents } = useContext(Context);
  const dispatch = useDispatch();
  const initialState = {
    day: "Today",
    date: "",
    location: location.structured_formatting.main_text,
    minPrice: 0,
    maxPrice: 0,
  };

  const [obj, setObj] = useState(filterObj !== null ? filterObj : initialState);
  const [sliderValues, setSliderValues] = useState([
    obj.minPrice,
    obj.maxPrice,
  ]);

  const [state, setState] = useState({
    selectedSortOption: null,
    priceRange: null,
    sponsoredListings: false,
  });

  const onResetClick = () => {
    dispatch(setAppliedFilter());
    dispatch(setAppliedFilterObj(null));
    GetExploreEventsFromUserLocation(
      20,
      filterObj,
      positionPoints.lat,
      positionPoints.lng,
      tags
    ).then((data) => {
      setExploreEvents(data);
    });
    onClose();
  };

  const onApplyClick = () => {
    onApply(obj);
    dispatch(setAppliedFilterObj(obj));
    GetExploreEventsFromUserLocation(
      20,
      obj,
      positionPoints.lat,
      positionPoints.lng,
      tags
    ).then((data) => {
      setExploreEvents(data);
    });

    let i = 0;
    Object.values(obj).map((item) => {
      if (item === null || item === "") {
      } else {
        i++;
      }
    });
    dispatch(setAppliedFilter(i - 2));
    onClose();
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValues(newValue);
    setObj((prev) => ({
      ...prev,
      minPrice: newValue[0],
      maxPrice: sliderValues[1],
    }));
  };

  const handleDayChange = (e) => {
    if (e === "Tomorrow") {
      let today = new Date();
      today.setDate(today.getDate() + 1);
      const date = today.toISOString().split("T")[0];
      setObj((prev) => ({
        ...prev,
        date: date,
        day: e,
      }));
    } else if (e === "week") {
      let today = new Date();
      today.setDate(today.getDate() + 7);
      const date = today.toISOString().split("T")[0];
      setObj((prev) => ({
        ...prev,
        date: date,
        day: e,
      }));
    } else {
      let today = new Date();
      const date = today.toISOString().split("T")[0];
      setObj((prev) => ({
        ...prev,
        date: date,
        day: e,
      }));
    }
  };

  return (
    <DefaultModal open={open} onClose={onClose}>
      <Stack textAlign="start" gap={3}>
        <Typography
          variant="h3"
          fontSize={"25px"}
          fontWeight={"400"}
          color={"#303030"}
        >
          Filter
        </Typography>
        <Stack gap={2}>
          <StyledTypography variant="h5">Time & Date</StyledTypography>
          <CustomStack>
            <MyButton
              onClick={() => {
                handleDayChange("Today");
              }}
              variant={obj.day === "Today" ? "contained" : "outlined"}
            >
              Today
            </MyButton>
            <MyButton
              onClick={() => {
                handleDayChange("Tomorrow");
              }}
              variant={obj.day === "Tomorrow" ? "contained" : "outlined"}
            >
              Tomorrow
            </MyButton>
            <MyButton
              onClick={() => {
                handleDayChange("week");
              }}
              variant={obj.day === "week" ? "contained" : "outlined"}
            >
              This week
            </MyButton>
          </CustomStack>
          <Stack
            width={"70%"}
            className="filter-model"
            sx={{ display: "flex", justifyContent: "center" }}
            color={obj.day !== "custom" ? "#EB5757" : "white"}
          >
            <CalenderIcon
              style={{ position: "absolute", left: "8%", zIndex: 99 }}
            />
            <CustomDateInput
              type="date"
              customDay={obj.day}
              value={obj.day === "custom" ? obj.date : ""}
              onChange={(e) => {
                setObj((prev) => ({
                  ...prev,
                  date: e.target.value,
                  day: "custom",
                }));
              }}
            />
            <ArrowRight style={{ position: "absolute", right: "8%" }} />
          </Stack>
        </Stack>

        <Stack gap={2}>
          <StyledTypography variant="h5">Location</StyledTypography>
          <Stack
            width={"100%"}
            flexDirection={"row"}
            alignItems={"center"}
            sx={{
              border: "1px solid #E5E5E5",
              borderRadius: "15px",
              padding: "8px",
            }}
          >
            <LocationFilter />
            <select
              style={{
                fontSize: "16px",
                fontWeight: "400",
                color: "#141736",
                WebkitAppearance: "none" /* for Chrome */,
                MozAppearance: "none" /* for Firefox */,
                msExpand: "none",
              }}
              className="state-dropdown-filter placeHolder"
              value={capitalizeFirstLetter(obj.location)}
              onChange={(e) => {
                setObj((prev) => ({
                  ...prev,
                  location: e.target.value.toLowerCase(),
                }));
              }}
            >
              <option
                key={0}
                value={""}
                hidden={obj.location === "" ? true : false}
              >
                {location.structured_formatting.main_text}
              </option>
              {Cities.map((city, index) => (
                <option key={index + 1} value={city}>
                  {city}
                </option>
              ))}
            </select>
            <ArrowRight1 style={{ margin: "0px 20px" }} />
          </Stack>
        </Stack>
        <Stack>
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <StyledTypography variant="h5">Select price range</StyledTypography>
            <Typography
              sx={{ fontSize: "18px", fontWeight: 400, color: "#F56C6C" }}
            >
              ${obj.minPrice}-${obj.maxPrice}
            </Typography>
          </Stack>
          <Stack
            width={"100%"}
            margin={"2rem 0rem 1rem 0rem"}
            position={"relative"}
          >
            <AirbnbSlider
              aria-valuemax={"120"}
              slots={{ thumb: AirbnbThumbComponent }}
              getAriaLabel={(index) =>
                index === 0 ? "Minimum price" : "Maximum price"
              }
              value={sliderValues}
              onChange={handleSliderChange}
              min={0}
              max={120}
            />
          </Stack>
        </Stack>
        <CustomStack>
          <StyledButton className="outlined" onClick={onResetClick}>
            RESET
          </StyledButton>
          <StyledButton className="contained" onClick={onApplyClick}>
            APPLY
          </StyledButton>
        </CustomStack>
      </Stack>
    </DefaultModal>
  );
};

export default SortAndFilterModal;
