export const MODALS = {
    EVENT_CONFIRMATION: "EVENT_CONFIRMATION",
    EVENT_FLAG: "EVENT_FLAG",
    TAGS: "TAGS",
    TAGS_EXPLORER: "TAGS_EXPLORER",
    SORT_FILTER: "SORT_FILTER",
    ADD_KEYWORD: "ADD_KEYWORD",
    LEFT_EVENT: "ho",
    CANCEL_EVENT: "CANCEL_EVENT",
    PAYMENT_WITHDRAW: "PAYMENT_WITHDRAW",
};
