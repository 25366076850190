import React from "react";
import ReactDOM from "react-dom/client";
import "../src/styles/main.scss";
import "rc-slider/assets/index.css";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import StateContext from "./context/StateContext";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

    <StateContext>

        <App />

    </StateContext>
);

serviceWorkerRegistration.register();
