import { Route } from "react-router-dom";
import store from "../store/store";

export const ROUTES = {
  HOME: "/home",
  EXPLORE: "/explore",
  EXPLORE_GUEST: "/explore-guest",
  SAVED: "/saved",
  NOTIFICATIONS: "/notifications",
  PROFILE: "/profile",
  LOGIN: "/login",
  SIGN_UP: "/sign-up",
  FORGOT_PASSWORD: "/forgot-password",
  FORGOT_ACCOUNT_PASSWORD: "/forgot-account-password",
  EVENT_DETAILS: "/event/:id",
  PAST_UPCOMING_EVENTS: "/events",
  RESET_PASSWORD: "/reset-password",
  RESET_ACCOUNT_PASSWORD: "/reset-account-password",
  DELETE_ACCOUNT: "/delete-account",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_OF_SERVICES: "/terms-of-services",
  PAYMENTS: "/payments",
  STRIPE_PAYMENT: "/payment/:id/:uId",
  STRIPE_PAYMENT_SUCCESSFUL: "/event-sucessful/:id",
  SET_UP_PAYMENT: "/set-up-payment",
  PAY_WITH: "/pay-with",
  ADD_CARD_DETAILS: "/add-card-details",
  TRANSACTIONS: "/transactions",
  PAYMENT_DETAILS: "/payment-details",
  NOTIFICATIONS_SETTINGS: "/notifications-settings",
  CREATE_EVENT: "/create-event",
  CREATE_EVENT_GUEST: "/create-event-guest",
  PAYMENT_WITHDRAW: "/payment-withdraw",
};

const isAnonymous = () => {
  const { userInfo } = store.getState().UserReducer;
  if (userInfo && userInfo?.token !== ""){
    return false
  }
  else{
     return true
  }
};

export const isRouteProtected = (route) => {
  console.log('routeee',route);
  if (route === '/explore-guest' || route === '/explore-guest/'){
    return 'explore-guest'
  }
  else if (route === '/create-event-guest' || route === '/create-event-guest/') {
    return 'create-event-guest';
  }
  else if (isAnonymous()) {
    return true;
  } else {
    return false
  }
};
