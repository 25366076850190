import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addEvent, updateEvent, updatePaymentStatusEvent } from "../../firebase/functions/event";
import {
  sendEventUpdateNotification,
  sendNewEventNotification,
} from "../../firebase/functions/messaging";
import LocationPointsHook from "../../hooks/useLocationPoints";
import { useCreateEventContext } from "../../pages/CreateEvent";
import { setCreateEventId, setEventType } from "../../store/EventSlice";
import NextButton from "./NextButton";

const DetailCard = ({ title, children }) => {
  return (
    <Stack
      width="100%"
      borderRadius="8px"
      bgcolor="white"
      sx={{
        border: "1px solid #D9D9D9",
        padding: "10px",
      }}
    >
      {children}
    </Stack>
  );
};

const DetailCardPrice = ({ title, children }) => {
  return (
    <Stack
      width="70%"
      borderRadius="8px"
      bgcolor="white"
      sx={{
        border: "1px solid #D9D9D9",
        padding: "10px",
      }}
    >
      {children}
    </Stack>
  );
};

const TextPrice = ({ title }) => {
  return (
    <Typography sx={{ fontSize: "14px", fontWeight: 400, marginTop: "1rem" }}>
      <span style={{ color: "#F56C6C", marginRight: "5px" }}>*</span>
      {title}
    </Typography>
  );
};

const Text = ({ title }) => {
  return (
    <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
      <span style={{ color: "#F56C6C", marginRight: "5px" }}>*</span>
      {title}
    </Typography>
  );
};
const PricePage = ({ setPages, pages, state, setLoader, setEventId }) => {
  const { getAdrress, location } = LocationPointsHook();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isFreeEvent, setIsFreeEvent] = useState(false);
  const eventType = useSelector((state) => state.EventDataReducer.eventType);
  const uId = useSelector((state) => state?.UserReducer?.userInfo?.uid);

  const updateEventID = useSelector(
    (state) => state.EventDataReducer.updateEventID
  );
  const { maxParticipants, cost, setState } = useCreateEventContext();

  const NextPage = () => {
    setPages(pages + 1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!uId) {
      toast(
        "Login required for create event. Please login and then try again",
        { type: "info" }
      );
      return;
    }
    if (
      state?.photos === null ||
      state?.title === "" ||
      state?.description === "" ||
      // state.keywords === "" ||
      +state?.maxParticipants === 0 ||
      state?.displayAddress === null
    ) {
      toast("All fields must be filled", { type: "error" });
      return;
    }
    if (state.startTime >= state.endTime) {
      toast("Start time must be before end time and must be filled", {
        type: "error",
      });
      return;
    }
    if (state?.date === "") {
      toast("Date must be filled", { type: "error" });
      return;
    }

    if (
      state?.keywords.length > 20 ||
      state?.keywords.includes(" ") ||
      state?.keywords.includes(",")
    ) {
      toast(
        "Keywords must be 20 characters or less and cannot contain spaces",
        { type: "error" }
      );
      return;
    }

    try {
      const locationPoints = await getAdrress(
        `${state.startAddress} ${state.apartment} ${state.city}, ${state.state}`
      );

      const paymentData = {
        ...state,
        address: locationPoints,
        city: state.city.toLowerCase(),
        displayAddress: `${state.startAddress} ${state.apartment} ${state.city} ${state.state}`,

        userId: uId,
      };
      if (!isFreeEvent) {
        paymentData.payment = "pending";
      }
      setState(paymentData);

      NextPage();
      setLoader(false);
      if (eventType === "update") {
        updateEvent(paymentData, updateEventID, setLoader).then(() => {
          toast("Event Updated successfully", { type: "success" });
          sendEventUpdateNotification({ ...paymentData, id: updateEventID });
          setEventId(updateEventID);
        });
        dispatch(setEventType("create"));
      } else {
        addEvent(paymentData, setLoader)
          .then((eventId) => {
            if (isFreeEvent) {
              console.log({eventId});

                setEventId(eventId);
  
                dispatch(setEventType("create"));
                
                navigate(`/event-sucessful/${eventId}/?free`);
             
            } else {
              navigate(`/payment/${eventId}/${uId}`);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setPrice = (e) => {
    if (isFreeEvent) {
      setState((prev) => ({ ...prev, cost: 0 }));
    } else {
      setState((prev) => ({ ...prev, cost: e.target.value }));
    }
  };

  return (
    <Stack gap={2} width="100%">
      <Stack gap={2}>
        <TextPrice title="How many attendees are you planning for?" />
        <DetailCardPrice title="Event Name">
          <input
            className="placeHolder"
            value={maxParticipants}
            style={{ border: "none", outline: "none", fontSize: "16px" }}
            type="number"
            placeholder="Enter maximum # of attendees"
            onChange={(e) =>
              setState((prev) => ({ ...prev, maxParticipants: e.target.value }))
            }
          />
        </DetailCardPrice>
        <Text title="Last question, is there an admission cost?" />
        <DetailCard title="Event Name">
          <input
            disabled={isFreeEvent}
            className="placeHolder"
            style={{ border: "none", outline: "none", fontSize: "16px" }}
            type="number"
            value={isFreeEvent ? 0 : cost}
            placeholder="Enter price per attendee"
            onChange={setPrice}
          />
        </DetailCard>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="checkbox"
            className="checkbox"
            checked={isFreeEvent}
            style={{
              width: "16px",
              height: "16px",
              color: "black",
              opacity: "50%",
              marginRight: "10px",
            }}
            onChange={() => {
              // setisFreeEvent((pre) => !pre);

              setIsFreeEvent((pre) => !pre);
            }}
          />
          <label
            style={{
              fontSize: "14px",
              fontWeight: 400,
              color: "#000000",
              marginRight: "5px",
            }}
          >
            This is a free event.
          </label>
        </div>
        <div onClick={handleSubmit} style={{ width: "100%" }}>
          <NextButton props={"true"} />
        </div>
      </Stack>
    </Stack>
  );
};

export default PricePage;
