import react from "react";
import { Button, Stack } from "@mui/material";
import { useSelector } from "react-redux";

const NextButton = ({ props }) => {
  const eventType = useSelector((state) => state.EventDataReducer.eventType);

  return (
    <Stack direction="row" sx={{ marginY: "10px" }}>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#F56C6C",
          width: "100%",
          height: "38px",
          fontSize: "12px",
          fontWeight: 700,
          borderRadius: "7px",
          boxShadow: "0px 8px 20px 0px #4AD2E415",
          "&:hover": {
            backgroundColor: "#F56C6C",
            boxShadow: "none",
          },
        }}
      >
        {props
          ? eventType === "update"
            ? "UPDATE EVENT"
            : "CREATE EVENT"
          : "NEXT"}
      </Button>
    </Stack>
  );
};

export default NextButton;
