import { Avatar, Box, Stack, Typography } from "@mui/material";
import timeSince from "../../utils/timeSince";
import {
  getAllNotifications,
  returnJoinedNotifications,
  getAllNotificationDelete,
} from "../../firebase/functions/messaging/notifications";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import SwiperNotification from "./SwiperNotification";
import {useContext} from "react"
import { Context } from "../../context/StateContext";

export const notificationTypes = {
  reminder: "reminder",
  update: "update",
  new: "new",
  message: "message",
  join: "join",
  left: "left",
};

const renderNotificationTitle = (type) => {
  let title = "";
  let color = "";
  switch (type) {
    case notificationTypes.reminder:
      title = "Event Reminder";
      color = "#FFB72D";
      break;
    case notificationTypes.update:
      title = "Event Updates";
      color = "#2DC6FF";
      break;
    case notificationTypes.new:
      title = "Events Added Near You";
      color = "#8BCD37";
      break;
    case notificationTypes.message:
      title = "New Message";
      color = "#8237CD";

      break;
    case notificationTypes.join:
      title = "New Joining";
      color = "#37A8FD";
      break;
    case notificationTypes.left:
      title = "Left Activity";
      color = "#FF0000";
      break;
    default:
      title = "Notification";
      color = "black";
  }

  return (
    <Typography fontWeight="bold" variant="body1" color={color}>
      {title}
    </Typography>
  );
};
const renderPhoto = (type, photo) => {
  let title = "";
  let color = "";
  switch (type) {
    case notificationTypes.reminder:
      color = "#FFB72D";
      break;
    case notificationTypes.update:
      color = "#2DC6FF";
      break;
    case notificationTypes.new:
      color = "#8BCD37";
      break;
    case notificationTypes.message:
      color = "#8237CD";
      break;
    case notificationTypes.join:
      color = "#37A8FD";
      break;
    case notificationTypes.left:
      color = "#FF0000";
      break;
    default:
      color = "black";
  }

  return (
    <Avatar
      src={photo ?? ""}
      sx={{
        border: `2px solid ${color}`,
      }}
    />
  );
};
const NotificationsContainer = () => {
  // const [notifications, setNotifications] = useState([]);

  const {notifications, setNotifications} = useContext(Context)

  const navigate = useNavigate();
  

  // fetching notification logic & joined notification

  useEffect(() => {
    getAllNotifications().then(async () => {
      const notifyData = await returnJoinedNotifications();
      setNotifications(notifyData);


    });
  }, []);

  // deleted notification logic

  const handleDeleteNotification = (id) => {
    getAllNotificationDelete(id)
      .then(() => {
        const updatedNotifications = notifications.filter(
          (notification) => notification.id !== id
        );
        setNotifications(updatedNotifications);

      })
      .catch((e) => {
        console.log("error", e);
      });
    };
    
  
  return (
    <Stack gap={2}>
      {notifications.map(
        ({ type, time, title, link, id, description, photo }) => (
          <SwiperNotification
            key={id}
            deleteId={id}
            onDelete={handleDeleteNotification}
          >
            <Box
              style={{ cursor: link ? "pointer" : "default" }}
              key={id}
              sx={{ backgroundColor: "white" }}
              p={2}
              borderRadius="20px"
            >
              <Stack gap={2} flexDirection="row">
                {renderPhoto(type, photo)}
                <Stack
                  gap="4px"
                  onClick={() => {
                    if (link) {
                      const newA = document.createElement("a");
                      newA.href = link;
                      newA.target = "_blank";
                      newA.click();
                    }
                  }}
                >
                  {renderNotificationTitle(type)}
                  <Typography variant="body1">{title}</Typography>
                </Stack>
                <Typography
                  display={{ xs: "none", sm: "block" }}
                  ml="auto"
                  variant="body1"
                  sx={{ opacity: 0.5 }}
                >
                  {timeSince(time)}
                </Typography>
              </Stack>
              <Typography
                mt={2}
                display={{ xs: "block", sm: "none" }}
                textAlign="end"
                variant="body1"
                sx={{ opacity: 0.5 }}
              >
                {timeSince(time)}
              </Typography>
            </Box>
          </SwiperNotification>
        )
      )}
    </Stack>
  );
};

export default NotificationsContainer;
