import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase/config";
import { isRouteProtected, ROUTES } from "../routes";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../store/UserSlice";
import localStorage from "redux-persist/es/storage";
import { ReactComponent as Logo } from "../images/recessLogo.svg";

const UserAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [shouldFadeOut, setShouldFadeOut] = useState(false);
  const [user, setUser] = useState("loading");
  const location = useLocation();
  console.log({"Path data":location});
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.UserReducer.userInfo);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      if (user === "loading") {
      setShouldFadeOut(true);
        setTimeout(() => {
          setUser(currentuser);
        }, 1000);
      } else {
        setUser(currentuser);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const isProtectedRoute = isRouteProtected(location.pathname);
    console.log('ggggg', isProtectedRoute,  );
    if (isProtectedRoute === 'explore-guest') {
      console.log("protected");
      navigate(`${ROUTES.EXPLORE_GUEST}/${location.search}`);
    }
    else if (isProtectedRoute === 'create-event-guest') {
      console.log("protectedddd");
      navigate(`${ROUTES.CREATE_EVENT_GUEST}/${location.search}`);
    }
    else if (isProtectedRoute && user === 'loading') {
      console.log("Helllll");
      setShouldFadeOut(false);
      navigate(ROUTES.LOGIN);
    }
  
    else if (!isProtectedRoute) {
      console.log("2w3");
      if (location.search.includes("/event/")) {
        const eventRoute = location.search.slice(1)
        navigate(eventRoute);
      }
      else {
        setShouldFadeOut(true);
        setTimeout(() => {
          navigate(ROUTES.HOME);
        }, 1000);
      }
    }
  }, [user]);
  
  
  localStorage.setItem("UID", user?.uid);

  if (user === "loading") {
    return (
      <div className={`Logo-page ${shouldFadeOut ? "fade-out" : ""}`}>
        <Logo />
      </div>
    );
  }
  return (
    <UserAuthContext.Provider value={{ user }}>
      {children}
    </UserAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(UserAuthContext);
}
