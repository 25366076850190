import { Container, Stack, Typography } from "@mui/material";
import PageHeader from "../components/PageHeader";
const TermsOfServices = () => {
    return (
        <Container sx={{ my: 4 }}>
            <Stack gap={3}>
                <PageHeader title="Terms of Services" />
                <Typography fontSize="40px" variant="h2" fontWeight="bold">
                    Recess Privacy Introduction
                </Typography>
                <Typography color="#8E949B" variant="body1">
                    Effective: June 19, 2023
                </Typography>
                <Typography variant="body1" textTransform="uppercase">
                    Welcome to Recess! These Terms of Service govern your use of our
                    platform, which enables users to create and manage events, as well as
                    collect funds from attendees. By accessing or using our services, you
                    agree to comply with these terms. Please read them carefully.
                </Typography>
                <Typography variant="body1">
                    <h1 style={{ fontSize: "1.5rem" }}>
                        1. Account Creation and Responsibilities:
                    </h1>{" "}
                    {/* <br /> */}
                    <br />   a. You must be at least 18 years old or have the necessary
                    legal capacity to enter into a binding agreement.
                    <br />
                    <br />   b. You are responsible for maintaining the confidentiality of
                    your account information and any activities that occur under your
                    account.
                    <br />
                    <br />
                    c. You agree to provide accurate and up-to-date information during
                    the registration process and to keep it updated at all times.
                    <br />
                    <br />
                    <h1 style={{ fontSize: "1.5rem" }}>
                        2. Event Creation and Management:
                    </h1>
                    <br />    a. You may create, manage, and promote events through our
                    platform.
                    <br />
                    <br />    b. You are solely responsible for the accuracy, legality,
                    and appropriateness of the event details, including venue, date, time,
                    and other relevant information.
                    <br />
                    <br />
                    c. You agree not to use our platform for any illegal, fraudulent,
                    or harmful activities, including promoting hate speech,
                    discrimination, or violence.
                    <br />
                    <br />
                    <h1 style={{ fontSize: "1.5rem" }}>3. Financial Transactions:</h1>
                    <br />    a. Our platform facilitates the collection of funds from
                    event attendees. You acknowledge that we act as a third-party
                    intermediary in these transactions.
                    <br />
                    <br />    b. You agree to provide accurate and complete payment
                    information and authorize us to process the necessary transactions on
                    your behalf.
                    <br />
                    <br />    c. We are not responsible for any disputes, financial
                    losses, or liabilities arising from the collection or distribution of
                    funds between you and the attendees.
                    <br />
                    <br />
                    <h1 style={{ fontSize: "1.5rem" }}>4. Intellectual Property:</h1>
                    <br />    a. Our web application and its content, including text,
                    graphics, logos, and software, are protected by copyright and other
                    intellectual property laws.
                    <br />
                    <br />    b. You may not reproduce, distribute, modify, or create
                    derivative works based on our platform or its content without our
                    explicit permission.
                    <br />
                    <br />
                    <h1 style={{ fontSize: "1.5rem" }}>
                        5. Privacy and Data Protection:
                    </h1>
                    <br />   a. We respect your privacy and handle your personal
                    information in accordance with our Privacy Policy.
                    <br />
                    <br />    b. By using our platform, you consent to the collection,
                    use, and sharing of your personal data as outlined in our Privacy
                    Policy.
                    <br />
                    <br />
                    <h1 style={{ fontSize: "1.5rem" }}>6. User Conduct:</h1>
                    <br />   a. You agree not to engage in any activities that may disrupt
                    or harm the functioning of our platform or interfere with other users'
                    experiences.
                    <br />
                    <br />
                    b. You will not engage in unauthorized access, distribution of
                    malware, or any other malicious activities.
                    <br />
                    <br />
                    <h1 style={{ fontSize: "1.5rem" }}>7. Termination:</h1>
                    <br />    a. We reserve the right to suspend or terminate your
                    account, without prior notice, if you violate these Terms of Service
                    or engage in any prohibited activities.
                    <br />
                    <br />    b. You may terminate your account at any time by contacting
                    our support team.
                    <br />
                    <br />
                    <h1 style={{ fontSize: "1.5rem" }}>8. Limitation of Liability:</h1>
                    <br />    a. Our platform is provided on an "as is" and "as available"
                    basis. We do not guarantee the uninterrupted, secure, or error-free
                    operation of our services.
                    <br />
                    <br />    b. We are not liable for any direct, indirect, incidental,
                    consequential, or punitive damages arising from your use of our
                    platform or any interactions with other users.
                    <br />
                    <br />
                    <h1 style={{ fontSize: "1.5rem" }}>9. Modifications to the Terms:</h1>
                    <br />    a. We reserve the right to modify these Terms of Service at
                    any time. Changes will be effective upon posting the updated terms on
                    our platform.
                    <br />
                    <br />    b. Continued use of our services after any modifications
                    constitutes your acceptance of the revised terms.
                    <br />
                    <br />
                    <h1 style={{ fontSize: "1.5rem" }}>
                        10. Governing Law and Arizona:
                    </h1>
                    <br />     These Terms of Service are governed by and construed in
                    accordance with the laws of [Arizona]. Any disputes arising from
                    these terms shall be subject to the exclusive arizona of the
                    courts in [Arizona].
                    <br />
                    <br />
                    By using our web application, you acknowledge that you have read,
                    understood, and agreed to the practices described in this Privacy
                    Policy. If you do not agree with this policy, please refrain from
                    using our services.
                </Typography>
            </Stack>
        </Container>
    );
};
export default TermsOfServices;
