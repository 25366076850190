import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { ReactComponent as MapImage } from "../../images/map-icon.svg";
import "swiper/css";
import styles from "../../styles/Event.module.scss";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes";
import {
  DislikeEventById,
  IsEventLikedByUser,
  LikeEventById,
} from "../../firebase/functions/event/event-likes";
// import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { timeTo12HrFormat } from "../../utils/timeFunctions";
import { GetUsersById } from "../../firebase/functions/user";
import BookMarkAct from "../../icons/BookMarkAct";
import BookMark from "../../icons/BookMark";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const UpcomingPastEventCard = ({ event, customBg }) => {
  const navigate = useNavigate();
  const [userPhotos, setPhotos] = useState([]);

  try {
    event.events[0].date = event.date.toDate();
  } catch (error) {}
  
  const {
   id,
    photos,
    title,
    location,
    participant,
    date,
    startTime,
    eventJoined,
    description,
  } = event;

  // const [like, setLike] = useState({ id: null, isLike: false });

  // const handleLike = (id) => {
  //   if (like.isLike) {
  //     DislikeEventById(id);
  //     setLike({ id: id, isLike: false });
  //   } else {
  //     LikeEventById(id);
  //     setLike({ id: id, isLike: true });
  //   }
  // };

  useEffect(() => {
    // try {
    //   IsEventLikedByUser(id).then((res) => {
    //     setLike({ id: id, isLike: res });
    //   });
    // } catch (error) {
    //   console.log("error", error);
    // }

    event.joined?.forEach((user) => {
      if (userPhotos.length < 3) {
        GetUsersById(user).then((res) => {
          setPhotos((userPhotos) => [...userPhotos, res]);
        });
      }
    });
  }, []);
  

  return (
    <>
      <p
        style={{
          fontSize: "Poppins",
          fontSize: "1.1rem",
          paddingLeft: "16px",
          fontWeight: "600",
          paddingTop: "10px",
          // marginBottom: "10px",
        }}
      >
        {event.date}
      </p>
      {event?.events.map((val, index) => (
        <div
          style={{ marginTop: "15px", cursor: "pointer" }}
          key={index}
          onClick={() => {
            navigate(`/event/${val.id}`);
          }}
        >
            <Box
              sx={{
                backgroundColor: "white",
                boxShadow: "0px 8px 27px -6px #d7d7d7",
                p: { xs: 1.5, md: 2 },
                borderRadius: "20px",
              }}
            >
              <Stack
                direction="row"
                justifyContent={{ xs: "inherit", lg: "inherit" }}
                gap={4}
              >
                <Box>
                  <img
                    style={{ height: "100px" }}
                    className={styles.eventImage}
                    src={val.photos}
                    alt=""
                  />
                </Box>
                <Box marginTop={"15px"}>
                  <Stack
                    direction="row"
                    sx={{ mb: { xs: 1, md: 2 } }}
                    gap={{ xs: 0.5, md: 1 }}
                    style={{
                      marginTop: "4px",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: { xs: 12, md: 16 } }}
                      variant="h6"
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#27C9C9",
                        // opacity: "0.5"
                      }}
                    >
                      {val && val.date && (val.date
                        .toDate()
                        .toLocaleDateString("en-US", {
                          weekday: "short",
                          day: "numeric",
                          month: "long",
                        })
                        .toUpperCase())}
                      {/* {event.date} */}
                    </Typography>

                      <Typography
                        sx={{ fontSize: { xs: 12, md: 16 } }}
                        variant="h6"
                        style={{
                          display: "flex",
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#27C9C9",
                          // opacity: "0.5"
                        }}
                      >
                        <div style={{ alignItems: "center" }}>
                          <FiberManualRecordIcon
                            style={{
                              width: "0.6rem",
                              height: "0.5rem",
                              paddingRight: "4px",
                              //
                            }}
                            fontSize="small"
                          />
                        </div>{" "}
                        {val && val.startTime && (timeTo12HrFormat(val?.startTime))}
                      </Typography>
                    </Stack>
                    <Typography
                      sx={{
                        fontSize: { xs: 15, md: 20 },
                        fontWeight: "400",
                        color: "#303030",
                      }}
                      variant="h5"
                      gutterBottom
                    >
                      {val.title}
                    </Typography>
                    <Box sx={{ display: "flex", mt: "5px" }}>
                      <MapImage />
                      {val.displayAddress && (
                        <Typography
                          sx={{
                            fontSize: { xs: 13, md: 15 },
                            fontWeight: "400",
                            color: "#666666",
                            ml: "5px",
                          }}
                          variant="body1"
                        >
                          {val.displayAddress}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  {/* on desktop screen, join and like */}
                </Stack>
              </Box>
          </div>
        ))}
    </>
  );
};

export default UpcomingPastEventCard;
