import React, { useEffect, useState } from "react";
import axios from "axios";
import { useCreateEventContext } from "../pages/CreateEvent";

const LocationPointsHook = () => {
  const { displayAddress } = useCreateEventContext();
  const googleApi = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const [location, setLocation] = useState({ lat: 0, lng: 0 });

  const getAdrress = async (address) => {
    if (address) {
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${googleApi}`;

      return axios
        .get(apiUrl)
        .then((response) => {
          const { results } = response.data;
          if (results && results.length > 0) {
            const { lat, lng } = results[0].geometry.location;
            setLocation({ lat, lng });
            return { lat, lng };
          }
        })
        .catch((error) => {
          console.error("Error fetching location data", error);
        });
    }
  };
  return { getAdrress, location };
};

export default LocationPointsHook;
