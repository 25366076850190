import react, { useState, useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import NextButton from "./NextButton";
import AddKeywordModal from "../../modals/AddKeywordModal";
import { AddKeywordInUser } from "../../firebase/functions/user";
import { ReactComponent as RemoveTagSvg } from "../../images/Close.svg";
import { useDispatch, useSelector } from "react-redux";
import { removeTags, setTags, setTagsFilter } from "../../store/ModelSlice";
import { setCheckboxes } from "../../store/EventSlice";
import { useCreateEventContext } from "../../pages/CreateEvent";

const Text = ({ title }) => {
  return (
    <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
      <span style={{ color: "#F56C6C", marginRight: "5px" }}>*</span>
      {title}
    </Typography>
  );
};

const CategoryPage = ({
  setPages,
  pages,
  setState,
  checkedValues,
  setCheckedValues,
}) => {
  const dispatch = useDispatch();
  const tags = useSelector((state) => state.ModelReducer.tags);
  const [openKeyModel, setOpenKeyModel] = useState(false);
  const [keyword, setKeyword] = useState("");
  const checkbox = useSelector((state) => state.EventDataReducer.checkbox);
  const updateEventType = useSelector(
    (state) => state.EventDataReducer.eventType
  );
  const { eventType, keywords, category } = useCreateEventContext();

  const nextPage = () => {
    setPages(pages + 1);
  };

  const AddKeyword = (e) => {
    e.stopPropagation();
    if (keyword !== "") {
      setOpenKeyModel(false);
      AddKeywordInUser(keyword);
      dispatch(setTagsFilter(keyword));
      setState((prev) => ({
        ...prev,
        keywords: [...tags],
      }));
    }
  };

  useEffect(() => {
    if (updateEventType === "update") {
      let updatedCheckbox = [
        { title: "Sports & Games", isChecked: false },
        { title: "Adventure & Hiking", isChecked: false },
        { title: "Community Event", isChecked: false },
        { title: "Chill Hang", isChecked: false },
        { title: "Art & Music", isChecked: false },
      ];

      category.forEach((item) => {
        updatedCheckbox.forEach((obj, i) => {
          if (obj.title === item) {
            updatedCheckbox[i].isChecked = true;
          }
        });
      });
      dispatch(setCheckboxes(updatedCheckbox));
      if (tags.length === 0 && keywords.length !== 0) {
        dispatch(setTags(keywords));
      }
    }
  }, [updateEventType]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      keywords: [...tags],
    }));
  }, [tags]);

  useEffect(() => {
    if (checkedValues !== "") {
      setState((prev) => ({
        ...prev,
        category: [...checkedValues],
      }));
    }
  }, [checkbox]);

  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = [...checkbox];
    updatedCheckboxes[index] = {
      ...updatedCheckboxes[index],
      isChecked: !updatedCheckboxes[index].isChecked,
    };
    dispatch(setCheckboxes(updatedCheckboxes));

    const updatedCheckedValues = updatedCheckboxes
      .filter((checkbox) => checkbox.isChecked)
      .map((checkbox) => checkbox.title);
    setCheckedValues(updatedCheckedValues);
    setState((prev) => ({
      ...prev,
      category: [...checkedValues],
    }));
  };

  const CheckBox = ({ index, title, isChecked, handleCheckboxChange }) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          key={index}
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          className="checkbox"
          style={{
            width: "16px",
            height: "16px",
            marginRight: "10px",
            borderRadius: "2px",
          }}
        />
        <label
          style={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#000000",
            marginRight: "5px",
          }}
        >
          {" "}
          {title}
        </label>
      </div>
    );
  };

  const handleRemoveTag = (e, index) => {
    e.stopPropagation();
    dispatch(removeTags(index));
  };

  return (
    <Stack gap={2} width="100%">
      <Stack gap={2}>
        <Text title="What type of event is this?" />
        <Stack width="100%">
          <ButtonGroup
            aria-label="outlined primary button group"
            sx={{ width: "100%" }}
          >
            <Button
              name="Outdoor"
              sx={{
                width: "50%",
                border: "1px solid #27C9C9",
                borderRadius: "8px 0px 0px 8px ",
                fontSize: "14px",
                fontWeight: 400,
                boxShadow: "none",
                color: eventType === "Outdoor" ? "white" : "black",
                backgroundColor:
                  eventType === "Outdoor" ? "#27C9C9" : "inherit",
                "&:hover": {
                  backgroundColor: "#27C9C9",
                  borderColor: "#27C9C9",
                  boxShadow: "none",
                },
              }}
              onClick={(event) => {
                setState((prev) => ({
                  ...prev,
                  eventType: event.target.name,
                }));
              }}
            >
              Outdoor
            </Button>
            <Button
              name="Indoor"
              sx={{
                width: "50%",
                border: "1px solid #27C9C9",
                fontSize: "14px",
                fontWeight: 400,
                boxShadow: "none",
                borderRadius: "0px 8px 8px 0px ",
                backgroundColor: eventType === "Indoor" ? "#27C9C9" : "inherit",
                color: eventType === "Indoor" ? "white" : "black",
                "&:hover": {
                  backgroundColor: "#27C9C9",
                  borderColor: "#27C9C9",
                  boxShadow: "none",
                },
              }}
              onClick={(event) => {
                setState((prev) => ({
                  ...prev,
                  eventType: event.target.name,
                }));
              }}
            >
              Indoor
            </Button>
          </ButtonGroup>
        </Stack>
        <p style={{ fontSize: "14px", fontWeight: 400 }}>
          Select at least{" "}
          <span style={{ fontSize: "14px", fontWeight: 600 }}>one</span> (1)
          category below that most closely relates to your event.
        </p>
        <Stack gap={2}>
          {checkbox.map((checkbox, index) => (
            <CheckBox
              key={index}
              title={checkbox.title}
              isChecked={checkbox.isChecked}
              handleCheckboxChange={() => handleCheckboxChange(index)}
            />
          ))}
        </Stack>
        <p style={{ fontSize: "14px", fontWeight: 400, marginTop: "10px" }}>
          Any other tags you’d like to use to describe your event? You can add
          or make changes to these later.
        </p>

        <div className="tag-div" onClick={() => setOpenKeyModel(true)}>
          {tags &&
            Array.isArray(tags) &&
            tags.map((tag, index) => (
              <div className="tag-box" key={index}>
                <p style={{ marginRight: "10px" }}>{tag}</p>
                <RemoveTagSvg onClick={(e) => handleRemoveTag(e, index)} />
              </div>
            ))}
        </div>

        <div onClick={nextPage} style={{ width: "100%" }}>
          <NextButton />
        </div>
      </Stack>
      <AddKeywordModal
        open={openKeyModel}
        onClose={(e) => AddKeyword(e)}
        keyword={keyword}
        setKeyword={setKeyword}
      />
    </Stack>
  );
};

export default CategoryPage;
