import { Box, Container, Stack, Typography } from "@mui/material";

import { ReactComponent as RedFilter } from "../../../images/red-filter.svg";
import { useModalsContext } from "../../../modals/ModalsContext";
import { MODALS } from "../../../modals/modals";
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../../context/StateContext";
import { GetExploreEventsFromUserLocation } from "../../../firebase/functions/event/sort-event";

const ExploreHeader = ({ filters, locationParam }) => {
  const { exploreEvents, setExploreEvents } = useContext(Context);

  const [search, setSearch] = useState("");

  const tags = useSelector((state) => state.ModelReducer.tags);

  const positionPoints = useSelector(
    (state) => state.LocationReducer.positionPoints
  );

  const { setOpenModal } = useModalsContext();
  const appliedFilters = useSelector(
    (state) => state.ModelReducer.appliedFilters
  );
  const filterObj = useSelector((state) => state.ModelReducer.filterObj);

  // const [filter, setFilter] = useState(0)
  // useEffect(()=>{
  // },[appliedFilters])

  //   const handleSearchChange = (e) => {
  //  const searchTerm = e.target.value.toLowerCase();

  //   const filteredEvents = exploreEvents.filter((event) =>
  //     event.title.toLowerCase().includes(searchTerm)
  //   );

  //   setSearch(searchTerm);
  //   setExploreEvents(filteredEvents);
  // };

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();

  // Filter the eventData based on the search term
  const filteredEvents = exploreEvents?.filter((event) =>
    event.title.toLowerCase().includes(searchTerm)
  );

  setSearch(searchTerm);

  setExploreEvents(filteredEvents);
  
  if(e.target.value==='') {
 
     GetExploreEventsFromUserLocation(
      20,
      filterObj,
      positionPoints.lat,
      positionPoints.lng,
      tags
    ).then((data) => {
      setExploreEvents(data);
    });

  }
  
};

  return (
    <Container>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: { xs: 262, md: 450 },
            height: { xs: 51, md: 60 },
            border: "1px solid #EEEEEE",
            borderRadius: "12px",
            boxShadow: "0px 40px 60px 0px #D3D1D880",
          }}
        >
          <IconButton sx={{ p: "10px" }} aria-label="menu">
            <ArrowBackIosIcon
              sx={{ color: "black", height: "0.7em", width: "1em" }}
            />
          </IconButton>
          <InputBase
            sx={{ ml: 0.2, flex: 1 }}
            placeholder="What are you feeling up for?"
            inputProps={{ "DM Sans": "What are you feeling up for" }}
            value={search}
            onChange={handleSearchChange}
          />
        </Paper>

        {!locationParam && (
          <Box>
            <Box
              onClick={() => setOpenModal(MODALS.SORT_FILTER)}
              sx={{
                bgcolor: "#FFFFFF",
                color: "#28303F",
                fontSize: { xs: 24, md: 30 },
                border: "1px solid #EEEEEE",
                borderRadius: "15px",
                width: { xs: 51, md: 60 },
                height: { xs: 51, md: 60 },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",
                boxShadow: "0px 40px 60px 0px #D3D1D880",
              }}
              variant="contained"
            >
              <RedFilter />
            </Box>
            {appliedFilters > 0 ? (
              <Box
                onClick={() => setOpenModal(MODALS.SORT_FILTER)}
                sx={{
                  position: "relative",
                  bottom: { xs: "41px", sm: "41px", md: "56px" },
                  left: { xs: "23px", sm: "23px", md: "33px" },
                  bgcolor: "#2DC6FF",
                  color: "white",
                  fontSize: { xs: 10, md: 12 },
                  borderRadius: "50%",
                  width: { xs: 18, md: 24 },
                  height: { xs: 18, md: 24 },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  cursor: "pointer",
                  boxShadow: "none",
                }}
                variant="contained"
              >
                {appliedFilters}
              </Box>
            ) : (
              ""
            )}
          </Box>
        )}
      </Stack>
    </Container>
  );
};

export default ExploreHeader;
