import React from "react";
import styled from "@emotion/styled";

const Tags = ({ selectedOption }) => {
  return (
    <StyledStack>
      <StyledTagContainer>
        {selectedOption &&
          selectedOption.map((tag, i) => (
            <StyledTag backgroundColor={tag.color} key={i}>
              {tag.label}
            </StyledTag>
          ))}
      </StyledTagContainer>
    </StyledStack>
  );
};

export default Tags;

const StyledTagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledTag = styled.div`
  display: flex;
  height: 31px;
  width: fit-content;
  background-color: ${(props) => props.backgroundColor};
  margin: 4px;
  padding: 7px 15px;
  border-radius: 16px;
  color: white;
`;

const StyledStack = styled.div`
  width: 100%;
  display: flex;
  height: fit-content;
`;
