import { IconButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import useSignIn from "../hooks/useSignIn";
import { setLoaded } from "../store/LocationSlice";

const Line = () => (
  <svg
    width="64"
    height="1"
    viewBox="0 0 64 1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      y1="0.85"
      x2="64"
      y2="0.85"
      stroke="#212121"
      stroke-opacity="0.5"
      stroke-width="0.3"
    />
  </svg>
);

const FaceBookIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="9.99998" fill="#1977F3" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.7683 19.8437C11.1944 19.9461 10.6036 19.9995 10.0002 19.9995C9.46544 19.9995 8.94045 19.9575 8.42838 19.8767V13.1852H5.7146V10.1059H8.42838V7.75892C8.42838 5.08972 10.0231 3.61487 12.4652 3.61487C13.2666 3.62603 14.0662 3.6956 14.8575 3.82303V6.44474H13.509C12.1815 6.44474 11.7683 7.26583 11.7683 8.10911V10.1064H14.7323L14.2585 13.1852H11.7683V19.8437Z"
      fill="#F2F2F2"
    />
  </svg>
);

const GoogleIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0002 3.86624C11.433 3.8442 12.8187 4.3779 13.8665 5.35534L16.6886 2.59633C14.878 0.900101 12.4812 -0.0300145 10.0002 0.00073902C8.14637 0.000310762 6.32901 0.515469 4.75131 1.4886C3.17361 2.46174 1.89778 3.85448 1.06651 5.51108L4.30016 8.02111C4.69555 6.81912 5.45837 5.77154 6.48102 5.0261C7.50368 4.28066 8.73459 3.87497 10.0002 3.86624Z"
      fill="#E43E2B"
    />
    <path
      d="M19.6 10.2207C19.6118 9.53338 19.5408 8.84719 19.3885 8.17688H10V11.8876H15.5115C15.407 12.5382 15.1719 13.1609 14.8204 13.7183C14.469 14.2757 14.0084 14.7563 13.4663 15.1311L16.6221 17.5748C17.6056 16.6255 18.3791 15.4805 18.8925 14.2138C19.4059 12.947 19.6479 11.5867 19.6029 10.2207H19.6Z"
      fill="#3B7DED"
    />
    <path
      d="M4.31187 11.9756C4.09323 11.3391 3.98051 10.6711 3.97821 9.9981C3.9822 9.32623 4.09088 8.65907 4.30033 8.02065L1.06668 5.51062C0.365323 6.90276 0 8.43983 0 9.99858C0 11.5573 0.365323 13.0944 1.06668 14.4865L4.31187 11.9756Z"
      fill="#F0B501"
    />
    <path
      d="M10 19.9963C12.4358 20.0652 14.8056 19.1986 16.6221 17.5747L13.4663 15.131C12.4431 15.8169 11.2314 16.1664 10 16.1308C8.73551 16.1233 7.50549 15.7179 6.48443 14.9721C5.46337 14.2263 4.70313 13.178 4.31153 11.976L1.07788 14.4869C1.90692 16.1428 3.18066 17.5352 4.75645 18.5083C6.33225 19.4813 8.14787 19.9966 10 19.9963Z"
      fill="#2BA24C"
    />
  </svg>
);

const AppleIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1050_1805)">
      <path
        d="M15.6121 19.1976C14.5234 20.253 13.3346 20.0864 12.1903 19.5864C10.9794 19.0754 9.8684 19.0531 8.59078 19.5864C6.99098 20.2752 6.14665 20.0752 5.19121 19.1976C-0.230326 13.6094 0.569573 5.09936 6.72435 4.78828C8.22416 4.86605 9.26847 5.6104 10.1461 5.67706C11.4571 5.41043 12.7125 4.64386 14.1123 4.74385C15.7899 4.87716 17.0564 5.54374 17.8896 6.74359C14.4234 8.82111 15.2455 13.3872 18.4229 14.6648C17.7896 16.3313 16.9675 17.9866 15.601 19.2087L15.6121 19.1976ZM10.035 4.72163C9.8684 2.24416 11.8793 0.199975 14.1901 0C14.5123 2.8663 11.5904 4.99937 10.035 4.72163Z"
        fill="#303030"
      />
    </g>
    <defs>
      <clipPath id="clip0_1050_1805">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ContinueWithSocialMedia = ({sx}) => {
    const {handleGoogleSignIn,handleFacebookSignIn,handleAppleSignIn} = useSignIn();
    const dispatch = useDispatch()
    const handleClick = (e)=>{
        if(e==="facebook"){
           handleFacebookSignIn().then(()=>{
            dispatch(setLoaded(true))
           })
           
        }else if(e==="apple"){
            handleAppleSignIn().then(()=>{
                dispatch(setLoaded(true))
               })
        }else if(e==="google"){
            handleGoogleSignIn().then(()=>{
                dispatch(setLoaded(true))
               })
        }
    }

  return (
    <Stack sx={{ pt: 6, pb: 5 }} spacing={2}>
      <Stack
        justifyContent="center"
        alignItems="center"
        direction="row"
        spacing={3}
      >
        <Line />
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            color: "#666666",
            lineHeight: "16px",
          }}
          variant="subtitle2"
        >
          OR SIGN IN WITH
        </Typography>
        <Line />
      </Stack>
      <Stack
        justifyContent="space-evenly"
        alignItems="center"
        direction="row"
        spacing={2}
      >
        <IconButton
          className="social-icon-box"
          onClick={() => handleClick("google")}
        >
          <GoogleIcon />
        </IconButton>
        <IconButton
          className="social-icon-box"
          onClick={() => handleClick("facebook")}
        >
          <FaceBookIcon />
        </IconButton>

        <IconButton
          className="social-icon-box"
          onClick={() => handleClick("apple")}
        >
          <AppleIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default ContinueWithSocialMedia;
