import { Box, Button, Stack, Typography } from "@mui/material";
import { BsCalendarEvent } from "react-icons/bs";
import styles from "../../../styles/Home.module.scss";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";
import { Timestamp } from "firebase/firestore";
import { timeTo12HrFormat } from "../../../utils/timeFunctions";
import { getContrastRatio } from "../../../utils/colors";
import { useEffect, useState } from "react";
import { GetUsersById } from "../../../firebase/functions/user";
import eventStyles from "../../../styles/Event.module.scss";
import { ReactComponent as MapImage } from "../../../images/map-icon.svg";

import {
  DislikeEventById,
  IsEventLikedByUser,
  LikeEventById,
} from "../../../firebase/functions/event/event-likes";

import BookMark from "../../../icons/BookMark";
import BookMarkAct from "../../../icons/BookMarkAct";
import { useSelector } from "react-redux";

const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const UpcomingCard = ({ event }) => {

  const [userPhotos, setPhotos] = useState([]);

  const {uid} = useSelector((state) => state.UserReducer.userInfo.uid);

  try {
    event.date = event.date.toDate();
  } catch (error) {}

  const { id, title, startTime, endTime, date, photos, bgColor } = event;

  const navigate = useNavigate();


  const [like, setLike] = useState({ id: null, isLike: false });

useEffect(() => {
    try {
        IsEventLikedByUser(id).then((res) => {
            setLike({ id: id, isLike: res });
        });
    } catch (error) {
        console.log("error", error);
    }
}, [id]);

const handleLike = (id, e) => {
    e.stopPropagation();
    if (like.isLike) {
        DislikeEventById(id).then(() => {
            setLike({ id: id, isLike: false });
        });
    } else {
        LikeEventById(id).then(() => {
            setLike({ id: id, isLike: true });
        });
    }
};

  /* check if bgColor is bright */
  const fontColor =
    getContrastRatio(bgColor, "ffffff") > 0.7 ? "#000000" : "#ffffff";


  return (
    <Box
      className={styles.eventCard}
      sx={{
        bgcolor: "#FFFFFF",
        boxShadow: "0px 8px 30px 0px #5055880F",
        color: `black`,
        p: { xs: "10px", md: 1.5 },
        borderRadius: "25px !important",
        minHeight: "270px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      onClick={function (e) {
        e.preventDefault();
        navigate(ROUTES.EVENT_DETAILS.replace(":id", event.id));
  
}}
    >
      <div className={styles.eventThumb} id={"imgUpcoming" + id}>
        <img
          className={styles.eventThumb}
          style={{ height: "100%", width: "100%", objectFit: "cover" }}
          id={"imgUpcoming" + id}
          src={photos}
          alt=""
        />
        <div className="date-card">
          <span>
            {date.toLocaleDateString("en-US", {
              day: "numeric",
            })}
          </span>

          <span style={{ fontSize: "9px", fontWeight: 400 }}>
            {date.toLocaleDateString("en-US", {
              month: "long",
            })}
          </span>
        </div>
        <div className="bookmark" onClick={(e) => handleLike(id, e)}>

           {like.isLike && like.id === id ? (

             <BookMark height={14} width={14} className={styles.heartIcon} />
             
             ) : (
               <BookMarkAct height={14} width={14} className={styles.heartIcon} />
               

          )} 
        </div>
      </div>
      <Box sx={{ my: { xs: 1, md: 2 }, px: 1 }}>
        <Typography sx={{ color: "black" }} variant="h4">
          {title}
        </Typography>
        <Stack
          direction="row"
          sx={{ my: { xs: 1, md: 2 } }}
          gap={{ xs: 0.5, md: 1 }}
        >
          <Typography sx={{ color: "black", opacity: "50%" }} variant="h6">
            {timeTo12HrFormat(startTime)}
          </Typography>
          <Typography sx={{ color: "black", opacity: "50%" }} variant="h6">
            to {timeTo12HrFormat(endTime)}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          {/* <Box
                  sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: {xs: 0.5, md: 1},
                  }}
                  className={styles.eventJoined}
              >
                  <Box>
                                   {eventJoined.map((user) => (
          <img key={user.id} src={user.image} alt="" />
        ))}
                  </Box>
                  <Typography color={fontColor} variant="h6">
                      {event.attendees} joined
                  </Typography>
              </Box> */}
          {userPhotos && userPhotos?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                //   marginTop: "5px",
                alignItems: "center",
                gap: { xs: 0.5, md: 1 },
                position: "relative",
                right: "-6px",
              }}
              className={eventStyles.eventJoined}
            >
              <Box>
                {userPhotos.map((user, index) => {
                  return (
                    <img
                      style={{
                        width: "30px",
                        aspectRatio: "1/1",
                      }}
                      key={index}
                      src={
                        user?.photoURL
                          ? user.photoURL
                          : "https://cdn1.iconfinder.com/data/icons/messenger-and-society/16/user_person_avatar_unisex-512.png"
                      }
                      alt=""
                    />
                  );
                })}
              </Box>
              <Typography variant="h6" className="text-poolside">
                +{event.attendees} Going
              </Typography>
            </Box>
          )}
        </Stack>
        {/* <Typography sx={{color:"black", opacity:"50%", }} variant="h6">
              {timeTo12HrFormat(startTime)}
            </Typography> */}
        <Box sx={{ display: "flex", mt: "5px" }}>
          <MapImage />
          {event.displayAddress && (
            <Typography
              sx={{
                fontSize: { xs: 13, md: 15 },
                fontWeight: "400",
                color: "#666666",
                ml: "5px",
              }}
              variant="body1"
            >
              {event.displayAddress}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default UpcomingCard;
