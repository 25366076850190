import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import React from "react";
import { Box } from "@mui/material";
import { updatePaymentStatusEvent } from "../../firebase/functions/event";

function CheckoutForm(props) {
  const eventId = props.eventId;
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const amount = "$10";

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }
  
    setIsProcessing(true);
    let paymentSuccessful = false;
  
    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/event-sucessful/${eventId}/?paid`,
        },
      });
  
      if (error) {
        // Handle Stripe errors here
        setMessage(error.message || "An error occurred while processing your payment.");
        console.error('Stripe error:', error);
      } else {
        // If there's no error, the payment was successful
        paymentSuccessful = true;
      }
    } catch (error) {
      // Handle any other errors
      setMessage("An error occurred while processing your payment.");
      console.error('Unhandled payment error:', error);
    }
  
    if (paymentSuccessful) {
      try {
        await updatePaymentStatusEvent(eventId, 'paid');
        // You can redirect to the success page or update the UI accordingly here
      } catch (updateError) {
        // Handle errors from updating the Firestore document
        setMessage("Payment was successful, but an error occurred while updating the event status.");
        console.error('Error updating the event status:', updateError);
      }
    }
  
    setIsProcessing(false);
  };
  
  

  return (
    <Box component="section" sx={{ p: 5 }}>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        <button
          disabled={isProcessing || !stripe || !elements}
          id="submit"
          style={{
            backgroundColor: "#E97451",
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "16px",
            margin: "14px 2px",
            cursor: "pointer",
          }}
        >
          <span id="button-text">
            {isProcessing ? "Processing ... " : `Pay  ${amount}`}
          </span>
        </button>

        {message && <div id="payment-message">{message}</div>}
      </form>
    </Box>
  );
}
export default CheckoutForm;
