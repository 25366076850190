import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Container,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import golf from "../images/golf.png";
import DetailsNavigation from "../components/EventDetails/Details/DetailsNavigation";
import FeaturesCard from "../components/EventDetails/Details/FeaturesCard";
import LocationCard from "../components/EventDetails/Details/LocationCard";
import AddressCard from "../components/EventDetails/Details/AddressCard";
import Registered from "../components/EventDetails/Registered/Registered";
import MapView from "../components/Explore/MapView/MapView";
import marker3 from "../images/marker-3.png";
import MessageBoard from "../components/MessageBoard/MessageBoard";
import EventConfirmationModal from "../modals/EventConfirmationModal";
import { useModalsContext } from "../modals/ModalsContext";
import { MODALS } from "../modals/modals";
import FlagEventModal from "../modals/FlagEventModal";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PinDropIcon from "@mui/icons-material/PinDrop";
import solarChat from "../icons/solarChat.png";
import dateLogo from "../icons/date.png";
import locationLogo from "../icons/location.png";
import people from "../icons/people.png";
import InfoIcon from "../icons/InfoIcon";
import {
  checkIfEventIsCanceled,
  checkIfEventIsFlaggedByCurrentUser,
  ViewEventById,
} from "../firebase/functions/event";
import { useEffect, useState } from "react";
import {
  getCurrentUser,
  GetUsersById,
  GetUsersByIds,
} from "../firebase/functions/user";
import { HasUserJoinedEvent } from "../firebase/functions/event/event-join";
import { timeTo12HrFormat } from "../utils/timeFunctions";
import { toast } from "react-toastify";
import LeftModal from "../modals/LeftModal";
import { batch, useDispatch } from "react-redux";
import { Grid } from "@mui/material";

import {
  setEventType,
  setUpdateEventData,
  setUpdateEventID,
} from "../store/EventSlice";
import { ROUTES } from "../routes/index";
import CancelEventModal from "../modals/CancelModal";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import MapIcon from "../icons/MapIcon";
import LocationIcon from "../icons/LocationIcon";
import CustomDivider from "../components/CustomDivider";
import InfoIcon2 from "../icons/InfoIcon2";
import { setProfileInfo } from "../store/ProfileSlice";
// import { Grid } from "swiper";
const _event = {
  CreatedBy: "",
  id: 1,
  title: "Loading...",
  location: "Loading...",
  keywords: "Loading...",
  coordinates: { lat: 32.158915943, lng: 536.1564159 },
  address: { lat: 10.0, lng: 10.0 },
  maxParticipants: "Loading...",
  date: new Date(),
  startTime: "Loading...",
  endTime: "Loading...",
  cost: "Loading...",
  photos: golf,
  description: "Loading...",
};

const EventDetails = ({ event = _event }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tags = useSelector((state) => state.ModelReducer.tags);
  const userInfo = useSelector((state) => state.UserReducer.userInfo);

  const { id } = useParams();
  const [marker, setMarker] = useState(event);
  const [title, setTitle] = useState(event.title);
  const [location, setLocation] = useState(event.location);
  const [maxParticipants, setmaxParticipants] = useState(event.maxParticipants);
  const [date, setDate] = useState(event.date);
  const [startTime, setStartTime] = useState(event.startTime);
  const [endTime, setEndTime] = useState(event.endTime);
  const [photos, setPhotos] = useState(event.photos);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [keywords, setKeywords] = useState(event.keywords);
  const [cost, setCost] = useState(event.cost);
  const [description, setDescription] = useState(event.description);
  const [address, setAddress] = useState(event.address);
  const [users, setUsers] = useState([]);
  const [IsUserJoined, setIsUserJoined] = useState(false);
  const [displayAddress, setDisplayAddress] = useState(
    event.displayAddress ? event.displayAddress : ""
  );
  const [creator, setCreator] = useState(event.CreatedBy);
  const [eventFlagged, setEventFlagged] = useState(false);
  const [eventCanceled, setEventCanceled] = useState(false);
  const [showLoading, setShowLoading] = useState(true);

  const [buttonMenu, setButtonMenu] = useState(false);

  const maxAvatars = 3;

  const avatars = users.slice(0, maxAvatars);

  const remainingUsers = users.length - avatars.length;

  let timer1 = setTimeout(() => setShowLoading(true), 2000);

  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.scrollTo(0, 0);
  useEffect(() => {
    checkIfEventIsFlaggedByCurrentUser(id).then((r) => {
      setEventFlagged(r);
    });

    checkIfEventIsCanceled(id).then((r) => {
      setEventCanceled(r);
    });

    let validateData = null;
    // while(validateData===null){
    ViewEventById(id).then((data) => {
      console.log({'get events data':data});
      // if (!data) {
      //   return;
      // }
      setMarker(data);
      validateData = data;
      if (!data) return;
      setTitle(data?.title);
      setmaxParticipants(data?.maxParticipants);
      setStartTime(timeTo12HrFormat(data?.startTime));
      setEndTime(timeTo12HrFormat(data?.endTime));
      setDate(data?.date.toDate());
      setPhotos(data?.photos);
      setKeywords(data?.keywords);
      setCost(event?.cost === 0 ? "Free" : data.cost);
      setDescription(data?.description);
      setAddress(data?.address);
      setCreator(data?.CreatedBy);
      setLocation(
        data?.address.city +
          ", " +
          data?.address.state +
          ", " +
          data?.address.country_code?.toUpperCase()
      );
      setDisplayAddress(
        data?.address.displayAddress
          ? data?.address.displayAddress
          : data?.displayAddress
      );
      /* change event.date */
      GetUsersByIds(data?.joined).then((usersData) => {
        const crrData = usersData?.filter((user) => user !== null);
        setUsers(usersData);
      });
    }).catch((err) =>{
      console.log('get events error' , err)
    });
    HasUserJoinedEvent(id).then((data) => {
      setIsUserJoined(data);
    });
    // }
  }, [IsUserJoined, startTime, title, event, id, showLoading]);

  const ReverseSwiper = () => {
    setCurrentPhotoIndex(
      (prevIndex) => (prevIndex - 1 + photos.length) % photos.length
    );
  };

  const ForwardSwiper = () => {
    setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % photos.length);
  };

  const uId = useSelector((state) => state?.UserReducer?.userInfo?.uid);

  const getLocation =useLocation();
  console.log({getLocation:getLocation.pathname});

  const clickHandler = () => {
    if(!uId){

      navigate(`${ROUTES.LOGIN}/?${getLocation.pathname}`);
    }
    else{
      if (date < new Date()) {
        toast("Event has already passed", { type: "error" });
      } else if (isCreator()) {
        setOpenModal(MODALS.CANCEL_EVENT);
        dispatch(setUpdateEventData(marker));
      } else {
        if (IsUserJoined) {
          setOpenModal(MODALS.LEFT_EVENT);
          dispatch(setUpdateEventData(marker));
        } else {
          setOpenModal(MODALS.EVENT_CONFIRMATION);
        }
      }
    }
   
  };

  const updateHandler = () => {
    batch(() => {
      dispatch(setUpdateEventData(marker));
      dispatch(setEventType("update"));
      dispatch(setUpdateEventID(id));
    });
    navigate(ROUTES.CREATE_EVENT);
  };

  const setIsJoined = (value) => {
    setIsUserJoined(value);
  };

  const isCreator = () => {
    return creator === getCurrentUser();
  };

  const handleMenuOpen = () => {
    setButtonMenu(!buttonMenu);
  };

  const handleMenuClose = () => {
    setButtonMenu(false);
  };

  const { openModal, setOpenModal } = useModalsContext();

  const fetchProfile = async (id) => {
    if (id === userInfo.uid) {
      navigate(ROUTES.PROFILE);
      return;
    }
    GetUsersById(id).then((data) => {
      dispatch(setProfileInfo(data));
      navigate(ROUTES.PROFILE);
    });
  };

  return (
    <>
      <EventConfirmationModal
        setIsJoined={setIsJoined}
        open={openModal === MODALS.EVENT_CONFIRMATION}
        id={id}
        title={title}
        maxParticipants={maxParticipants}
        date={date}
        startTime={startTime}
        endTime={endTime}
        photos={photos}
        keywords={keywords}
        cost={cost}
        displayAddress={displayAddress}
        address={address}
        description={description}
        users={users}
        event={event}
        creator={creator}
        onClose={() => setOpenModal(null)}
      />
      <FlagEventModal
        id={id}
        open={openModal === MODALS.EVENT_FLAG}
        onClose={() => setOpenModal(null)}
      />
      <CancelEventModal
        id={id}
        open={openModal === MODALS.CANCEL_EVENT}
        onClose={() => setOpenModal(null)}
      />
      <LeftModal
        id={id}
        open={openModal === MODALS.LEFT_EVENT}
        onClose={() => setOpenModal(null)}
      />
      <Box mt={{ xs: 0, sm: 2 }} sx={{ backgroundColor: "#FFFF" }}>
        <Box
          width="100%"
          boxSizing="border-box"
          position="relative"
          sx={{
            aspectRatio: { xs: "5/3", sm: "15/3.5" },
            background: `url('${photos[currentPhotoIndex]}') center center/cover`,
          }}
          p={2}
        >
          <Icon
            icon="solar:alt-arrow-left-linear"
            style={{ position: "absolute", top: "50%", right: 0 }}
            onClick={ForwardSwiper}
            width="30"
            height="30"
            rotate={2}
          />
          <Icon
            icon="solar:alt-arrow-left-linear"
            style={{ position: "absolute", top: "50%", left: 0 }}
            onClick={ReverseSwiper}
            width="30"
            height="30"
          />
          <DetailsNavigation event={event} eventFlagged={eventFlagged} />
          <Box
            boxShadow={"0px 3px 20px 0px #a3a2a2"}
            px={4}
            width="100%"
            maxWidth="274px"
            bottom={0}
            left="50%"
            sx={{
              transform: `${
                buttonMenu
                  ? "translateY(86%) translateX(-50%)"
                  : "translateY(50%) translateX(-50%)"
              }`,
              borderRadius: `${buttonMenu ? "20px" : "35px"}`,
            }}
            position="absolute"
            backgroundColor="white"
          >
            <Stack
              px={3}
              alignItems="center"
              justifyContent="center"
              flexDirection="row"
            >
              <Box>
                <Box>
                  <Box
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    variant="contained"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",

                      borderTopRadius: "20px",
                      width: "250px",
                      height: "55px",
                      p: "10px",
                      alignItems: "center",
                      gap: "10px",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      {buttonMenu ? (
                        <Typography color="black" sx={{ fontWeight: 600 }}>
                          {" "}
                          Attendees{" "}
                        </Typography>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "2px",
                            alignItems: "center",
                          }}
                        >
                          <AvatarGroup max={maxAvatars}>
                            {avatars.map((user, i) => (
                              <Avatar
                                style={{ width: "35px", height: "35px" }}
                                key={i}
                                alt={user?.displayName}
                                src={user.photoURL}
                              />
                            ))}
                          </AvatarGroup>

                          <Typography
                            sx={{
                              fontSize: "15px",
                              color: "#27C9C9",
                              fontFamily: "DM Sans",
                            }}
                          >
                            + {remainingUsers} Going
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    <Button
                      style={{
                        backgroundColor: "#F56C6C",
                        color: "white",
                        padding: "0px",
                      }}
                    >
                      RSVP
                    </Button>
                  </Box>

                  {buttonMenu && (
                    <Box sx={{ mb: "10px" }}>
                      {users.map((user,i) => (
                        <Box
                          key={i}
                          id="simple-menu"
                          anchorEl={buttonMenu}
                          open={buttonMenu}
                          onClose={handleMenuClose}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              px: "3px",
                              width: "250px",
                              bgcolor: "white",
                              borderRadius: "10px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                my: "5px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "7px",
                                  alignItems: "center",
                                }}
                              >
                                <Avatar
                                  alt={user?.displayName}
                                  src={user.photoURL}
                                />
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    fontFamily: "sans-serif",
                                  }}
                                >
                                  {user?.displayName.length > 8
                                    ? user?.displayName.slice(0, 8) + "..."
                                    : user?.displayName}
                                </Typography>
                              </Box>
                              <Box>
                                <Button
                                  style={{ fontSize: "16px" }}
                                  onClick={() => {
                                    fetchProfile(user.uid);
                                  }}
                                >
                                  View Profile
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  )}

                  {buttonMenu && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button onClick={handleMenuClose} sx={{ color: "black" }}>
                        Close
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
        <Container
          sx={{ pt: { xs: 2, md: 4 }, pb: { xs: 9, sm: 4, md: 2 }, mt: "8px" }}
        >
          <Stack spacing={3}>
            <Stack mt={4} gap={2}>
              <Stack gap={1}>
                <Typography variant="h3" fontWeight="bold">
                  {eventFlagged && (
                    <Typography
                      style={{
                        background: "red",
                        color: "white",
                        padding: ".5rem 1rem",
                        borderRadius: "20px",
                        width: "fit-content",
                        marginRight: "1rem",
                        display: "inline-block",
                      }}
                    >
                      Flagged By You
                    </Typography>
                  )}
                  {eventCanceled.length > 0 && (
                    <Typography
                      style={{
                        background: "red",
                        color: "white",
                        padding: ".5rem 1rem",
                        borderRadius: "20px",
                        width: "fit-content",
                        marginRight: "1rem",
                        marginBottom: "0.5rem",
                        display: "inline-block",
                      }}
                    >
                      Event Cancelled due to{" "}
                      {eventCanceled[0].reason === "Other"
                        ? "Some Reason"
                        : eventCanceled[0].reason}
                    </Typography>
                  )}
                  {title}
                </Typography>
                <Stack gap={1} flexDirection="row" alignItems="center">
                  {/* <Typography color="primary" variant="body2">
                    {address.city}, {address.state},{" "}
                    {address.country_code?.toUpperCase()}
                  </Typography> */}
                  <img src={dateLogo} alt="date logo" />

                  {/* <Box
                    variant="body2"
                    alignItems="center"
                    sx={{
                      display: "flex",
                      background: "#ceffff",
                      padding: "0px 7px",
                      borderRadius: "8px",
                    }}
                  >
                    {" "}
                    <CalendarMonthIcon style={{ color: "#27C9C9" }} />
                  </Box> */}
                  <Box>
                    <Box>
                      {date.toLocaleDateString("en-US", {
                        // weekday: "short",
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </Box>
                    <Typography variant="body2">
                      {date.toLocaleDateString("en-US", {
                        weekday: "short",
                        // day: "numeric",
                        // month: "long",
                        // year: "numeric",
                      })}{" "}
                      {startTime} - {endTime}
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
              <Stack gap={1} flexDirection="row" alignItems="center">
                <Box
                  variant="body2"
                  alignItems="center"
                  // sx={{
                  //   display: "flex",
                  //   background: "#ceffff",
                  //   padding: "0px 7px",
                  //   borderRadius: "8px",
                  // }}
                >
                  <img src={locationLogo} alt="location logo" />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {/* <p>{address}</p> */}
                  <p>{displayAddress}</p>

                  {/* <LocationCard coordinates={address} /> */}
                  {/* <AddressCard address={displayAddress} /> */}
                </Box>
              </Stack>
              <div
                style={{ height: "0px ", borderTop: "0.1px solid #b9b0b0" }}
              ></div>
              {/* <Typography variant="body1">{description}</Typography> */}
              {/* <Registered users={users} maxParticipants={maxParticipants} /> */}
            </Stack>
            <Stack gap={1}>
              <Grid container>
                <Grid
                  item
                  md={6}
                  xs={6}
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <img src={solarChat} alt="solor icon" />

                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <Typography>Event Cost</Typography>
                      {/* <InfoIcon2 /> */}
                    </Box>

                    <Typography variant="h5">{cost ? cost : "Free"}</Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  md={6}
                  xs={6}
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <img src={people} alt="people icon" />

                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <Typography>Max Attendees</Typography>
                      <InfoIcon2 />
                    </Box>

                    <Typography variant="h5">{maxParticipants}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Stack>
            <div
              style={{ height: "0px ", borderTop: "0.1px solid #b9b0b0" }}
            ></div>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  // flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", gap: "20px" }}>
                  <img
                    style={{ width: "15%", borderRadius: "10px" }}
                    src={users[0]?.photoURL}
                  ></img>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}
                    >
                      {users[0]?.displayName}
                    </Typography>
                    <Typography variant="p" sx={{ color: "#6a6a6a" }}>
                      Orgnizer
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{ height: "94%", whiteSpace: "nowrap" }}
                    onClick={() => {
                      fetchProfile(creator);
                    }}
                  >
                    View Profile
                  </Button>
                </Box>
              </Box>
            </Box>
            {/* <div
              style={{ height: "0px ", borderTop: "0.1px solid #b9b0b0" }}
            ></div> */}
            <Stack gap={1}>
              <Typography variant="h4">Event Details</Typography>
              {description}
            </Stack>
            {/* <Stack mb={2} gap={1}>
              <Typography variant="h4" fontWeight="bold">
                Map
              </Typography>
              <Box borderRadius={3} overflow="hidden">
                {marker?.CreatedBy !== "" ? (
                  <MapView
                    tags={tags}
                    eventDetail={true}
                    width={"80%"}
                    height="500px"
                    markers={marker}
                    center={address}
                  />
                ) : (
                  <></>
                )}
              </Box>
            </Stack> */}
            {/* <Stack gap={1}>
              <Typography variant="h4" fontWeight="bold">
                Message Board
              </Typography>
              <Box borderRadius="20px" overflow="hidden">
                <MessageBoard id={id} />
              </Box>
            </Stack> */}
            <Stack
              gap={1}
              sx={{
                flexDirection: isCreator() ? "row" : "column",
              }}
            >
              <Button
                onClick={clickHandler}
                disabled={date < new Date() || eventCanceled.length > 0}
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#F56C6C",
                  color: "info.main",
                  borderRadius: 8,
                  padding: 1,
                  "&:hover": {
                    backgroundColor: "#F25050",
                  },
                }}
              >
                {isCreator()
                  ? "Cancel Event"
                  : IsUserJoined
                  ? "Leave"
                  : `RSVP FOR  ${cost === 0 ? "FREE" : cost}`}
              </Button>

              <Button
                onClick={updateHandler}
                disabled={date < new Date() || eventCanceled.length > 0}
                variant="contained"
                fullWidth
                sx={{
                  display: isCreator() ? "inline-flex" : "none",
                  backgroundColor: "#F56C6C",
                  color: "info.main",
                  borderRadius: 8,
                  padding: 1,
                  "&:hover": {
                    backgroundColor: "#F25050",
                  },
                }}
              >
                Update Event
              </Button>
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default EventDetails;
