import { Button, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updatePaymentStatusEvent } from "../../firebase/functions/event";
import { initialState } from "../../pages/CreateEvent";
import { ROUTES } from "../../routes";
import { clearCheckboxes } from "../../store/EventSlice";
import { emptyTags } from "../../store/ModelSlice";
import OneTimeGif from "./gif";

const EventCreate = ({ setPages, setState, loader, eventId }) => {
  // const EventCreate = (eventId) => {
  const { id } = useParams();
  console.log({ id });
  const urlParams = new URLSearchParams(window.location.search);
  const getPaymentStatus = () => {
    if (urlParams.has("paid")) {
      return "Paid";
    } else if (urlParams.has("free")) {
      return "Free";
    } else {
      return "Unknown"; // or handle other cases as needed
    }
  };

  const paymentStatus = getPaymentStatus();

  useEffect(() => {
    if (paymentStatus) {
      updatePaymentStatusEvent(id, paymentStatus);
    }
  }, [paymentStatus]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const nextPage = () => {
    dispatch(clearCheckboxes());
    dispatch(emptyTags([]));
    navigate(ROUTES.CREATE_EVENT);
  };

  const navigateToEvent = () => {
    navigate(ROUTES.EVENT_DETAILS.replace(":id", id));
  };

  return (
    <Stack width="100%">
      <Stack>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: 500,
            lineHeight: "36px",
            color: "#120D26",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20vh",
          }}
        >
          Event Created successfully!
        </Typography>
        {/* <div className="gif-div">
          <img
            src="https://res.cloudinary.com/dstnwi5iq/image/upload/v1699533500/golden-tick-mark-symbols-BYEaeX6-600_nrbgza.jpg"
            alt="event SuccessFull"
            style={{ height: "200px", width: "200px" }}
          />
        </div> */}
        <OneTimeGif loader={loader} />
        <Stack
          sx={{
            position: "absolute",
            bottom: "20%",

            paddingX: { xs: 2, sm: 4, md: 10 },
            width: "89vw",
          }}
        >
          <Stack direction="row" sx={{ marginY: "10px", paddingX: { md: 10 } }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#F56C6C",
                width: "100%",
                height: "38px",
                fontSize: "12px",
                fontWeight: 700,
                borderRadius: "7px",
                boxShadow: "0px 8px 20px 0px #4AD2E415",
              }}
              onClick={navigateToEvent}
            >
              GO TO EVENT PAGE
            </Button>
          </Stack>
          <Stack
            direction="row"
            sx={{ marginY: "10px", paddingX: { md: 10 } }}
            onClick={nextPage}
          >
            <Button
              variant="outlined"
              sx={{
                border: " 1px solid #F56C6C",
                width: "100%",
                height: "38px",
                fontSize: "12px",
                fontWeight: 700,
                borderRadius: "7px",
                boxShadow: "0px 8px 20px 0px #4AD2E415",
                color: "#F56C6C",
              }}
            >
              ADD ANOTHER EVENT
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EventCreate;
