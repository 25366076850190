import { getCurrentUser } from "../user";
import { db } from "../../config";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";

let notifications = [];
let currentUser = null;

// Function to fetch all notifications

export const getAllNotifications = async () => {
  currentUser = await getCurrentUser();
  const newNotifications = []; // Create a new array for notifications.

  const notificationsRef = collection(
    db,
    "users",
    currentUser,
    "notifications"
  );


  try {
    const notificationsSnapshot = await getDocs(notificationsRef);

    notificationsSnapshot.forEach((doc) => {
      /* add notification id also */
      let notification = {
        id: doc.id,
        time: new Date(doc.data().timeStamp.seconds * 1000),
        ...doc.data(),
      };
      newNotifications.push(notification); // Push to the new array.
    });

    // Update the notifications array with the new data.
    notifications = newNotifications;
  } catch (error) {
    console.error("Error fetching notifications:", error);
  }
};

// Function to delete a notification by ID
export const getAllNotificationDelete = async (id) => {
  currentUser = await getCurrentUser();
  const newNotifications = []; // Create a new array for notifications.

  const notificationsRef = doc(db, "users", currentUser, "notifications", id);

  try {
    await deleteDoc(notificationsRef);


    // Update the notifications array with the deleted item removed.
    notifications.forEach((notification) => {
      if (notification.id !== id) {
        newNotifications.push(notification);
      }
    });
    notifications = newNotifications;
  } catch (error) {
    console.error("Error deleting notification:", error);
  }
};

// Function to return joined notifications
export const returnJoinedNotifications = () => {
  return notifications
    .filter((notification) => {
      return notification.type !== "new-event";
    })
    .sort((a, b) => {
      return b.time - a.time;
    });
};
