import { Box, Button, Stack, Container, Typography } from "@mui/material";
import userIcon from "../../images/user.jpg";
import PageHeader from "../PageHeader";
import { useEffect, useState } from "react";
import { GetCurrentUserDetails } from "../../firebase/functions/user";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import app from "../../firebase/config";
import { toast } from "react-toastify";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { setUserInfo } from "../../store/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as EditSvg } from "../../images/edit.svg";
import { ReactComponent as LocationIcon } from "../../images/profile-location.svg";
import styled from "@emotion/styled";
import Interest from "./Interest";
import Tags from "./Tags";
import { ReactComponent as BackIcon } from "../../images/Back.svg";
import CustomTabs from "./Tabs";
import { SortEventWithUserId } from "../../firebase/functions/event/sort-event";
import { ROUTES } from "../../routes";
import { useNavigate } from "react-router-dom";
import { clearProfileInfo } from "../../store/ProfileSlice";
import useUpdateEffect from "../../utils/useUpdateEffect";

export const options = [
  { value: "Concerts", label: "Concerts", color: "#53DFDF" },
  { value: "Music", label: "Music", color: "#188181" },
  { value: "Art", label: "Art", color: "#F56C6C" },
  { value: "Movies", label: "Movies", color: "#FFB459" },
  { value: "Reading", label: "Reading", color: "#C19A0B" },
  { value: "Outdoor Events", label: "Outdoor Events", color: "#F99F9F" },
];

const maxWordCount = 150;

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let userInfoData = useSelector((state) => state.UserReducer.userInfo);
  let profileInfo = useSelector(
    (state) => state.UserProfileReducer.profileInfo
  );
  const [userInfo, setUser] = useState(
    Object.keys(profileInfo).length ? profileInfo : userInfoData
  );

  const [type, setType] = useState(
    Object.keys(profileInfo).length ? "profileInfo" : "userInfo"
  );

  const [name, setName] = useState(userInfo?.displayName);
  const [about, setAbout] = useState(userInfo.aboutUs ? userInfo.aboutUs : "");
  const [location, setLocation] = useState(
    userInfo.location &&
      userInfo.location.city &&
      userInfo.location.city !== undefined
      ? userInfo?.location?.city
      : ""
  );
  const [pastEvent, setPastEvent] = useState(userInfo?.pastEvent);
  const [selectedOption, setSelectedOption] = useState(
    userInfo.interest ? userInfo.interest : []
  );
  // const [phone, setPhone] = useState(userInfo.phone ? userInfo.phone : "");
  // const [oldPhone, setOldPhone] = useState("");
  // const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [tempPhotoURL, setTempPhotoURL] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [wordCount, setWordCount] = useState(maxWordCount - about.length);

  useEffect(() => {
    toast(errorMessage, { type: "error" });
  }, [errorMessage]);

  useUpdateEffect(() => {
    if (Object.keys(profileInfo).length) {
      setType("profileInfo");
      setUser(profileInfo);
    }
  }, [profileInfo]);

  useEffect(() => {
    SortEventWithUserId(userInfo.uid).then((data) => {
      setPastEvent(data.length);
    });
    return () => {
      dispatch(clearProfileInfo());
    };
  }, []);

  const handleFileUpload = async (event) => {
    setTempPhotoURL(event.target.files[0]);
  };

  const handleUpdateName = async () => {
    if (name === "") {
      toast("Name cannot be empty!", { type: "error" });
      return;
    }

    try {
      await uploadAndSetUserData();
      toast("Profile updated successfully!", { type: "success" });
    } catch (error) {
      console.error(error);
    }
  };

  const uploadAndSetUserData = async () => {
    const db = getFirestore(app);
    const storage = getStorage();
    const randomName =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    let updatedUserData = {
      displayName: name,
      aboutUs: about,
      interest: selectedOption,
      location: { city: location },
      follow: userInfo.follow || 0,
      following: userInfo.following || 0,
      pastEvent: userInfo.pastEvent || 0,
    };
    if (tempPhotoURL) {
      toast("Uploading photo...", { type: "info" });
      const storageRef = ref(storage, `${randomName}`);
      await uploadBytes(storageRef, tempPhotoURL);

      const downloadURL = await getDownloadURL(storageRef);
      updatedUserData = { ...updatedUserData, photoURL: downloadURL };
    }

    await updateUserDataInFirestore(db, userInfo.uid, updatedUserData);
    updateLocalState(updatedUserData);
  };

  const updateUserDataInFirestore = async (db, uid, data) => {
    const userRef = doc(db, "users", uid);
    try {
      await updateDoc(userRef, data);
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  const updateLocalState = (updatedUserData) => {
    dispatch(setUserInfo({ ...userInfo, ...updatedUserData }));
  };

  // const handleSendCode = async () => {
  //   const db = getFirestore(app);
  //   const auth = getAuth();
  //   if (phone === oldPhone) {
  //     toast("Phone number is same as before!", { type: "error" });
  //     return;
  //   }
  //   if (phone === "") {
  //     toast("Phone number cannot be empty!", { type: "error" });
  //     return;
  //   }
  //   try {
  //     const recaptchaVerifier = new RecaptchaVerifier(
  //       "recaptcha-container",
  //       {},
  //       getAuth()
  //     );

  //     const confirmationResult = await signInWithPhoneNumber(
  //       auth,
  //       phone,
  //       recaptchaVerifier
  //     );
  //     setErrorMessage(null);
  //     const code = window.prompt(
  //       "Please enter the verification code sent to your phone:"
  //     );
  //     if (code) {
  //       const res = await confirmationResult.confirm(code);
  //       if (res.user) {
  //         setIsPhoneVerified(true);
  //         setOldPhone(phone);
  //         updateDoc(doc(db, "users", userInfo.uid), {
  //           phone: phone,
  //           isPhoneVerified: true,
  //         });
  //         toast("Phone number verified!", { type: "success" });
  //       }
  //     }
  //     const recaptchaContainer = document.getElementById("recaptcha-container");
  //     while (recaptchaContainer.firstChild) {
  //       recaptchaContainer.removeChild(recaptchaContainer.firstChild);
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //     setErrorMessage(error.message);
  //   }
  // };

  const handleChangeAbout = (e) => {
    const temp = e.target.value;
    const newWordCount = maxWordCount - temp.length;
    if (newWordCount >= 0 && newWordCount <= maxWordCount) {
      setAbout(temp);
      setWordCount(newWordCount);
    }
  };

  return (
    <Container sx={{ py: { xs: 3.5, md: 4 }, px: "25px" }}>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems=""
        gap={2}
      >
        {type === "userInfo" ? (
          <PageHeader title="My Profile" />
        ) : (
          <Stack position="relative" flexDirection="row" alignItems={"center"}>
            <BackIcon
              onClick={() => {
                navigate(-1);
                dispatch(clearProfileInfo());
              }}
            />
          </Stack>
        )}
        {type === "userInfo" ? (
          <Stack
            onClick={() => {
              if (isUpdating) {
                handleUpdateName();
              }
              setIsUpdating((prevState) => !prevState);
            }}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {!isUpdating && <EditSvg />}
            <Button
              sx={{
                color: "#F56C6C",
              }}
            >
              {isUpdating ? "Save" : "Edit Profile"}
            </Button>
          </Stack>
        ) : (
          ""
        )}
        <Stack gap={2} flexDirection={"column"} justifyContent="center">
          <Stack
            flexDirection={"row"}
            width={"100%"}
            justifyContent={"space-between"}
          >
            <Box
              maxWidth="75px"
              sx={{ aspectRatio: "1/1", border: "3px solid #F56C6C" }}
              borderRadius="50%"
              width={"100%"}
              overflow="hidden"
              backgroundColor="#FFFF"
              display="flex"
              alignItems="center"
              justifyContent="center"
              position="relative"
            >
              <img
                style={{
                  objectFit: "cover",
                  width: "96%",
                  aspectRatio: "1/1",
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
                src={
                  userInfo?.photoURL
                    ? userInfo.photoURL
                    : "https://cdn1.iconfinder.com/data/icons/messenger-and-society/16/user_person_avatar_unisex-512.png"
                }
                alt="user"
              />
              <input
                type="file"
                id="file-input"
                name="file-input"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
              <label
                id="file-input-label"
                for="file-input"
                style={{
                  display: isUpdating ? "flex" : "none",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "black",
                  color: "#FFFF",
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  opacity: 0.8,
                  cursor: "pointer",
                  width: "100%",
                }}
              >
                Edit
              </label>
            </Box>
            <Stack
              flexDirection={"row"}
              justifyContent={"space-around"}
              width={"100%"}
              marginLeft={"7%"}
            >
              <StyledStack>
                <StyledTypography>{userInfo.following || 0}</StyledTypography>
                <StyledTypography>Following</StyledTypography>
              </StyledStack>
              <StyledStack>
                <StyledTypography>{userInfo.followers || 0}</StyledTypography>
                <StyledTypography>Followers</StyledTypography>
              </StyledStack>
              <StyledStack>
                <StyledTypography>{pastEvent || 0}</StyledTypography>
                <StyledTypography>Past Events</StyledTypography>
              </StyledStack>
            </Stack>
          </Stack>

          <Stack
            alignItems={"center"}
            textAlign={{ xs: "center", sm: "initial" }}
            marginTop={"-10px"}
          >
            <StyledInput
              type="text"
              readOnly={!isUpdating}
              isUpdating={isUpdating}
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
            />

            <Stack
              width={"100%"}
              flexDirection={"row"}
              alignItems={"center"}
              sx={{ marginTop: "5px" }}
            >
              <LocationIcon style={{ marginRight: "7px" }} />
              <StyledInput
                type="text"
                readOnly={!isUpdating}
                isUpdating={isUpdating}
                small
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
                value={location}
              />
            </Stack>
            <div
              style={{
                display: isUpdating ? "block" : "none",
              }}
              id="recaptcha-container"
            ></div>
          </Stack>

          <Stack
            width={"100%"}
            sx={{
              borderTop: "1px solid rgba(142, 148, 155, 0.27)",
              marginY: "10px",
              paddingTop: "10px",
            }}
          >
            {type === "userInfo" ? (
              <>
                <Stack>
                  <AboutTypoGraphy>About Me</AboutTypoGraphy>
                  <div className="text-area">
                    <StyledTextarea
                      rows={5}
                      isUpdating={isUpdating}
                      readOnly={!isUpdating}
                      onChange={(e) => {
                        handleChangeAbout(e);
                      }}
                      value={about}
                    />
                    {isUpdating && (
                      <StyledWordCount>{wordCount} Words Left</StyledWordCount>
                    )}
                  </div>
                </Stack>
                <Stack>
                  <AboutTypoGraphy>Interests</AboutTypoGraphy>
                  {isUpdating && (
                    <Interest
                      options={options}
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                    />
                  )}
                  {!isUpdating && (
                    <Tags options={options} selectedOption={selectedOption} />
                  )}
                </Stack>
              </>
            ) : (
              <CustomTabs
                userInfo={userInfo}
                options={options}
                setPastEvent={setPastEvent}
                selectedOption={selectedOption}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Header;

const StyledStack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const AboutTypoGraphy = styled.p`
  color: var(--color-typography-title, #120d26);
  text-rendering: optimizeLegibility;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  margin: 0;
`;

const StyledTypography = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #120d26;
  display: flex;
  justify-content: center;

  &:last-child {
    font-size: 12px;
    font-weight: 400;
    color: #747688;
  }
`;

const StyledInput = styled.input`
  font-size: ${(props) => (props.small ? "12px" : "24px")};
  color: ${(props) => (props.small ? "#20ACAC" : "#120D26")};
  font-weight: ${(props) => (props.small ? "400" : "500")};
  outline: none;
  border: ${(props) => (props.isUpdating ? "1px solid grey" : "none")};
  box-shadow: ${(props) =>
    props.isUpdating ? "0 0 50px 1px rgba(0, 0, 0, 0.1)" : "none"};
  border-radius: 5px;
  background-color: ${(props) => (props.isUpdating ? "#FFFF" : "transparent")};
  padding: ${(props) => (props.isUpdating ? "5px" : "0px")};
  width: 100%;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #3c3e56;
  outline: none;
  resize: none;
  padding: 5px 9px 5px 9 px;
  line-height: 25px;
  border: ${(props) => (props.isUpdating ? "1px solid grey" : "none")};
`;

const StyledWordCount = styled.p`
  color: #20acac;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  bottom: 4%;
  right: 2%;
`;
