import ExploreHeader from "../components/Explore/ExploreHeader/ExploreHeader";
import ExploreView from "../components/Explore/ExploreView/ExploreView";
import { useModalsContext } from "../modals/ModalsContext";
import SortAndFilterModal from "../modals/SortAndFilterModal";
import { MODALS } from "../modals/modals";
import { useState } from "react";
import TagModelExplore from "../modals/TagModelExplore";
import ExploreGuestView from "../components/Explore/ExploreGuestView/ExploreView";
import { useLocation } from "react-router-dom";

const ExploreGuest = () => {
  const { openModal, setOpenModal } = useModalsContext();

  const [appliedFilters, setAppliedFilters] = useState(null);
  const location = useLocation()

  console.log({"location data":location});
  
  const [tags, setTags] = useState([]);

  const FiltersChanged = (filters) => {
    console.log({'change filter':filters});
    setAppliedFilters(filters);
  };

  return (
    <div>
      <SortAndFilterModal
        onApply={FiltersChanged}
        open={openModal === MODALS.SORT_FILTER}
        onClose={() => setOpenModal(null)}
      />

      <ExploreGuestView filters={appliedFilters} location={location.search} />
      
      <TagModelExplore
        tags={tags}
        setTags={setTags}
        onClose={() => setOpenModal(true)}
        open={openModal === MODALS.TAGS_EXPLORER}
      />
    </div>
  );
};

export default ExploreGuest;
