import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

import { calculateRGB } from '../../../utils/getAverageRGB';
import app from '../../config';
import { getCurrentUser } from '../user';

import store from '../../../store/store';

/* Basic Variables */
const storage = getStorage();
const db = getFirestore(app);
let currentUser = null;
// getCurrentUser();

/* add event :: Start */
export const addEvent = async (event, setLoader) => {
  const eventId = Math.random().toString(36).substring(2);
  currentUser = await getCurrentUser();
  const reader = new FileReader();
  reader.onload = function (e) {
    const imgSrc = e.target.result;
    calculateRGB(imgSrc).then(async (rgb) => {
      event = { ...event, bgColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 1)` };

      // Upload each photo to Firebase Storage With Random Name
      const photoUrls = await Promise.all(
        event.photos.map(async (photo) => {
          const photoRef = ref(
            storage,
            `photos/${Math.random().toString(36).substring(2)}`
          );
          await uploadBytes(photoRef, photo);
          return await getDownloadURL(photoRef);
        })
      );

      // Add photo URLs to the event object
      const eventWithPhotos = { ...event, photos: photoUrls };
      const { startTime, date } = event;
      const [year, mon, day] = date.split('-');
      const [hour, min] = startTime.split(':');

      // Add Event to Firebase with Timestamp and CreatedBy
      setDoc(doc(db, 'events', eventId), {
        ...eventWithPhotos,
        timeStamp: Timestamp.fromDate(new Date()),
        eventId:eventId,
        CreatedBy: currentUser,
        date: new Date(year, mon - 1, day, hour, min),
        joined: [currentUser],
        attendees: 1,
        attendeesUser: {
          [currentUser]: 1,
        },
        participantCount: 1,
        latitude: event.address.lat,
        longitude: event.address.lng,
        comments: [],
      }).then(() => {
        setLoader(true);
      });
      /* after Doc is set */
    });
  };
  reader.readAsDataURL(event.photos[0]);
  return eventId;
};
/* add event :: End */

/* Update event :: Start */
export const updateEvent = async (event, id, setLoader) => {
  const eventId = id;
  currentUser = await getCurrentUser();
  const reader = new FileReader();
  // Upload each photo to Firebase Storage With Random Name
  const photoUrls = await Promise.all(
    event.photos.map(async (photo) => {
      if (typeof photo === 'string') {
        return photo;
      } else {
        const photoRef = ref(
          storage,
          `photos/${Math.random().toString(36).substring(2)}`
        );
        await uploadBytes(photoRef, photo);
        return await getDownloadURL(photoRef);
      }
    })
  );

  // Add photo URLs to the event object
  const eventWithPhotos = { ...event, photos: photoUrls };
  const { startTime, date } = event;
  const [year, mon, day] = date.split('-');
  const [hour, min] = startTime.split(':');

  const eventRef = doc(db, 'events', eventId);

  // Add Event to Firebase with Timestamp and CreatedBy
  updateDoc(eventRef, {
    ...eventWithPhotos,
    date: Timestamp.fromDate(new Date(year, mon - 1, day, hour, min)),
    attendees: event.attendees,
    attendeesUser: event.attendeesUser,
    participantCount: event.participantCount,
    latitude: event.address.lat,
    longitude: event.address.lng,
    comments: event.comments,
  }).then(() => {
    setLoader(true);
  });
};

export const updatePaymentStatusEvent = async (eventId, status) => {
  // Reference to the event document in Firestore
  const eventRef = doc(db, 'events', eventId);

  // Update the payment status field
  try {
    await updateDoc(eventRef, {
      paymentStatus: status // Assuming the field is named 'paymentStatus'
    });
    console.log('Payment status updated to:', status);
    return { success: true };
  } catch (error) {
    console.error('Error updating payment status:', error);
    return { success: false, error };
  }
};

/* Update event :: End */

/* view event by id :: Start */
export const ViewEventById = async (id) => {
  const docRef = doc(db, 'events', id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
};
/* view event by id :: End */

/* view event created by user :: Start */
export const ViewEventCreatedByUser = async (CreatedBy, maxItems) => {
  try {
    // Get a reference to the Firestore collection that contains the events
    const eventsRef = collection(db, 'events');

    // Query the collection to get events that were created by the specified user
    const q = query(
      eventsRef,
      where('CreatedBy', '==', CreatedBy),
      limit(maxItems)
    );

    // Map the query snapshot to an array of event objects
    // Return the array of event objects
    const querySnapshot = await getDocs(q);
    const events = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    /* sort events by date */
    return events.sort((a, b) => {
      return b.date.seconds - a.date.seconds;
    });
  } catch (error) {
    console.log('error', error);
    // Return an empty array or throw an error, depending on your use case
    return [];
  }
};
/* view event created by user :: End */

/* view event created by current loggedIn user :: Start */
export const ViewEventsCreatedByMe = async (maxLimit) => {
  currentUser = await getCurrentUser();
  return ViewEventCreatedByUser(currentUser, maxLimit);
};
/* view event created by current loggedIn user :: End */

export const GetLatitudeAndLongitudeOfDistance = async (
  latitude,
  longitude,
  distance
) => {
  let lat = 0.0144927536231884;
  let lon = 0.0181818181818182;

  let lowerLat = latitude - lat * distance;
  let lowerLon = longitude - lon * distance;

  let greaterLat = Number(latitude) + Number(lat * distance);
  let greaterLon = Number(longitude) + Number(lon * distance);


  return { lowerLat, lowerLon, greaterLat, greaterLon };
};
export const GetLatitudeAndLongitudeOfDistanceNear = async (
  latitude,
  longitude,
  distance
) => {
  let degreeLat = distance / 111; // Approx. 1 degree of latitude is approximately 111km
  let lowerLat = latitude - degreeLat;
  let greaterLat = latitude + degreeLat;
  let degreeLong = distance / (111 * Math.cos((latitude * Math.PI) / 180)); // Approx. 1 degree of longitude is approximately 111km at the equator, but scales with latitude
  let lowerLon = longitude - degreeLong;
  let greaterLon = longitude + degreeLong;

  // let lat = 0.0144927536231884;
  // let lon = 0.0181818181818182;

  // let lowerLat = latitude - lat * distance;
  // let lowerLon = longitude - lon * distance;

  // let greaterLat = latitude + lat * distance;
  // let greaterLon = longitude + lon * distance;

  return { lowerLat, lowerLon, greaterLat, greaterLon };
};

// const GetUserLocation = async () => {
//     return new Promise((resolve, reject) => {
//         navigator.geolocation.getCurrentPosition(function (position) {
//             resolve(position);
//             /* console it */
//             return {
//                 latitude: position.coords.latitude,
//                 longitude: position.coords.longitude
//             }
//         }, function (error) {
//             reject(error);
//         });
//     });
// }

export const GetAreaNearUser = async (distance) => {
  const {
    LocationReducer: { location },
  } = store.getState();
  // const userLocation = await GetUserLocation();
  return GetLatitudeAndLongitudeOfDistance(
    location.latitude,
    location.longitude,
    distance
  );
};
export const GetNearArea = async (lat, lang, distance) => {
  // const {
  //   LocationReducer: { location },
  // } = store.getState();
  // const userLocation = await GetUserLocation();
  return GetLatitudeAndLongitudeOfDistanceNear(lat, lang, distance);
};

export const getUserLocationCity = async () => {
  try {
    const {
      LocationReducer: { location },
    } = store.getState();
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${
        location.latitude
      },${
        location.longitude
      }&result_type=locality&key=${'AIzaSyDAXr7GY62OB8KITsDM33BNEdYq7Z6f8aA'}`
    );
    const data = await response.json();
    if (data.results.length > 0) {
      return data.results[0].formatted_address;
    }

    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const GetKeywordsFromAllEvents = async () => {
  const eventsCol = collection(db, 'events'); // Get a reference to the "events" collection.
  const eventDocs = await getDocs(eventsCol); // Retrieve all documents in the "events" collection.
  return eventDocs.docs.map((doc) => doc.data().keywords); // Return an array of the data objects for each document.
};

export const FlagEvent = async (eventId, content) => {
  const docRef = doc(db, 'events', eventId);
  /* add new collection flag */
  const flagRef = collection(db, 'events', eventId, 'flags');
  currentUser = await getCurrentUser();
  const flagDoc = await addDoc(flagRef, {
    reason: content,
    timeStamp: Timestamp.fromDate(new Date()),
    createdBy: currentUser,
  });
  return flagDoc.id;
};

export const CancelEvent = async (eventId, content) => {
  /* add event in cancel collection */
  const CancelRef = collection(db, 'events', eventId, 'cancel');
  currentUser = await getCurrentUser();
  const CancelDoc = await addDoc(CancelRef, {
    reason: content,
    timeStamp: Timestamp.fromDate(new Date()),
    createdBy: currentUser,
  });
  return CancelDoc.id;
};

export const checkIfEventIsFlaggedByCurrentUser = async (eventId) => {
  const docRef = collection(db, 'events', eventId, 'flags');
  currentUser = await getCurrentUser();
  const q = query(docRef, where('createdBy', '==', currentUser));
  const querySnapshot = await getDocs(q);
  const events = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return events.length > 0;
};

export const checkIfEventIsCanceled = async (eventId) => {
  const docRef = collection(db, 'events', eventId, 'cancel');
  currentUser = await getCurrentUser();
  const querySnapshot = await getDocs(docRef);
  const events = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return events;
};
