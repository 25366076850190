import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

// mui imports
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import ContinueWithSocialMedia from "../components/ContinueWithSocialMedia";
import FormContainer from "../components/FormContainer";
import StyledTextInput from "../components/StyledTextInput";
import useSignIn from "../hooks/useSignIn";
import { ReactComponent as Logo } from "../icons/Favicon-forms.svg";
import { ReactComponent as HiddenEye } from "../icons/HiddenEye.svg";
import { Box } from "@mui/material";
import { ROUTES } from "../routes";
import { setLoaded } from "../store/LocationSlice";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../store/UserSlice";
import { useEffect, useState } from "react";
import { GetCurrentUserDetails } from "../firebase/functions/user";

const Header = () => {
  const navigate = useNavigate();
  return (
    <Stack
      position="relative"
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        left={0}
        position="absolute"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        {/* <BackButton /> */}
      </Box>
      <Logo
        style={{
          boxShadow:
            "0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.10)",
          borderRadius: "50%",
        }}
      />
      {/* <Typography fontWeight="bold" fontSize="20px" textAlign="center">
                Create an account
            </Typography> */}
    </Stack>
  );
};

const SignInForm = ({ inputs, formData, handleOnChange, setHide, hide }) => (
  <Stack spacing={2}>
    {inputs.map((inputProps, index) => (
      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <input
          {...inputProps}
          key={index}
          placeholder={inputProps.label}
          value={formData[inputProps.name]}
          onChange={handleOnChange}
          type={inputProps.type === "password" && hide ? "password" : "text"}
          style={{
            padding: "9px 5px",
            border: "1px solid #D9D9D9",
            borderRadius: "8px",
            width: "100%",
            paddingLeft: "18%",
            fontSize: "14px",
            fontWeight: 400,
            color: "#666666",
          }}
        />
        <img
          style={{ position: "absolute", left: "5%" }}
          src={inputProps.image}
        />
        <img
          onClick={() => {
            setHide(!hide);
          }}
          style={{ position: "absolute", right: "5%" }}
          src={hide ? inputProps.HideEye : inputProps.eye}
        />
      </div>
    ))}
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <FormControlLabel
        sx={{ marginLeft: "1%" }}
        control={
          <input
            style={{ marginRight: "7px" }}
            type="checkbox"
            name="rememberMe"
            checked={formData.rememberMe}
            onChange={handleOnChange}
          />
        }
        label="Remember Me"
      />
      <Link
        component={RouterLink}
        to="/forgot-password"
        color="inherit"
        underline="always"
        sx={{ color: "#20ACAC", fontSize: "14px", fontWeight: 400 }}
      >
        Forgot password?
      </Link>
    </Stack>
  </Stack>
);

const Login = () => {
  const {
    inputs,
    formData,
    handleOnChange,
    handleSubmit,
    handleSubmitAsGuest,
  } = useSignIn();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.UserReducer.userInfo);

  const [hide, setHide] = useState(true);
const location =useLocation();
  const handleClick = (e) => {

    handleSubmit(e).then(() => {
      console.log('dfsdff',e);
      dispatch(setLoaded(true));
      GetCurrentUserDetails().then((data) => {
        dispatch(setUserInfo(data));
      });
    })
  }
console.log("user_info",userInfo);
  useEffect(() =>{
console.log('userInfo',location.search.includes("/event/"));
location.search.includes("/event/")
     if(location.search && userInfo && userInfo.uid !=='' && !location.search.includes("/event/"))
      {
        console.log('11');
        setTimeout(() => {
          navigate(`${ROUTES.CREATE_EVENT_GUEST}/${location.search}`);
        }, 3000);
      }
    
  
  },[userInfo,location.search])

  const handleClickGuest = () => {
    dispatch(setUserInfo({ ...userInfo, email: "Anonymous" }));
    handleSubmitAsGuest();
    navigate(ROUTES.HOME);
  };

  return (
    <FormContainer>
      <Header />
      <Stack maxWidth={"400px"} width={"90%"} sx={{ marginTop: 5 }} spacing={5}>
        <Typography
          align="center"
          sx={{
            fontSize: "24px",
            lineHeight: "36px",
            fontWeight: "500",
            fontStyle: "normal",
            color: "#303030",
            fontFamily: "PoppinsRegular",
          }}
        >
          Sign In
        </Typography>
        <SignInForm
          {...{ inputs, formData, handleOnChange }}
          hide={hide}
          setHide={setHide}
        />
        <Button
          onClick={handleClick}
          variant="contained"
          sx={{
            backgroundColor: "#20ACAC",
            fontSize: "15px",
            fontWeight: 700,
            lineHeight: "19px",
            color: "info.main",
            borderRadius: "8px",
            padding: "17px",
            boxshadow:
              "0px 9.057971000671387px 31.702898025512695px 0px #6F7EC926",
            "&:hover": {
              backgroundColor: "#20ACAC",
            },
          }}
        >
          SIGN IN
        </Button>
      </Stack>
      <ContinueWithSocialMedia
        sx={{ marginTop: 5, marginBottom: 8, textAlign: "center" }}
      />
      <Box
        sx={{
          pt: 2,
          pb: 6,
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
          fontWeight: 400,
        }}
        flexDirection={"column"}
      >
        <Typography textAlign="center">Don’t have an account? </Typography>
        <Link
          component={RouterLink}
          to={ROUTES.SIGN_UP}
          underline="always"
          sx={{ color: "#20ACAC", fontSize: "14px", fontWeight: 400 }}
        >
          Sign up for free.
        </Link>
      </Box>
    </FormContainer>
  );
};

export default Login;
