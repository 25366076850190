const PaymentDetails = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14" cy="13.9997" r="11.6667" stroke="#111315" strokeWidth="2" />
    <path
      d="M17.3334 11.6667C17.3334 12.219 16.8856 12.6667 16.3334 12.6667C15.7811 12.6667 15.3334 12.219 15.3334 11.6667H17.3334ZM15.3334 11.6667C15.3334 10.9303 14.7364 10.3333 14 10.3333V8.33333C15.841 8.33333 17.3334 9.82572 17.3334 11.6667H15.3334ZM14 10.3333C13.2636 10.3333 12.6667 10.9303 12.6667 11.6667H10.6667C10.6667 9.82572 12.1591 8.33333 14 8.33333V10.3333ZM12.6667 11.6667C12.6667 12.403 13.2636 13 14 13V15C12.1591 15 10.6667 13.5076 10.6667 11.6667H12.6667Z"
      fill="#111315"
    />
    <path
      d="M14 14.0003C15.2887 14.0003 16.3334 15.045 16.3334 16.3337C16.3334 17.6223 15.2887 18.667 14 18.667C12.7114 18.667 11.6667 17.6223 11.6667 16.3337"
      stroke="#111315"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M14 7.58301V9.33301"
      stroke="#111315"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M14 18.667V20.417"
      stroke="#111315"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default PaymentDetails;
