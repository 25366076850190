import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { ReactComponent as MapImage } from "../../images/map-icon.svg";
import "swiper/css";
import styles from "../../styles/Event.module.scss";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes";
import {
  DislikeEventById,
  IsEventLikedByUser,
  LikeEventById,
} from "../../firebase/functions/event/event-likes";
import { timeTo12HrFormat } from "../../utils/timeFunctions";
import { GetUsersById } from "../../firebase/functions/user";
import BookMarkAct from "../../icons/BookMarkAct";
import BookMark from "../../icons/BookMark";

const EventCard = ({ event, customBg, shadow }) => {
  const navigate = useNavigate();
  const [userPhotos, setPhotos] = useState([]);
  try {
    event.date = event.date.toDate();
  } catch (error) {}
  const {
    id,
    photos,
    title,
    location,
    participant,
    date,
    startTime,
    eventJoined,
    description,
  } = event;

  const [like, setLike] = useState({ id: null, isLike: false });
  useEffect(() => {
    try {
      IsEventLikedByUser(id).then((res) => {
        setLike({ id: id, isLike: res });
      });
    } catch (error) {
      console.log("error", error);
    }

    // event.joined?.forEach((user) => {
    //   /* get all details of this user and push it to setPhotos */
    //   /* only unique values in userPhotos and length 3 */
    //   if (userPhotos.length < 3) {
    //     GetUsersById(user).then((res) => {
    //       setPhotos((userPhotos) => [...userPhotos, res]);
    //     });
    //   }
    // });
  }, []);

  const handleLike = (id) => {
    if (like.isLike) {
      DislikeEventById(id);
      setLike({ id: id, isLike: false });
    } else {
      LikeEventById(id);
      setLike({ id: id, isLike: true });
    }
  };
  
  return (
    <Box
      sx={{
        backgroundColor: customBg ? customBg : "#FFFFFF",
        boxShadow: "0px 8px 30px 0px #5055880F",
        p: { xs: 1.5, md: 2 },
        borderRadius: "20px",
      }}
      >
      <Stack
        direction="row"
        justifyContent="space-between"
        gap={2}
      >
        <Box>
          <img className={styles.eventImage} src={photos} alt="" />
        </Box>
        <Box marginTop={"15px"}>
          {date && startTime && (
            <Stack
              direction="row"
              sx={{ mb: { xs: 1, md: 2 } }}
              gap={{ xs: 0.5, md: 1 }}
              style={{
                marginTop: "4px",
              }}
            >
              <Typography
                sx={{ fontSize: { xs: 12, md: 16 } }}
                variant="h6"
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#27C9C9",
                  // opacity: "0.5"
                }}
              >
                {date
                  .toLocaleDateString("en-US", {
                    weekday: "short",
                    day: "numeric",
                    month: "short",
                  })
                  .toUpperCase()}
              </Typography>

              <Typography
                sx={{ fontSize: { xs: 12, md: 16 } }}
                variant="h6"
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#27C9C9",
                  // opacity: "0.5"
                }}
              >
                . {timeTo12HrFormat(startTime)}
              </Typography>
            </Stack>
          )}
          <Typography
            sx={{
              fontSize: { xs: 15, md: 20 },
              fontWeight: "400",
              color: "#303030",
            }}
            variant="h5"
            gutterBottom
          >
            {title.length > 15 ? title.slice(0, 15) + "..." : title}
          </Typography>
          <Box sx={{ display: "flex", mt: "5px" }}>
            <MapImage />
            {event.startAddress && (
              <Typography
                sx={{
                  fontSize: { xs: 13, md: 15 },
                  fontWeight: "400",
                  color: "#666666",
                  ml: "5px",
                }}
                variant="body1"
              >
                {event.startAddress}
              </Typography>
            )}
          </Box>
        </Box>
        {/* on desktop screen, join and like */}
        <Stack
          marginTop={"10px"}
          direction="column"
          gap={4}
          justifyContent="space-between"
          alignItems="center"
          sx={{ display: { xs: "flex", lg: "flex" } }}
        >
          <Box
            onClick={() => handleLike(id)}
            lg={{
              fontSize: 24,
              bgcolor: "info.main",
              color:
                like.isLike && like.id === id ? "error.main" : "text.secondary",
              width: 50,
              height: 50,
              lineHeight: "54px",
              textAlign: "center",
              borderRadius: "50%",
              cursor: "pointer",
            }}
            sx={{
              fontSize: 16,
              bgcolor: "info.main",
              color:
                like.isLike && like.id === id ? "error.main" : "text.secondary",
              width: 32,
              height: 32,
              lineHeight: "48px",
              textAlign: "center",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          >

            {like.isLike && like.id === id ? (

              <BookMark height={15} width={16} />

              ) : 
              (
              <BookMarkAct height={15} width={16} />
            )}
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EventCard;
