const PasswordIcon = () => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3094 6.47095H4.37886M11.3094 6.47095C13.2233 6.47095 14.7747 8.02242 14.7747 9.93624V15.1342C14.7747 17.048 13.2233 18.5995 11.3094 18.5995H4.37886C2.46504 18.5995 0.913574 17.048 0.913574 15.1342V9.93624C0.913574 8.02242 2.46504 6.47095 4.37886 6.47095M11.3094 6.47095V4.73831C11.3094 2.82449 9.75797 1.27303 7.84415 1.27303C5.93032 1.27303 4.37886 2.82449 4.37886 4.73831V6.47095M9.57679 12.5352C9.57679 13.4921 8.80106 14.2678 7.84415 14.2678C6.88723 14.2678 6.1115 13.4921 6.1115 12.5352C6.1115 11.5783 6.88723 10.8026 7.84415 10.8026C8.80106 10.8026 9.57679 11.5783 9.57679 12.5352Z"
      stroke="#CED1DC"
      strokeWidth="1.41762"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PasswordIcon;
