import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import "../../styles/Home.module.scss";
import { ROUTES } from "../../routes";
import { useEffect, useState } from "react";
import { GetCurrentUserDetails } from "../../firebase/functions/user";
import { setUserInfo } from "../../store/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import pick from "../../images/combined-Shape.png";
import { ReactComponent as Notification } from "../../images/notification.svg";
import { ReactComponent as LocationIcon } from "../../images/location-icon.svg";
import { ReactComponent as SearchIcon } from "../../images/search.svg";
import { ReactComponent as FilterIcon } from "../../images/filter.svg";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

import { AiOutlinePlus } from "react-icons/ai";
import LocationHook from "../../hooks/useLocationHook";
import { setLoaded } from "../../store/LocationSlice";
import { useModalsContext } from "../../modals/ModalsContext";
import { MODALS } from "../../modals/modals";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#F56C6C",
    color: "#F56C6C",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const HomeHeaderDesktop = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.UserReducer.userInfo);
  const location = useSelector((state) => state.LocationReducer.location);
  const loaded = useSelector((state) => state.LocationReducer.loaded);
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userPhoto, setUserPhoto] = useState("");
  const { setCurrentLocationPoints } = LocationHook();
  const { setOpenModal } = useModalsContext();

  useEffect(() => {
    if (userInfo !== null) {
      setUserPhoto(userInfo.photoURL);
      setUserName(userInfo?.displayName);
      if (userInfo?.displayName.split(" ").length >= 1) {
        setFirstName(userInfo?.displayName.split(" ")[0]);
        setLastName(userInfo?.displayName.split(" ")[1]);
        setUserPhoto(userInfo?.photoURL);
      } else {
        setFirstName(userInfo?.displayName);
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (loaded) {
      setCurrentLocationPoints();
      GetCurrentUserDetails().then((data) => {
        dispatch(setUserInfo(data));
      });
    }
    dispatch(setLoaded(false));
  }, [loaded]);

  return (
    <>
      <Container
        className="header-input"
        sx={{
          pt: { xs: 1, md: 2 },
          backgroundColor: "#20ACAC",
          borderRadius: "0px 0px 33px 33px",
          display: { xs: "none", sm: "block" },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <img src={pick} alt="user" />
          <Box style={{ marginLeft: "6px" }}>
            <Typography
              sx={{
                fontSize: 12,
                mb: 0,
                color: "#FEFEFE",
                fontWeight: "400",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              variant="subtitle1"
            >
              Current Location
              <span style={{ marginLeft: "3px" }}>
                <LocationIcon />
              </span>
            </Typography>
            <Typography
              sx={{
                fontSize: 13,
                mb: 0,
                color: "#FFFFFF",
                fontWeight: "400",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              variant="subtitle2"
              gutterBottom
            >
              {location.structured_formatting.main_text +
                ", " +
                location.structured_formatting.secondary_text}
            </Typography>
          </Box>
          <Box>
            <Button
              onClick={() => navigate(ROUTES.NOTIFICATIONS)}
              sx={{
                bgcolor: "#35b3b3",
                color: "info.main",
                fontSize: { xs: 24, md: 30 },
                borderRadius: "50%",
                width: { xs: "42px", sm: "60px" },
                height: { xs: "42px", sm: "60px" },
                minWidth: "unset",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",
                boxShadow: 0,
                p: 0,
              }}
              variant="contained"
            >
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                variant="dot"
              >
                <Notification />
              </StyledBadge>
            </Button>
          </Box>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginTop={2}
            marginBottom={2}
            gap={1}
            divider={
              <Divider
                sx={{ color: "#53DFDF", height: "25px", marginTop: "5px" }}
                orientation="vertical"
                flexItem
              />
            }
          >
            <Box>
              <SearchIcon />
            </Box>
            <TextField placeholder="Search..." variant="standard" />
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "50px",
              backgroundColor: "#27C9C9",
              padding: "5px 8px 5px 8px",
              fontSize: "12.3px",
              fontWeight: "400",
              color: "#FFFFFF",
            }}
            onClick={() => setOpenModal(MODALS.SORT_FILTER)}
          >
            <FilterIcon style={{ marginRight: "5px" }} />
            Filters
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default HomeHeaderDesktop;
