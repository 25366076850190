// import {GoogleMap, Marker, useLoadScript} from "@react-google-maps/api";
import Map, { Marker, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as NavigateIcon } from "../../images/navigate-mapIcon.svg";
import { ReactComponent as TickIcon } from "../../images/tick-icon.svg";
import { useCreateEventContext } from "../../pages/CreateEvent";
import { useSelector } from "react-redux";
import LocationHook from "../../hooks/useLocationHook";

const CustomMap = ({ pages, setPages, loadUserLocation }) => {
  const mapRef = useRef();
  const [selected, setSelected] = useState(null);
  const [userPosition, setUserPosition] = useState(null);
  const [loading, setLoading] = useState(false);
  const [displayAddress, setDisplayAddress] = useState(null);
  const userInfo = useSelector((state) => state.UserReducer.userInfo);
  const updateEventData = useSelector(
    (state) => state.EventDataReducer.updateEventData
  );
  const eventType = useSelector((state) => state.EventDataReducer.eventType);
  const { address, setState } = useCreateEventContext();
  const { points } = LocationHook();

  const urlParams = new URLSearchParams(window.location.search);
  const lat = urlParams.get("lat");
  const lng = urlParams.get("lng");

  useEffect(() => {
    if (!address) return;
    setState((state) => ({
      ...state,
      state: address.state_code,
      city: address.county,
      apartment: address.house_number,
      startAddress: address.road,
    }));
  }, [address]);

  const setToLocation = () => {
    mapRef.current.jumpTo({
      center: [userInfo.location.lng, userInfo.location.lat],
      zoom: 12,
    });
    setSelected({
      latLng: {
        lat: () => userInfo.location.lat,
        lng: () => userInfo.location.lng,
      },
    });
  };

  useEffect(() => {
    if (!selected) return;

    setLoading(true);

    const currentLat = selected.latLng.lat();
    const currentLng = selected.latLng.lng();

    const geocoder = new window.google.maps.Geocoder();

    fetch(
      `https://api.opencagedata.com/geocode/v1/json?q=${currentLat},${currentLng}&key=${"a4a45601fe694b858418813d5a592ab3"}`
    )
      .then((res) => res.json())
      .then((data) => {
        setState((state) => ({
          ...state,
          address: {
            ...data.results[0].components,
            ...data.results[0].geometry,
          },
        }));
      })
      .finally(() => setLoading(false));
    geocoder.geocode(
      { location: { lat: currentLat, lng: currentLng } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            setDisplayAddress(results[0].formatted_address);
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }, [selected]);

  useEffect(() => {
    if (address) {
      setUserPosition({
        lat: address.lat,
        lng: address.lng,
      });
      setSelected({
        latLng: {
          lat: () => address.lat,
          lng: () => address.lng,
        },
      });
    } 
    else {
      setUserPosition({
        lat: userInfo.location.lat,
        lng: userInfo.location.lng,
      });

      if (loadUserLocation)
        setSelected({
          latLng: {
            lat: () => userInfo.location.lat,
            lng: () => userInfo.location.lng,
          },
        });
    }
  }, []);

  return (
    <div style={{ width: "100%", height: "65vh" }}>
      {userPosition && (
        <Map
          ref={mapRef}
          onClick={(e) => {
            setSelected({
              latLng: {
                lat: () => e.lngLat.lat,
                lng: () => e.lngLat.lng,
              },
            });
          }}
          initialViewState={{
            latitude: userPosition?.lat,
            longitude: userPosition?.lng,
            zoom: 12,
          }}
          mapboxAccessToken={process.env.REACT_APP_MAP_ACCESS_TOKEN}
          style={{ width: "100%", height: "100%", borderRadius: "10px" }}
          mapStyle="mapbox://styles/ar-rehman135/clh095mus00j301pgd330g67n"
        >
          {selected && (
            <Marker
              latitude={selected?.latLng.lat()}
              longitude={selected?.latLng.lng()}
              onClick={() => setSelected(null)}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                style={{ scale: "1.5" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.6645 3.67096C13.3956 2.08409 16.7525 2.11183 19.458 3.74362C22.1368 5.40864 23.765 8.38022 23.7498 11.5768C23.6874 14.7524 21.9416 17.7375 19.7593 20.0451C18.4998 21.383 17.0908 22.566 15.5611 23.57C15.4035 23.6611 15.2309 23.7221 15.0519 23.75C14.8795 23.7427 14.7116 23.6917 14.5634 23.6018C12.228 22.0932 10.1792 20.1675 8.51541 17.9175C7.12323 16.0392 6.33229 13.77 6.25 11.418C6.24819 8.21531 7.93346 5.25784 10.6645 3.67096ZM12.2427 12.7434C12.7021 13.876 13.7865 14.6147 14.9895 14.6147C15.7776 14.6204 16.5352 14.3047 17.0935 13.738C17.6518 13.1713 17.9643 12.4007 17.9616 11.5979C17.9658 10.3725 17.2443 9.26533 16.1341 8.79341C15.0238 8.32148 13.7438 8.57788 12.8916 9.44287C12.0395 10.3079 11.7833 11.6108 12.2427 12.7434Z"
                  fill="#20ACAC"
                />
                <path
                  opacity="0.4"
                  d="M15 27.5C18.4518 27.5 21.25 26.9404 21.25 26.25C21.25 25.5596 18.4518 25 15 25C11.5482 25 8.75 25.5596 8.75 26.25C8.75 26.9404 11.5482 27.5 15 27.5Z"
                  fill="#20ACAC"
                />
              </svg>
            </Marker>
          )}
          {loading && (
            <Stack
              left="50%"
              top="30%"
              sx={{ transform: "translateX(-50%)" }}
              position="absolute"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress size={60} />
            </Stack>
          )}
          <Stack
            width="25%"
            position="absolute"
            bottom={"2%"}
            left={"40%"}
            gap={2}
          >
            <Stack
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Stack
                sx={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: "#F56C6C",
                  borderRadius: "50%",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "35px",
                  fontWeight: 500,
                  marginBottom: "5px",
                  boxShadow:
                    " 4px -4px 5px 0px rgba(0, 0, 0, 0.05) inset, -2px 4px 8px 0px rgba(0, 0, 0, 0.10) inset",
                }}
                onClick={() => {
                  {
                    address && setPages(1);
                  }
                }}
              >
                {address ? <TickIcon /> : "+"}
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                onClick={setToLocation}
              >
                <NavigateIcon
                  style={{ marginLeft: "-5px", marginRight: "5px" }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "#616679",
                    textDecorationLine: "underline",
                  }}
                >
                  My Location
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Map>
      )}
    </div>
  );
};

const MapBox = ({ pages, setPages, ...props }) => {
  return (
    <Box width="100%">
      <CustomMap
        height="600px"
        loadUserLocation={props.loadUserLocation}
        pages={pages}
        setPages={setPages}
      />
    </Box>
  );
};

export default MapBox;
