import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import MobileNavbar from "./MobileNavbar";
import Navbar from "./Navbar";
import { UserAuthContextProvider } from "../../context/authContext";
import { ROUTES } from "../../routes";
import LocationHook from "../../hooks/useLocationHook";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setUserInfo } from "../../store/UserSlice";

const Main = () => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const isOpen = useSelector((state) => state.OpenMenuReducer.isOpen);
  const userInfo = useSelector((state) => state.UserReducer.userInfo);

  const [locationParam, setLocationParams] = useState(false);
  const excludedRoute = ![
    ROUTES.LOGIN,
    ROUTES.SIGN_UP,
    ROUTES.FORGOT_PASSWORD,
  ].includes(pathname);

  const { setCurrentLocationPoints, getLocation } = LocationHook();

  useEffect(() => {
    if (
      userInfo.location === null ||
      (userInfo.location?.lat === 10 && userInfo.location?.lng === 10)
    ) {
      setCurrentLocationPoints();
    } else {
      getLocation({
        lat: userInfo?.location?.lat,
        lng: userInfo?.location?.lng,
      });
    }
    const urlParams = new URLSearchParams(window.location.search);
    const host = urlParams.get("host")
    const lat = urlParams.get("lat");
    const lng = urlParams.get("lng");
    if ((lat && lng) ||  host) {
      setLocationParams(true);
    } else {
      setLocationParams(false);
    }
  }, []);

  return (
    <UserAuthContextProvider>
      <div style={{ overflow: "hidden" }}>
        <Box
          sx={{ minHeight: "100vh" }}
          display="flex"
          flexDirection="column"
          className={isOpen ? "gradient1" : "gradient"}
        >
          {matches && excludedRoute && <Navbar />}
          <Outlet />
          {!locationParam && !matches && excludedRoute && <MobileNavbar />}
        </Box>
      </div>
    </UserAuthContextProvider>
  );
};

export default Main;
