import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import {Tabs} from "@mui/base/Tabs";
import {TabsList} from "@mui/base/TabsList";
import {TabPanel} from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import Eicon from "../icons/errow.png";
import firebase from 'firebase/app';
import  { Tab,tabClasses } from "@mui/base/Tab";
import calender from "../icons/calender.png";

import {
  collection,
  getDocs,
  limit,
  orderBy,
  query as firebaseQuery,
  Timestamp,
  where,
} from "firebase/firestore";

import { db } from "../firebase/config";
import { useNavigate } from "react-router-dom";
import { showToast } from "../utils/toast";
import BackButton from "../icons/BackIcon";
import UpcomingPastEventCard from "../components/UpcomingPastEventCard/UpcomingPastEventCard";


export function documentDataToObject(doc) {
  return { id: doc.id, ...doc.data() };
}

const iconStyle = {
  backgroundColor: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  aspectRatio: "1/1",
  width: { xs: "30px", sm: "40px" },
  height: { xs: "30px", sm: "40px" },
  opacity: 0.8,
  padding: "7px",
  marginTop: "10px",
};

function formatDate(timestamp) {
  const date = timestamp?.toDate();
  const dated = new Date(date);

  // Get the month name from the month number
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = months[dated.getMonth()];

  // Get the year
  const year = dated.getFullYear();

  // Format the date as "month year"
  return `${monthName} ${year}`;
}

function UpcomingPastEvents({}) {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEnded, setIsEnded] = useState(false);
  const [lastDoc, setLastDoc] = useState();
  const [currentOffset, setCurrentOffset] = useState(0);
  const postLimit = 10; // Number of documents to fetch per page


  let type = "upcoming-event";
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0); // Set the initial selected tab to 0

  if (currentTab === 1) {
    type = "past-event";
  } else {
    type = "upcoming-event";
  }

  const groupedEvents = events.reduce((groups, event) => {
    const formattedDate = formatDate(event.date); // Pass event.date to formatDate
    if (!groups[formattedDate]) {
      groups[formattedDate] = [];
    }
    groups[formattedDate].push(event);
    return groups;
  }, {});

  // Convert the grouped events object back to an array
  const groupedEventsArray = Object.entries(groupedEvents).map(
    ([date, events]) => ({
      date,
      events,
    })
  );

  useEffect(() => {
    if (currentTab === 1) {
      type = "past-event";
    } else {
      type = "upcoming-event";
    }
    // fetchPosts()
    setEvents([]);

    _fetch(false);
  }, [currentTab]);

  /* autorun function that scroll to top */

  let displayText = "";
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window?.scrollTo(0, 0);
  if (type) {
    displayText = type.replace(/-/g, " ");
  } else {
    displayText = "Events";
  }

  async function fetchPosts(query) {
    try {

      const snapshot = await getDocs(query);
      const _fetched = snapshot?.docs?.map(documentDataToObject);
      let fethcedEvents = _fetched;
      const today = new Date();

      if (type)
        switch (type) {
          case "upcoming-event":
            break;
          case "past-event":
            fethcedEvents = _fetched?.filter(
              (event) => event.date < Timestamp.now().toDate()
            );
            break;
        }
      return {
        data: fethcedEvents,
        lastDoc: snapshot?.docs[snapshot.docs.length - 1],
        isEnded: snapshot?.docs?.length !== postLimit,
      };
    } catch (error) {
      console.log("error", error);
      showToast({ type: "error", message: error?.message });
      return { data: [] };
    }
  }

  useEffect(() => {
    setLoading(true);
    setIsEnded(false);
    setLastDoc(null);
    setEvents([]);
    // _fetch(false);
  }, []);

  const userJSON = localStorage.getItem("UID");

  const uid = JSON.stringify(userJSON);

  async function _fetch(startAfterLastDoc = true) {
    const queries = [collection(db, "events"), orderBy('date', 'asc'),limit(currentOffset + postLimit)];
    if (type) {
      let whereQuery = [];

        switch (type) {
            case "upcoming-event":

                whereQuery.push(
                    where("date", ">=", Timestamp.fromDate(new Date())), // Fetch events from today onwards
                    where("joined", "array-contains", userJSON)
                );
                break; // Missing break added here
            case "past-event":
                whereQuery.push(
                    where("date", "<", Timestamp.fromDate(new Date())),
                    where("joined", "array-contains", userJSON)
                );
                break;
            default:
                break;
        } 

      try {
        const result = await fetchPosts(firebaseQuery(...[...queries, ...whereQuery]));
        const totalFetched = result.data.length;
        const newEvents = result.data.slice(totalFetched - postLimit, totalFetched);
      
        // Filter out duplicates
        const filteredNewEvents = newEvents.filter(newEvent => 
          !events.some(events => events.id === newEvent.id));
      
        setEvents(prev => [...prev, ...filteredNewEvents]);
      
        const offset =currentOffset+ filteredNewEvents.length;
        setCurrentOffset(offset)
        if (filteredNewEvents.length < postLimit) setIsEnded(true);
      } catch (error) {
        console.error("Error fetching events: ", error);
        showToast({ type: "error", message: error?.message });
      } finally {
        setLoading(false);
      }
    } 
  }

  function onClickLoadMore() {
    _fetch();
  }
  const tabData = [
    { label: "UPCOMING", value: 0 },
    { label: "PAST EVENTS", value: 1 },
  ];

  const handleTabChange = (event, newValue) => {
    setLoading(false);
    setCurrentTab(newValue);
    setIsEnded(false);
    setLastDoc(null);
    setCurrentOffset(0)
  };
  const changeRoutes = () => {
    navigate("/event/:id");
  };

  return (
    <>
      <Container
        style={{ backgroundColor: "white" }}
        sx={{ py: { xs: 2, md: 4 } }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          backgroundColor="white"
          gap={1}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              backgroundColor: "white",
            }}
          >
            <IconButton
              onClick={() => navigate(-1)}
              size="small"
              sx={iconStyle}
            >
              <BackButton />
            </IconButton>
            <Typography
              style={{
                textTransform: "capitalize",
              }}
              variant="h3"
            >
              Events
            </Typography>
          </Box>
        </Stack>
      </Container>
      <Box
        style={{
          backgroundColor: "#ffffff",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px 0px",
        }}
        // sx={
        //     {padding:{xs:"4px 8px", md: "40px 80px"}}
        // }
        flexDirection="column"
        width="100%"
        display="flex"
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "100%" }}>
            <Tabs value={currentTab} onChange={handleTabChange} centered>
              <StyledTabsList>
                {tabData.map((tab) => (
                  <StyledTab
                    key={tab.value}
                    value={tab.value}
                    label={tab.label}
                  >
                    {tab.label}
                  </StyledTab>
                ))}
              </StyledTabsList>
            </Tabs>
          </div>
        </div>
        <Grid
          boxSizing="border-box"
          width="100%"
          container
          spacing={3}
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {(loading ? [...new Array(6)] : groupedEventsArray).map(
            (event, index) => (
              <Grid
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
                width="100%"
                key={index}
                xs={12}
                md={6}
                item
                boxSizing="border-box"
              >
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    height="300px"
                    width="100%"
                    sx={{ borderRadius: 2 }}
                  />
                ) : (
                  <>
                    <UpcomingPastEventCard
                      customBg={"#f6faff"}
                      event={event}
                    ></UpcomingPastEventCard>
                  </>
                )}
              </Grid>
            )
          )}
        </Grid>
        <div
          style={{
            margin: "auto",
            padding: "50px 10px 50px 10px",
            width: "100%",
            maxWidth: "250px",
          }}
        >
          {groupedEventsArray.length ? (
            <>
              {isEnded ? (
                <>
                  <Button disabled fullWidth variant="outlined">
                    No more events
                  </Button>
                </>
              ) : (
                <Button onClick={onClickLoadMore} fullWidth variant="outlined">
                  Load More
                </Button>
              )}
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    width: "70%",
                    borderRadius: "109px",
                    background: "#F6F4EF",
                  }}
                  src={calender}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ width: "85%" }}>
                    <p
                      style={{
                        marginTop: "7%",
                        color: "#120D26",
                        fontFamily: "Poppins",
                        fontSize: "1.3rem",
                        textAlign: "center",
                      }}
                    >
                      No {currentTab === 0 ? "Upcoming" : "Past"} Events
                    </p>
                    <p
                      style={{
                        marginTop: "4%",
                        color: "#747688",
                        fontFamily: "DM Sans",
                        fontSize: "1rem",
                        textAlign: "center",
                      }}
                    >
                      When you RSVP to an event, you will see{" "}
                      {currentTab === 0 ? "upcoming" : "past"} details about
                      them here.
                    </p>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <button
                    style={{
                      width: "100%",
                      padding: "10px 0px",
                      borderRadius: "15px",
                      border: "none",
                      background: "rgb(25 192 192)",
                      fontSize: "1.3rem",
                      fontWeight: "400",
                      color: "white",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/explore");
                    }}
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "90%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingLeft: "4%",
                        }}
                      >
                        <p
                          style={{
                            margin: "0px",
                            padding: "0px",
                            whiteSpace: "nowrap",
                            fontSize: "1rem",
                            fontFamily: "sans-serif",
                          }}
                        >
                          EXPLORE EVENTS
                        </p>
                        <img src={Eicon}></img>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </Box>
    </>
  );
}

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};
const WhiteArrowForwardIcon = styled(ArrowForwardIcon)({
  color: "white",
  borderRadius: "50px",
  border: "none",
  background: "rgb(44 131 131)",
});

const StyledTab = styled(Tab)`
  font-family: "IBM Plex Sans", sans-serif;
  color: #9b9b9b;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 100;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: 55px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #9b9b9b;
  }

  &:focus {
    color: #9b9b9b;
  }

  &.${tabClasses.selected} {
    background-color: white;
    color: #f56c6c;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StyledTabPanel = styled(TabPanel)(
  ({ theme }) => `
        width: 100%;
        font-family: IBM Plex Sans, sans-serif;
        font-size: 0.875rem;
        padding: 20px 12px;
        background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
        border: 1px solid ${
          theme.palette.mode === "dark" ? grey[700] : grey[200]
        };
        border-radius: 12px;
        opacity: 0.6;
        `
);

const StyledTabsList = styled(TabsList)(
  ({ theme }) => `
      whiteSpace: nowrap;
        min-width: 310px;
        background-color: #ededed7a;
        border-radius: 17px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: space-between;
        `
);

export default UpcomingPastEvents;
