import { combineReducers, configureStore } from '@reduxjs/toolkit'
import  ExploreTagModel  from './ModelSlice'
import  LocationReducer  from './LocationSlice'
import  UserReducer  from './UserSlice'
import EventDataReducer from './EventSlice'
import OpenMenuReducer from './OpenMenuSlice'
import UserProfileReducer from './ProfileSlice'
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';


const persistConfig = {
  key: 'root',
  storage,
  whitelist:["UserReducer"],
}
const reducer = combineReducers({
  ModelReducer:ExploreTagModel,
    LocationReducer,
    UserReducer,
    EventDataReducer,
    OpenMenuReducer,
    UserProfileReducer,
})
const persistedReducer = persistReducer(persistConfig, reducer)

export default configureStore({
  reducer: persistedReducer,
  // devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
})