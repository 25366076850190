import { createSlice } from "@reduxjs/toolkit";

export const ExploreTagModel = createSlice({
  name: "counter",
  initialState: {
    open: false,
    tags: [],
    appliedFilters: 0,
    filterObj: null,
  },
  reducers: {
    setTagsFilter: (state, action) => {
      state.tags.push(action.payload);
    },
    setTags: (state, action) => {
      state.tags = action.payload;
    },
    removeTags: (state, action) => {
      state.tags = state.tags.filter((_, i) => i !== action.payload);
    },
    emptyTags: (state, action) => {
      state.tags = action.payload;
    },
    setAppliedFilter: (state, action) => {
      state.appliedFilters = action.payload;
    },
    setAppliedFilterObj: (state, action) => {
      state.filterObj = action.payload;
    },
    setOpenTagModel: (state, action) => {
      state.open = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTagsFilter,
  setOpenTagModel,
  setAppliedFilter,
  removeTags,
  emptyTags,
  setTags,
  setAppliedFilterObj,
} = ExploreTagModel.actions;

export default ExploreTagModel.reducer;
