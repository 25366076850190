import Header from "../components/Profile/Header";

const Profile = () => {
  return (
    <div>
      <Header />
    </div>
  );
};

export default Profile;
