import { Button, IconButton, Stack, Typography } from "@mui/material";
import DefaultModal from "./DefaultModal";
import CustomDivider from "../components/CustomDivider";
import { useState, useEffect } from "react";
import { JoinEventById } from "../firebase/functions/event/event-join";
import { sendEventJoinNotification } from "../firebase/functions/messaging";
import PaymentForm from "../components/EventDetails/Details/PaymentForm";
import { useSelector } from "react-redux";

const EventConfirmationModal = ({
  id,
  photos,
  event,
  open,
  cost,
  creator,
  date,
  startTime,
  endTime,
  keywords,
  onClose,
  title,
  address,
  displayAddress,
  setIsJoined,
  maxParticipants,
  users,
  description,
}) => {
  const userInfo = useSelector((state) => state.UserReducer.userInfo);
  const [openPayment, setOpenPayment] = useState(false);

  const closePayment = (hasJoined) => {
    setOpenPayment(false);
    onModalClose();
    if (hasJoined) setIsJoined(true);
  };

  const [attendees, setAttendees] = useState(1);

  useEffect(() => {}, [cost]);

  const onModalClose = () => {
    setAttendees(1);
    onClose();
  };

  const JoinCurrentEvent = () => {
    if (cost > 0) {
      setOpenPayment(true);
    } else {
      JoinEventById(id, attendees);
      sendEventJoinNotification(title, id, creator, userInfo.photoURL, {
        date,
        startTime,
        photos,
        title,
        id,
      });
      onModalClose();
      setIsJoined(true);
    }
  };

  return (
    <DefaultModal open={open} onClose={onModalClose}>
      <PaymentForm
        eventId={id}
        photos={userInfo.photoURL}
        eventTitle={title}
        eventCreator={creator}
        attendees={attendees}
        currentEvent={event}
        open={openPayment}
        handleClose={closePayment}
        displayAddress={displayAddress}
        cost={cost}
      />
      <Stack textAlign="center" gap={3}>
        <Typography variant="p" sx={{ color: "#949090" }}>
          You are RSVPing to{" "}
        </Typography>

        {/* <Typography variant="h4">{title}</Typography> */}
        <Typography variant="h3">{title}</Typography>
        <Stack gap={2}>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1">Date</Typography>
            <Typography variant="body1">
              {date.toLocaleDateString("en-US", {
                weekday: "short",
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </Typography>
          </Stack>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1">Time</Typography>
            <Typography variant="body1">
              {startTime} - {endTime}
            </Typography>
          </Stack>
          {/* <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1">City</Typography>
            <Typography color="primary" variant="body1" fontWeight="bold">
              {event.address?.displayAddress || "N/A"}
            </Typography>
          </Stack> */}

          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1">Address</Typography>
            <Typography variant="body1">{displayAddress}</Typography>
          </Stack>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {/*<Typography variant="body1">Keyword</Typography>*/}
            {/*<Typspography>*/}
          </Stack>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1">Current Attending</Typography>
            <Typography variant="body1">
              <p style={{ textAlign: "end" }}>{users?.length}</p>
              <p style={{ color: "#949090", fontSize: "0.8rem" }}>
                Max: {maxParticipants}
              </p>
            </Typography>
          </Stack>
          {/* <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1">Latitude</Typography>
            <Typography variant="body1" fontWeight="bold">
              {address.lat}
            </Typography>
          </Stack> */}
          {/* <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1">Longitude</Typography>
            <Typography variant="body1" fontWeight="bold">
              {address.lng}
            </Typography>
          </Stack> */}
        </Stack>
        <CustomDivider />
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {/* <Typography variant="h1"># of RSVPs</Typography> */}
          <Typography variant="body1">
            <p style={{ textAlign: "start" }}># of RSVPs</p>
            <p style={{ color: "#949090" }}>
              {" "}
              How many people are you RSVPing for?
            </p>
          </Typography>
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <IconButton
              sx={{ backgroundColor: "#E7E8ED", width: "30px", height: "30px" }}
              onClick={() => {
                if (attendees > 0) {
                  setAttendees((prev) => prev - 1);
                }
              }}
            >
              -
            </IconButton>
            <Typography variant="body1">{attendees}</Typography>

            <IconButton
              sx={{ backgroundColor: "#E7E8ED", width: "30px", height: "30px" }}
              onClick={() => {
                if (maxParticipants !== attendees) {
                  setAttendees((prev) => prev + 1);
                }
              }}
              // setAttendees
              // }((prev) => prev + 1)}
            >
              +
            </IconButton>
          </Stack>
        </Stack>
        <CustomDivider />
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {/* <Typography variant="h1"># of RSVPs</Typography> */}
          <Typography variant="body1">Participation Cost</Typography>
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Typography variant="body1">
              {/* {cost} */}
              <p>{cost ? `$${cost}.00` : "Free"}</p>
              <p style={{ color: "#949090", fontSize: "0.8rem" }}>
                * {attendees} RSVPs
              </p>
            </Typography>
          </Stack>
        </Stack>
        <CustomDivider />
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {/* <Typography variant="h1"># of RSVPs</Typography> */}
          <Typography variant="body1">Total Amount Due</Typography>
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Typography variant="body1">${cost * attendees}.00</Typography>
          </Stack>
        </Stack>
        <CustomDivider />
        {/*<Stack*/}
        {/*    flexDirection="row"*/}
        {/*    alignItems="center"*/}
        {/*    justifyContent="space-between"*/}
        {/*>*/}
        {/*    <Typography variant="body1">Participation Cost</Typography>*/}
        {/*<div style={{display:"flex", alignItems:"center"}}>*/}
        {/*{cost?<div style={{display:"flex", alignItems:"center"}}>*/}
        {/*    <Typography variant="body1" fontWeight="bold" fontSize={"1.8em"} >*/}
        {/*    {cost ? `${cost}` : "Free"}*/}
        {/*</Typography>*/}
        {/*    <Typography variant="body1" fontWeight="bold" color={"#808080"} position="relative" top={"-8px"} >*/}
        {/*     &nbsp;x{attendees}*/}
        {/*</Typography>*/}
        {/*<Typography variant="body1" fontWeight="bold" fontSize={"1.8em"} margin={"0px 6px"}>*/}
        {/*    =*/}
        {/*</Typography>*/}
        {/*</div>:<></>}*/}
        {/*<Typography variant="body1" fontWeight="bold" fontSize={"1.8em"} color={"#8BCD37"}>*/}
        {/*    {cost ? `${cost*attendees}` : "Free"}*/}
        {/*</Typography>*/}
        {/*</div>*/}
        {/*</Stack>*/}
        <Button
          onClick={JoinCurrentEvent}
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: "#000",
            color: "info.main",
            borderRadius: 8,
            padding: 1,
            "&:hover": {
              backgroundColor: "#000",
            },
          }}
        >
          CONFIRM RSVP
        </Button>
      </Stack>
    </DefaultModal>
  );
};

export default EventConfirmationModal;
