const Payments = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.33325 8.16667C2.33325 5.58934 4.42259 3.5 6.99992 3.5H20.9999C23.5772 3.5 25.6666 5.58934 25.6666 8.16667V19.8333C25.6666 22.4107 23.5772 24.5 20.9999 24.5H6.99992C4.42259 24.5 2.33325 22.4107 2.33325 19.8333V8.16667Z"
      stroke="#111315"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M2.33325 8.16667H25.6666V12.8333H2.33325V8.16667Z"
      stroke="#111315"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M9.33333 19.8333H7"
      stroke="#111315"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default Payments;
