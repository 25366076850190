import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";

import app from "../../config";
import { getCurrentUser } from "../user";

const db = getFirestore(app);

let currentUser = null;

/* Left event :: Start */
export const LeftEventById = async (id) => {
  currentUser = await getCurrentUser();
  const eventRef = doc(db, "events", id);
  const event = await getDoc(eventRef);
  if (event.exists) {
    const data = event.data();
    const leftUser = data.joined.filter((user) => {
      return user !== currentUser;
    });

    const leftAttendees = { ...data.attendeesUser };
    delete leftAttendees[currentUser];
    const noOfAttendees = data.attendees - data.attendeesUser[currentUser];
    return await updateDoc(eventRef, {
      joined: leftUser,
      attendees: noOfAttendees,
      attendeesUser: leftAttendees,
      participantCount: data.participantCount - 1,
    });
  }
};
/* Left event :: End */
