import React from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";

const AboutUs = () => {
  const userInfo = useSelector((state) => state.UserReducer.userInfo);
  return <StyledTextarea rows={4} readOnly={true} value={userInfo.aboutUs} />;
};
export default AboutUs;

const StyledTextarea = styled.textarea`
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #3c3e56;
  outline: none;
  resize: none;
  padding: 15px 9px 20px 0px;
  line-height: 25px;
  border: none;
  height: 100%;
`;
