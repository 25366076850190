import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import "swiper/css";
import EventCard from "../../EventCard/EventCard";
import { useEffect, useState } from "react";
import {
  SortEventWithCityByPopular,
  SortEventWithCityByUpcoming,
  SortEventWithLocationByPopular,
  SortEventWithLocationByUpcoming,
} from "../../../firebase/functions/event/sort-event";
import { ROUTES } from "../../../routes";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { ReactComponent as SeeAllIcon } from "../../../images/see-all.svg";
import { useSelector } from "react-redux";

const Popular = ({ currentCity, heading }) => {
  const [events, setEvents] = useState([]);
  const location = useSelector((state) => state.LocationReducer.location);

  const navigate = useNavigate();
  // useEffect(() => {
  //     let isMounted = true;
  //     if (location.structured_formatting.main_text !== "") {
  //         SortEventWithCityByUpcoming(location.structured_formatting.main_text, 4).then((events) => {
  //             if (isMounted) {
  //                 setEvents(events);
  //             }
  //         });
  //     } else {
  //         SortEventWithLocationByUpcoming(4).then((events) => {
  //             if (isMounted) {
  //                 setEvents(events);
  //             }
  //         });
  //     }
  //     return () => {
  //         isMounted = false;
  //     };
  // }, [currentCity , location.structured_formatting.main_text]);
  useEffect(() => {
    SortEventWithLocationByPopular(2).then((events) => {
      setEvents(events);
    });
  }, []);
  return (
    <div>
      <Container
        sx={{
          py: { xs: 2, md: 5 },
          px: { xs: 2.5, md: 2 },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{
              fontSize: { xs: 18, md: 44 },
              fontWeight: 500,
              color: "#120D26",
            }}
            variant="h3"
          >
            {heading}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={function (e) {
                e.preventDefault();
                navigate(ROUTES.EVENTS_PAGE + "?type=popular-event");
              }}
              sx={{ color: "#747688", fontSize: "14px", fontWeight: "400" }}
              variant="text"
            >
              See All
            </Button>
            <SeeAllIcon />
          </Box>
        </Stack>
      </Container>
      <Container sx={{ pd: { xs: 2, md: 4 } }}>
        <Box>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 2, sm: 8, md: 8 }}
          >
            {events.length > 0 &&
              events.map((event) => (
                <Grid item xs={2} sm={4} md={4} key={event.id}>
                  <EventCard event={event} />
                </Grid>
              ))}
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default Popular;
