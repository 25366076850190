import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import {
  collection,
  getDocs,
  limit,
  orderBy,
  query as firebaseQuery,
  startAfter,
  Timestamp,
  where,
  query,
  getFirestore,
  getDoc,
  doc,
  db,
  get,
} from "firebase/firestore";

import { IsEventLikedByUser, getAllLikedEventByUser, getLikedEventsByUser, myFunction } from "../firebase/functions/event/event-likes";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { showToast } from "../utils/toast";
import { getCurrentUser } from "../firebase/functions/user";
import BackButton from "../icons/BackIcon";
import SavedEventsCard from "./SavedEventsCard";
import { Context } from "../context/StateContext";
import { useContext } from "react";
import {FilterEventsBySponsored} from '../firebase/functions/event/event-filter';

import { useSelector } from "react-redux";
import { ROUTES, isRouteProtected } from "../routes";

import Eicon from "../icons/errow.png";
import calender from "../icons/calender.png";


export function documentDataToObject(doc) {
  return { id: doc.id, ...doc.data() };
}

const iconStyle = {
  backgroundColor: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  aspectRatio: "1/1",
  width: { xs: "30px", sm: "40px" },
  height: { xs: "30px", sm: "40px" },
  opacity: 0.8,
  padding: "7px",
  marginTop: "10px",
};

function SavedEvents({}) {

  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEnded, setIsEnded] = useState(false);
  const [lastDoc, setLastDoc] = useState();

  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("type");
  const keywordSearch = searchParams.get("keywordSearch");
  const location=useLocation()
  const navigate = useNavigate();
   const userInfo = useSelector((state) => state?.UserReducer?.userInfo);
  const userId = useSelector((state) => state?.UserReducer?.userInfo.uid);

  const db = getFirestore();

  /* autorun function that scroll to top */

  let displayText = "";
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.scrollTo(0, 0);
  if (type) {
    displayText = type.replace(/-/g, " ");
  } else if (keywordSearch) {
    displayText = keywordSearch + " Events";
  } else {
    displayText = "Saved Events";
  }


const fetchLikedEvent = async (userId) => {

    try {

      const res = await getAllLikedEventByUser(userId);
      
      setEvents(res);
      setLoading(false);
    }
    catch (error) {

      console.error('Error fetching data:', error);

    }
  };

 useEffect(() => {
  
    const isProtectedRoute = isRouteProtected(location.pathname);

    if (!isProtectedRoute && userInfo?.email==="Anonymous") {
      navigate(ROUTES.LOGIN);
    }

 
  }, [userInfo, location.pathname]);

  useEffect(() => {

    fetchLikedEvent(userId);
  }, [userId]);

  const removeLikedEvent = (id) => {

    setEvents((events) => events.filter((event) => event.id !== id));    

  }

  return (
     <>
      <Container sx={{ py: { xs: 2, md: 4 } }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <IconButton onClick={() => navigate(-1)} size="small" sx={iconStyle}>
              <BackButton />
            </IconButton>
            <Typography style={{ textTransform: "capitalize" }} variant="h3">
              {displayText}
            </Typography>
          </Box>
        </Stack>
      </Container>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px 0px",
          mb: "30px",
        }}
        flexDirection="column"
        width="100%"
        display="flex"
      >
        <Grid
          boxSizing="border-box"
          width="100%"
          container
          spacing={3}
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {events.length ? (
            events.map((event) => (
              <Grid
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
                width="100%"
                key={event.id}
                xs={12}
                md={6}
                item
                boxSizing="border-box"
              >
                <SavedEventsCard customBg={"#f6faff"} event={event} removeLikedEvent={removeLikedEvent} />
              </Grid>
            ))
          ) : (

            <Box
        style={{
          backgroundColor: "#ffffff",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px 0px",
          marginLeft: "4.5vw"
        }}
        flexDirection="column"
        width="100%"
        display="flex"
      >    

      <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    width: "45%",
                    borderRadius: "109px",
                    background: "#F6F4EF",
                  }}
                  src={calender}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ width: "85%" }}>
                    <p
                      style={{
                        marginTop: "7%",
                        color: "#120D26",
                        fontFamily: "Poppins",
                        fontSize: "1.3rem",
                        textAlign: "center",
                      }}
                    >
                      No Saved Events
                    </p>
                    <p
                      style={{
                        marginTop: "4%",
                        color: "#747688",
                        fontFamily: "DM Sans",
                        fontSize: "1rem",
                        textAlign: "center",
                      }}
                    >
                      When saved an event, you will see details about
                      them here.
                    </p>
                  </div>
                </div>
              </div>
                  
                  <button
                    style={{
                      marginTop: "20px",
                      width: "250px",
                      padding: "10px 0px",
                      borderRadius: "15px",
                      border: "none",
                      background: "rgb(25 192 192)",
                      fontSize: "1.3rem",
                      fontWeight: "400",
                      color: "white",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/explore");
                    }}
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "90%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingLeft: "4%",
                        }}
                      >
                        <p
                          style={{
                            margin: "0px",
                            padding: "0px",
                            whiteSpace: "nowrap",
                            fontSize: "1rem",
                            fontFamily: "sans-serif",
                          }}
                        >
                          EXPLORE EVENTS
                        </p>
                        <img src={Eicon}></img>
                      </div>
                    </div>
                  </button>



            </>

</Box>
   
          )}

        </Grid>
      </Box>
    </>
  );
}

export default SavedEvents;

