
import { createContext, useState } from "react";

export const Context = createContext()


const StateContext = ({ children }) => {

    const [notifications, setNotifications] = useState([])
    const [eventData, setEventData] = useState([]);

    const [exploreEvents, setExploreEvents] = useState([])



    return (
        <Context.Provider
            value={{ notifications, setNotifications, eventData, setEventData, exploreEvents, setExploreEvents }}>

            {children}

        </Context.Provider>
    )

}

export default StateContext;